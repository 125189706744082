import UserInfo from '../UserInfo';
import { LuClipboardList } from 'react-icons/lu';
import { HiOutlineShoppingBag, HiOutlineCurrencyDollar } from 'react-icons/hi';
import { RxAccessibility } from 'react-icons/rx';
import { IoContrast, IoContrastOutline } from 'react-icons/io5';

import { HiOutlineBellAlert } from 'react-icons/hi2';
import { CiFlag1 } from 'react-icons/ci';
import { useContext } from 'react';
import { AuthContext, useAuth } from 'context/AuthContext';
import { useNavigate } from 'react-router';
import BB_ROUTES from 'routes/const';
import { useModal } from 'context/ModalContext';
import './style.scss';
import { Link, NavLink } from 'react-router-dom';
import { getNameSetor } from 'utils/constants';
import tippy from 'tippy.js';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/dist/tippy.css';
import Logo from 'components/Logo';
import { setStorageItem } from 'utils/storage';

interface OptiosnButton {
  text?: string | JSX.Element;
  ariaLabel: string;
  tip?: string;
  bgClass?: string;
  icon: JSX.Element;
  path: string;
  click: () => void;
}

const HeaderButton = ({
  text,
  ariaLabel,
  tip,
  bgClass,
  icon,
  path,
  click,
}: OptiosnButton) => {
  const { user, team, countNotify } = useContext(AuthContext);
  const handler = () => {
    click();
  };
  // tippy.setDefaultProps({ delay: 50 });
  // tippy('button.has-tip', {
  //   arrow: false,
  //   theme: team,
  // });
  return (
    <NavLink
      className={`h-7 w-10 px-2 lg:px-5 lg:w-16 lg:h-10 py-2 btn-nav transition-all hover:bg-opacity-70 rounded-md lg:rounded-xl shadow-md flex items-center justify-center item-menu ${
        bgClass ? `custom-color ${bgClass}` : 'no-color'
      } ${!!icon && !!text ? `justify-between` : ``}`}
      to={path}
      aria-label={` ${text ? text : ``} ${ariaLabel}`}
    >
      {text ? text : ''}
      <div className="icon" aria-hidden={true}>
        {icon}
      </div>
    </NavLink>
  );
};

export default function Header() {
  const navigate = useNavigate();
  const {
    user,
    team,
    countNotify,
    acessibility,
    setAcessibility,
    contrast,
    setContrast,
  } = useContext(AuthContext);
  const { showModalTutorial, setShowModalTutorial } = useModal();
  const { signOut } = useAuth();

  const handler = () => {};

  const handlerAcessibility = () => {
    setAcessibility(!acessibility);
  };

  const handlerContrast = () => {
    setContrast(!contrast);
  };

  return (
    <>
      <header className="container my-0 mx-auto mb-2 navigation-app">
        <div className="flex lg:hidden justify-between items-center py-[10px]">
          <div>
            <span
              className={`text-yellow text-xs font-bold text-center team-name relative z-[200]`}
              aria-hidden={true}
            >
              <>{getNameSetor(user?.teams || [])} </>
            </span>
          </div>
          <div className="flex flex-row items-center">
            <button
              aria-label={
                acessibility
                  ? `Recursos de acessibilidade ativados no minigame`
                  : `Ativar recursos de acessibilidade no minigame`
              }
              className={`mr-3 h-7 w-10 px-2 lg:px-5 lg:w-16 lg:h-10 py-4 btn-nav transition-all hover:bg-opacity-70 rounded-md lg:rounded-xl border flex items-center 
                    justify-center ${acessibility ? `is-active` : ''} ${
                contrast ? 'border-yellow' : 'border-white'
              }`}
              onClick={handlerAcessibility}
            >
              <RxAccessibility
                className="text-base lg:text-3xl"
                color={contrast ? `#fcfc30` : acessibility ? '#fcfc30' : '#fff'}
                fontSize={'25'}
              />
            </button>
            <button
              aria-label={
                contrast
                  ? `Recursos de contraste ativados`
                  : `Ativar recursos de contraste`
              }
              className={`mr-3 h-7 w-10 px-2 lg:px-5 lg:w-16 lg:h-10 py-4 btn-nav transition-all hover:bg-opacity-70 rounded-md lg:rounded-xl border border-white flex items-center 
                    justify-center ${
                      contrast ? 'border-yellow' : 'border-white'
                    }`}
              onClick={handlerContrast}
            >
              {contrast ? (
                <IoContrast
                  className="text-base lg:text-3xl"
                  color={contrast ? `#fcfc30` : '#fff'}
                  fontSize={'25'}
                />
              ) : (
                <IoContrastOutline
                  className="text-base lg:text-3xl"
                  color={contrast ? `#fcfc30` : '#fff'}
                  fontSize={'25'}
                />
              )}
            </button>
            <button
              className="px-6 py-2 transition-all hover:bg-opacity-70 bg-transparent border  rounded-xl shadow-md flex align-middle justify-center"
              onClick={() => signOut()}
              aria-label="Sair"
            >
              Sair
            </button>
          </div>
        </div>
        <div className="flex items-center relative md:justify-end">
          <div
            className="hidden lg:block brand left-0 absolute max-w-[260px] top-0 cursor-pointer z-[200]"
            onClick={() => navigate(BB_ROUTES.HOME())}
          >
            <Link to={'/'} aria-label="Logo Game tô on. Voltar a home">
              {/* <img src={`/img/brand-mobile.svg`} alt="" width={100} /> */}
              <Logo />
            </Link>
            {/* <Logo /> */}
          </div>
          <div className="navigation hidden lg:flex justify-between items-center flex-col w-[79%] mb-0">
            <nav className="hidden lg:flex pt-0 lg:pt-6 justify-between items-center flex-col md:flex-row w-full mb-0">
              <span
                className={`text-yellow text-xl font-bold text-center team-name relative hidden lg:block z-[190]`}
                aria-hidden={true}
              >
                <>{getNameSetor(user?.teams || [])} </>
              </span>
              <ul className="flex justify-center items-center md:justify-end md:items-end mt-[18px] md:mt-0">
                <li className="mr-3">
                  <HeaderButton
                    ariaLabel="Regulamento"
                    icon={
                      <LuClipboardList
                        className="text-base lg:text-3xl"
                        color={contrast ? `#fcfc30` : '#735CC6'}
                        fontSize={'25'}
                      />
                    }
                    tip="Regulamento"
                    path={BB_ROUTES.RULE()}
                    click={() => {
                      navigate(BB_ROUTES.RULE());
                    }}
                  />
                </li>
                <li className="mr-3">
                  <HeaderButton
                    ariaLabel="Loja"
                    icon={
                      <HiOutlineShoppingBag
                        className="text-base lg:text-3xl"
                        color={contrast ? `#fcfc30` : '#735CC6'}
                        fontSize={'25'}
                      />
                    }
                    tip="Loja"
                    path={BB_ROUTES.STORE()}
                    click={() => {
                      navigate(BB_ROUTES.STORE());
                    }}
                  />
                </li>
                <li className="mr-3">
                  <HeaderButton
                    ariaLabel="Extrato"
                    icon={
                      <HiOutlineCurrencyDollar
                        className="text-base lg:text-3xl"
                        color={contrast ? `#fcfc30` : '#735CC6'}
                        fontSize={'25'}
                      />
                    }
                    tip="Extrato"
                    path={BB_ROUTES.EXTRATO()}
                    click={() => {
                      navigate(BB_ROUTES.EXTRATO());
                    }}
                  />
                </li>

                <li className="mr-3">
                  <HeaderButton
                    ariaLabel="Desafios"
                    icon={
                      <CiFlag1
                        className="text-base lg:text-3xl"
                        color={contrast ? `#fcfc30` : '#735CC6'}
                        fontSize={'25'}
                      />
                    }
                    tip="Desafios"
                    path={BB_ROUTES.CHALLENGE()}
                    click={() => {
                      navigate(BB_ROUTES.CHALLENGE());
                    }}
                  />
                </li>
                <li className="mr-3">
                  <HeaderButton
                    ariaLabel="Notificações"
                    bgClass={`w-16 ${
                      contrast
                        ? 'bg-black text-yellow border-yellow b-0'
                        : 'bg-pink'
                    }`}
                    text={String(countNotify)}
                    icon={
                      <HiOutlineBellAlert
                        className="text-base lg:text-3xl"
                        color={contrast ? '#fcfc30' : '#fff'}
                        style={{
                          width: 20,
                        }}
                      />
                    }
                    tip="Notificações"
                    path={BB_ROUTES.NOTIFICATION()}
                    click={() => {
                      navigate(BB_ROUTES.NOTIFICATION());
                    }}
                  />
                </li>
                <li className="mr-3">
                  <button
                    aria-label={
                      acessibility
                        ? `Recursos de acessibilidade ativados no minigame`
                        : `Ativar recursos de acessibilidade no minigame`
                    }
                    className={`h-7 w-10 px-2 lg:px-5 lg:w-16 lg:h-10 py-2 btn-nav transition-all hover:bg-opacity-70 rounded-md lg:rounded-xl border flex items-center 
                    justify-between ${acessibility ? `is-active` : ''} ${
                      contrast ? 'bg-black border-yellow' : 'border-white'
                    } ${
                      contrast && acessibility
                        ? 'bg-yellow text-black border-yellow'
                        : ''
                    }`}
                    onClick={handlerAcessibility}
                  >
                    <RxAccessibility
                      className="text-base lg:text-3xl"
                      color={
                        contrast && acessibility
                          ? '#000'
                          : contrast
                          ? '#fcfc30'
                          : acessibility
                          ? '#fcfc30'
                          : '#fff'
                      }
                      fontSize={'25'}
                    />
                  </button>
                </li>
                <li className="mr-3">
                  <button
                    aria-label={
                      contrast
                        ? `Recursos de contraste ativados`
                        : `Ativar recursos de contraste`
                    }
                    className={`h-7 w-10 px-2 lg:px-5 lg:w-16 lg:h-10 py-2 btn-nav transition-all hover:bg-opacity-70 rounded-md lg:rounded-xl border border-white flex items-center 
                    justify-between ${
                      contrast
                        ? 'bg-yellow text-black border-yellow'
                        : 'border-white'
                    }`}
                    onClick={handlerContrast}
                  >
                    {contrast ? (
                      <IoContrast
                        className="text-base lg:text-3xl"
                        color={contrast ? `#000` : '#fff'}
                        fontSize={'25'}
                      />
                    ) : (
                      <IoContrastOutline
                        className="text-base lg:text-3xl"
                        color={contrast ? `#fcfc30` : '#fff'}
                        fontSize={'25'}
                      />
                    )}
                  </button>
                </li>

                <li className="hidden lg:block">
                  <button
                    aria-label="Sair"
                    className={`px-6 py-2 transition-all hover:bg-opacity-70 mr-3 bg-transparent border  rounded-xl shadow-md flex align-middle justify-center ${
                      contrast ? `border-yellow text-yellow` : ''
                    }`}
                    onClick={() => signOut()}
                  >
                    Sair
                  </button>
                </li>
              </ul>
            </nav>
          </div>

          {/* MOBILE */}
          <nav className="flex lg:hidden pt-0 lg:pt-6 justify-between items-center flex-col md:flex-row w-full mb-3">
            <div className="flex lg:hidden items-center justify-between md:items-end mt-0 lg:mt-[18px] md:mt-0 w-full">
              <ul className="flex justify-center items-center md:justify-end md:items-end md:mt-0">
                <li className="mr-3">
                  <HeaderButton
                    ariaLabel="Regulamento"
                    icon={
                      <LuClipboardList
                        className="text-base lg:text-3xl"
                        color={contrast ? `#fcfc30` : '#735CC6'}
                        fontSize={'25'}
                      />
                    }
                    tip="Regulamento"
                    path={BB_ROUTES.RULE()}
                    click={() => {
                      navigate(BB_ROUTES.RULE());
                    }}
                  />
                </li>
                <li className="mr-3">
                  <HeaderButton
                    ariaLabel="Loja"
                    icon={
                      <HiOutlineShoppingBag
                        className="text-base lg:text-3xl"
                        color={contrast ? `#fcfc30` : '#735CC6'}
                        fontSize={'25'}
                      />
                    }
                    tip="Loja"
                    path={BB_ROUTES.STORE()}
                    click={() => {
                      navigate(BB_ROUTES.STORE());
                    }}
                  />
                </li>
                <li className="mr-3">
                  <HeaderButton
                    ariaLabel="Extrato"
                    icon={
                      <HiOutlineCurrencyDollar
                        className="text-base lg:text-3xl"
                        color={contrast ? `#fcfc30` : '#735CC6'}
                        fontSize={'25'}
                      />
                    }
                    tip="Extrato"
                    path={BB_ROUTES.EXTRATO()}
                    click={() => {
                      navigate(BB_ROUTES.EXTRATO());
                    }}
                  />
                </li>

                <li className="mr-3">
                  <HeaderButton
                    ariaLabel="Desafios"
                    icon={
                      <CiFlag1
                        className="text-base lg:text-3xl"
                        color={contrast ? `#fcfc30` : '#735CC6'}
                        fontSize={'25'}
                      />
                    }
                    tip="Desafios"
                    path={BB_ROUTES.CHALLENGE()}
                    click={() => {
                      navigate(BB_ROUTES.CHALLENGE());
                    }}
                  />
                </li>
              </ul>
              <ul>
                <li>
                  <HeaderButton
                    ariaLabel="Notificações"
                    bgClass="bg-pink w-16"
                    text={String(countNotify)}
                    icon={
                      <HiOutlineBellAlert
                        className="text-base lg:text-3xl"
                        color={'#ffffff'}
                        size={25}
                      />
                    }
                    tip="Notificações"
                    path={BB_ROUTES.NOTIFICATION()}
                    click={() => {
                      navigate(BB_ROUTES.NOTIFICATION());
                    }}
                  />
                </li>
              </ul>
            </div>
          </nav>
          {/* END MOBILE */}
        </div>

        <div className="flex justify-between items-end lg:hidden mb-3 w-full">
          <Link to={'/'} aria-label="Logo Game tô on. Voltar a home">
            {/* <img src={`/img/brand-mobile.svg`} alt="" width={100} /> */}
            <div className="w-[120px] relative z-[201]">
              <Logo />
            </div>
          </Link>
          {/* <div
            className="cursor-pointer"
            onClick={(e) => setShowModalTutorial(true)}
          > */}
          <div
            className={`flex mascote mascote-${team} ${
              contrast ? 'has-contrast' : ''
            } cursor-pointer relative lg:absolute`}
            onClick={(e) => setShowModalTutorial(true)}
            tabIndex={0}
            role="button"
            aria-label="Veja nosso tutorial"
          >
            <img
              src="/img/icon-mascote.svg"
              alt=""
              className="relative z-10 w-16"
            />
            <div className="flex flex-col items-start relative top-[-11px] lg:top-[-13px]">
              <p
                className={`${
                  contrast ? 'bg-black border-yellow' : 'bg-white'
                } rounded-[40px] py-1 px-2 relative left-[-17px] lg:left-[-26px] text-[10px] lg:text-sm`}
              >
                Olá! Eu sou o Tonzinho!
              </p>
              <p
                className={`${
                  contrast ? 'bg-black border-yellow' : 'bg-white'
                } rounded-[40px] py-1 px-8  relative left-[-17px] text-[10px] lg:text-sm`}
              >
                Veja nosso tutorial
              </p>
            </div>
          </div>
          {/* <img src={`/img/mascote-${team}.png`} width={180} /> */}
          {/* </div> */}
        </div>
        <div className="flex mt-6 align-middle justify-center relative">
          <UserInfo />

          <div
            className="cursor-pointer hidden lg:block absolute right-[-10px] bottom-0"
            onClick={(e) => setShowModalTutorial(true)}
            tabIndex={0}
            aria-label="Veja nosso tutorial"
            role="button"
          >
            <div
              className={`flex mascote mascote-${team} ${
                contrast ? 'has-contrast' : ''
              }`}
            >
              <img
                src="/img/icon-mascote.svg"
                alt=""
                className="relative z-10"
              />
              <div className="flex flex-col items-start relative top-[-13px]">
                <p
                  className={`${
                    contrast ? 'bg-black' : 'bg-white'
                  } rounded-[40px] py-1 px-2 relative left-[-26px]`}
                >
                  Olá! Eu sou o Tonzinho!
                </p>
                <p
                  className={`${
                    contrast ? 'bg-black' : 'bg-white'
                  } rounded-[40px] py-1 px-8  relative left-[-17px]`}
                >
                  Veja nosso tutorial
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
