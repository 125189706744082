import { useEffect, useState } from "react";
import DialogDocument from "./Document";
import DialogExternal from "./External";
import DialogGame from "./Game";
import DialogQuiz from "./Quiz";
import DialogText from "./Text";
import DialogVideo from "./Video";
import "./style.scss";
import { useModal } from "context/ModalContext";
import { createPortal } from "react-dom";
import ModalBoardData from "utils/modal-board-data";

const DialogItem = () => {
	const { modalData } = useModal();
	const [dialogData, setDialogData] = useState<ModalBoardData>();

	useEffect(() => {
		if (modalData) {
			setDialogData(modalData);
		}
	}, [modalData]);

	useEffect(() => {
		document.getElementById("root")?.setAttribute("aria-hidden", "true");
		return () => {
			document.getElementById("root")?.removeAttribute("aria-hidden");
		};
	}, []);
	useEffect(() => {
		if (dialogData?.type) {
			// dialog-game
			const dialogClass = [
				"game_wordsearch",
				"game_crossword",
				"game_puzzle",
				"game_enigma",
				"game_memory",
				"game_codex",
				"game_runner",
			].includes(String(dialogData?.type))
				? `.dialog-game`
				: `.dialog-${dialogData.type}`;

			console.log(dialogClass);
			const element = document.querySelector(dialogClass);
			setTimeout(() => {
				element?.querySelector("h3")?.focus();
			}, 500);
		}
	}, [dialogData?.type]);

	return createPortal(
		<>
			{dialogData ? (
				<div
					aria-modal="true"
					role="dialog"
					className={`fixed z-[999] top-0 left-0 w-full h-full wrapper-dialog dialog-board dialog-${dialogData?.type}`}
				>
					{dialogData?.type === "video" && (
						<DialogVideo
							key={`video-${new Date().getTime()}`}
							content={dialogData.dialogContent || undefined}
							onEvent={dialogData.onEvent}
							onClose={dialogData.onClose}
						/>
					)}

					{dialogData?.type === "text" && (
						<DialogText
							key={`text-${new Date().getTime()}`}
							content={dialogData.dialogContent || undefined}
							onEvent={dialogData.onEvent}
							onClose={dialogData.onClose}
						/>
					)}

					{dialogData?.type === "quiz" && (
						<DialogQuiz
							key={`quiz-${new Date().getTime()}`}
							content={dialogData.dialogContent || undefined}
							onEvent={dialogData.onEvent}
							onClose={dialogData.onClose}
						/>
					)}

					{dialogData?.type === "doc" && (
						<DialogDocument
							key={`doc-${new Date().getTime()}`}
							content={dialogData.dialogContent || undefined}
							onEvent={dialogData.onEvent}
							onClose={dialogData.onClose}
						/>
					)}

					{dialogData?.type === "external" && (
						<DialogExternal
							key={`external-${new Date().getTime()}`}
							content={dialogData.dialogContent || undefined}
							onEvent={dialogData.onEvent}
							onClose={dialogData.onClose}
						/>
					)}

					{[
						"game_wordsearch",
						"game_crossword",
						"game_puzzle",
						"game_enigma",
						"game_memory",
						"game_codex",
						"game_runner",
					].includes(String(dialogData?.type)) && (
						<DialogGame
							key={`external-${new Date().getTime()}`}
							content={dialogData?.dialogContent || undefined}
							onEvent={dialogData?.onEvent}
							onClose={dialogData?.onClose}
						/>
					)}
				</div>
			) : (
				<></>
			)}
		</>,
		document.body,
	);
};

export default DialogItem;
