import ChallengeInfo from "classes/challenge";
import Skeleton from "components/Skeleton";
import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import BB_ROUTES from "routes/const";
import { getChallenges } from "services/challenges.service";

import tippy, { animateFill } from "tippy.js";
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/backdrop.css";
import "tippy.js/dist/tippy.css";

import { AiFillStar } from "react-icons/ai";
import "./style.scss";

const Challenge = () => {
  const { setBreadCrumbItens, setSidebar, setPageTitle, user, team, contrast } =
    useContext(AuthContext);
  const [filter, setFilter] = useState("Todos");
  const [loading, setLoading] = useState(false);
  const [itens, setItens] = useState<ChallengeInfo[]>([]);
  const [itensBkp, setItensBkp] = useState<ChallengeInfo[]>([]);

  useEffect(() => {
    setSidebar(false);
    if (user) {
      loadData();
    }
    setPageTitle("Desafios");
  }, [user]);

  useEffect(() => {
    if (itensBkp.length > 0) {
      onFilter({
        label: "Todos",
        value: null,
      });
    }
  }, [itensBkp]);

  useEffect(() => {
    setBreadCrumbItens([
      {
        isFirst: true,
        name: "Jornada / ",
        active: false,
        path: BB_ROUTES.TRAIL(),
      },
      {
        isFirst: false,
        name: "Desafios",
        active: true,
        path: "/ideias",
      },
    ]);
  }, [setBreadCrumbItens]);

  const filters = [
    {
      label: "Todos",
      value: null,
    },
    {
      label: "Realizados",
      value: true,
    },
    {
      label: "Não realizados",
      value: false,
    },
  ];

  function loadData() {
    setLoading(true);
    getChallenges()
      .then((resp) => {
        const list = resp.filter((item) => {
          return item.active === true && item.techniques.includes("GT35");
        });
        list.forEach((item) => {
          item.done = user?.challenges[item._id] > 0 ? true : false;
        });
        if (["DWMyGO0", "D1gpwRE"].some((id) => user?.challenges[id])) {
          list.forEach((item) => {
            if (["DWMyGO0", "D1gpwRE"].includes(item._id)) {
              item.done = true;
            }
          });
        }

        setItensBkp(list);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onFilter = (obj: any) => {
    setFilter(obj.label);
    const list = itensBkp.filter((data) => {
      if (obj.value != null) {
        return data.done === obj.value;
      } else {
        return data.done === true || data.done == false;
      }
    });

    list.forEach((item) => {
      const types: any[] = [];
      item.points.forEach((pt) => {
        types.push(pt.category);
      });
      item.type = types;
    });
    setItens(list);
  };

  function showPoints(type: string, item: any) {
    return item.find((e: any) => e.category === type)?.total || 0;
  }

  tippy(".myButton", {
    content: `3 estrelas se completar antes do período<br /> 2 estrelas se completar exatamente na data limite <br />  1 estrela se completar depois do período`,
    allowHTML: true,
    arrow: false,
    theme: team,
  });

  const ItemAriaLabel = (item: ChallengeInfo) => {
    const term = item.type.includes("star") ? "estrela" : "moeda";
    let resultText = item.done
      ? "Você ganhou "
      : "Se concluir esse desafio ganhará ";

    const options: Array<any> = [];
    item.points.forEach((el: any) => {
      if (el.category == "coin") {
        const label = el.total > 1 ? " moedas" : " moeda";
        options.push(el.total + label);
      }

      if (el.category == "star") {
        const label = el.total > 1 ? " estrelas" : " estrela";
        options.push(el.total + label);
      }
    });

    resultText += options.join("e");

    resultText += item.done ? " por concluir esse desafio" : "";

    return resultText;
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <nav className="grid grid-cols-1 lg:grid-cols-3 gap-7 w-full items-center mb-10">
          {filters.map((item: any) => (
            <button
              className={`rounded-[10px] shadow-2xl font-bold py-3 text-sm ${
                contrast ? "text-yellow" : "text-white"
              } ${
                filter == item.label
                  ? `active ${
                      contrast ? "border-yellow" : "border-white"
                    } border`
                  : ""
              }`}
              onClick={() => onFilter(item)}
              tabIndex={0}
              aria-label={`Filtrar por ${item.label}`}
            >
              {item.label}
            </button>
          ))}
        </nav>
        {loading && (
          <div className="box grid grid-cols-4 gap-7">
            <Skeleton size={8} type="MODULE" />
          </div>
        )}
        {!loading && itens.length > 0 && (
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-7 mb-20">
            {itens
              .filter((e) => {
                if (e.extra.showOnList !== false) {
                  return e;
                }
              })
              .map((item: ChallengeInfo, index: number) => (
                <div
                  className={`box ${
                    contrast ? "has-contrast" : ""
                  } rounded-[10px] px-[18px] py-10 pb-4 cursor-pointer relative`}
                  tabIndex={0}
                  aria-label={`Desafio ${
                    item.done ? "conquistado" : "não conquistado"
                  }`}
                >
                  {item.done && (
                    <div className="icon-checked absolute top-[-10px] right-[-10px]">
                      <img
                        aria-label={"Imagem indicativa de desafio concluído"}
                        src={require("../../assets/images/board-icon/checked.png")}
                      />
                    </div>
                  )}
                  <div>
                    <div
                      className="mb-8 flex items-center justify-center"
                      aria-hidden={true}
                      tabIndex={-1}
                    >
                      <img
                        aria-label={`Imagem relacionada ao desafio ${item.challenge}`}
                        src={item.badgeUrl}
                        alt={item.challenge}
                        width={78}
                      />
                    </div>
                    <h3
                      className={`text-lg ${
                        contrast ? "text-yellow" : `text-gray-300`
                      }  font-medium mb-3 title text-center`}
                      tabIndex={0}
                      aria-label={item.challenge}
                    >
                      {item.challenge}
                    </h3>
                    <p
                      className={` ${
                        contrast ? "text-yellow" : `text-gray-300`
                      } text-sm  text-center`}
                      tabIndex={0}
                      aria-label={item.description}
                    >
                      {item.description}
                    </p>
                  </div>
                  <div
                    className="stats flex"
                    aria-label={ItemAriaLabel(item)}
                    tabIndex={0}
                  >
                    {item.points && item.points.length && (
                      <>
                        {item.points.map((point: any, index: number) => (
                          <div tabIndex={-1} aria-hidden={false}>
                            {point.category == "star" && (
                              <p
                                className="flex"
                                aria-hidden={true}
                                tabIndex={-1}
                              >
                                <button
                                  className={
                                    item.extra.Tip ? "myButton flex" : "flex"
                                  }
                                  role={item.extra.Tip ? "contentinfo" : "term"}
                                >
                                  <AiFillStar color="#FCFC30" size={20} />
                                  <span
                                    className={`${
                                      contrast ? "text-yellow" : "text-gray-300"
                                    } underline  ml-2 font-normal`}
                                  >
                                    {point.total}
                                    {point.total > 1 ? " Estrelas" : " Estrela"}
                                  </span>
                                </button>
                              </p>
                            )}
                            {point.category == "coin" && (
                              <p
                                className="flex"
                                aria-hidden={true}
                                tabIndex={-1}
                              >
                                <img src="/img/coin.svg" />
                                <span
                                  className={`${
                                    contrast ? "text-yellow" : "text-gray-300"
                                  }`}
                                >
                                  {point.total}
                                  {point.total > 1 ? " Moedas" : " Moeda"}
                                </span>
                              </p>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      {!loading && itens.length == 0 && (
        <div className="flex justify-center items-center">
          <p className="text-white text-2xl text-center">
            Não há desafios {filter}!
          </p>
        </div>
      )}
    </>
  );
};

export default Challenge;
