import { api } from "./api";

export async function GetBadgess(playerId:any) {
  const { data } = await api.post("/database/challenge/aggregate", [
    {
      $match: {
        techniques: {
          $in: [
            "GT02"
          ]
        }
      }
    }
  ]);
  
  return data;
}

