import { useAuth } from 'context/AuthContext';
import { BiLogOut } from 'react-icons/bi';
import { useEffect } from 'react';
import Logo from 'components/Logo';

const NotAllowed = () => {
  const { signOut, setPageTitle } = useAuth();

  useEffect(() => {
    setPageTitle('');
  }, []);

  return (
    <div className="flex flex-col flex-center items-center justify-center relative bg-blue h-screen gap-16 lg:gap-0">
      <div className="w-[260px] relative mb-12">
        <Logo />
      </div>
      <div className=" mt-4 mb-4 py-4 px-6">
        <p className="text-white font-normal text-base text border-t border-white py-4">
          Olá! Você não possui acesso ao Game Tô On no BB.
        </p>
      </div>
    </div>
  );
};

export default NotAllowed;
