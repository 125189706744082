import { api } from './api';

export async function getNotificationsCustom(
  userId: string,
  equipe: any,
  time: any
) {
  const payload: any = [
    {
      $match: {
        $and: [
          {
            $or: [
              {
                player: userId,
              },
              {
                team: equipe,
              },
              {
                $and: [
                  {
                    player: {
                      $exists: false,
                    },
                  },
                  {
                    team: {
                      $exists: false,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      $sort: {
        created: 1,
      },
    },
    {
      $limit: 1
    },
  ];

  if (time) {
    payload.unshift({
      $match: {
        created: {
          $gt: {
            $date: time,
          },
        },
      },
    });
  }

  const { data } = await api.post(
    `database/notification_custom__c/aggregate?strict=true`,
    payload
  );
  if (!Array.isArray(data)) {
    return null;
  } else {
    return data;
  }
}

export async function updateNotificationCustom(data?: any[]) {
  try {
    const response = await api.put('/database/notification_custom__c', data);
    if (!response.data) {
      throw new Error('Erro ao salvar notificação');
    } else {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}
