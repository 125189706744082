import { useAuth } from 'context/AuthContext';
import { Link } from 'react-router-dom';

interface Options {
  customClass?: string;
  layoutClass?: string;
  titleColor?: string;
  title: JSX.Element;
  ctaText: string;
  children: JSX.Element;
  path: string;
  buttonText?: string;
  bgColorButton?: string;
  titleColorButton?: string;
  actionButton?: () => void;
}

const Box = ({
  customClass,
  title,
  titleColor,
  ctaText,
  children,
  layoutClass,
  path,
  buttonText,
  bgColorButton,
  titleColorButton,
  actionButton,
}: Options) => {
  const { contrast } = useAuth();

  return (
    <section
      className={`section flex flex-col justify-between shadow-md rounded-[10px] mb-5 py-7 px-4 section-${customClass} ${
        layoutClass ? layoutClass : ``
      }`}
    >
      <h4
        style={{
          color: contrast ? '#fcfc30' : titleColor ? titleColor : `#ffffff`,
        }}
        className="font-bold text-base mb-6"
        tabIndex={0}
      >
        {title}
      </h4>
      <div className="inner-section mb-8">{children}</div>
      <div className="flex justify-between items-center">
        <Link to={path} tabIndex={0} aria-label={ctaText}>
          <span
            className="underline hover:no-underline text-xs font-bold"
            style={{
              color: titleColor ? titleColor : `#FCFC30`,
            }}
          >
            {ctaText}
          </span>
        </Link>
        {buttonText && (
          <button
            onClick={actionButton}
            tabIndex={0}
            className={`${
              contrast && 'border-yellow border-2'
            } button px-3 py-1 rounded-[20px]`}
            style={{
              backgroundColor: contrast
                ? '#000'
                : bgColorButton
                ? bgColorButton
                : 'transparent',
              color: contrast
                ? '#fcfc30'
                : titleColorButton
                ? titleColorButton
                : '#FCFC30',
            }}
          >
            {buttonText}
          </button>
        )}
      </div>
    </section>
  );
};

export default Box;
