import { useAuth } from "context/AuthContext";
import { useState } from "react";
import { toast } from "react-toastify";
import { purchaseItem } from "services/store.service";
import { ReactComponent as Currency } from "../../../assets/img/currency.svg";
import "./style.scss";

const BoxStore = (props: any) => {
  const [resgatar, setResgatar] = useState(false);
  const [loading, setloading] = useState(false);
  const [isResgated, setisResgated] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const { user, refreshUser, contrast } = useAuth();

  console.log(props.global);

  function confirmResgate() {
    setloading(true);
    if (user?._id) {
      purchaseItem(props.data._id, "me").then((data) => {
        if (data.status === "OK") {
          toast(`👍 Resgate realizado com sucesso.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setisResgated(true);
          refreshUser();
        } else if (data.status === "UNAUTHORIZED") {
          if (
            data?.restrictions?.find(
              (e: string) => e === "limite_de_compra_excedido"
            )
          ) {
            toast(`❌ Você atingiu o limite de compra.`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (
            data?.restrictions?.find(
              (e: string) => e === "data_posse_menor_que_90_dias"
            )
          ) {
            toast(
              `❌ Você ainda não pode comprar na loja, apenas quando completar 90 dias a partir da data de posse.`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else {
            toast(
              `❌ Você não possui moedas suficientes para realizar a troca.`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          setResgatar(false);
          setisResgated(false);
        } else {
          toast(`❌ Ocorreu um erro, tente novamente mais tarde.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setloading(false);
      });
    }
  }

  function showValue() {
    const value = props.data.requires.length
      ? props.data.requires.find((e: any) => e.item === "coin")
      : 0;
    return value.total ? value.total : 0;
  }

  return (
    <>
      <div
        className={`
        box-store w-full
        ${contrast ? "has-contrast" : ""}
        ${
          props.data.amout <= props.data.owned && props.data.amout !== -1
            ? "disabled"
            : ""
        }
        ${resgatar ? "resgatar" : ""}
        ${isResgated ? "isResgated" : ""}
        ${user?.point_categories?.coin < showValue() ? "no-ammount" : ""}
      `}
        tabIndex={0}
        aria-label={`
          ${resgatar ? "resgatar" : ""}
          ${isResgated ? "resgatado" : ""}`}
      >
        <figure>
          <img
            src={
              props.data?.image?.medium?.url ||
              require("../../../assets/img/no-image.png")
            }
            aria-hidden={true}
            alt="Loja"
          />
        </figure>
        {isResgated ? (
          <div className="text-store">
            <h2>Parabéns!</h2>
            <p>Você resgatou esse item.</p>
            <button onClick={() => (setResgatar(false), setisResgated(false))}>
              Fechar
            </button>
          </div>
        ) : (
          <div className="text-store">
            <p
              aria-label={`${props.data.name}. ${props.data?.description}`}
              tabIndex={0}
            >
              {props.data?.name} <br />
              {props.data?.description}
            </p>
            <div
              className="currency"
              aria-label={`Valor: ${showValue()} ${
                showValue() > 1 ? "moedas" : "moeda"
              }`}
              tabIndex={0}
            >
              <Currency aria-hidden={true} />
              <span aria-hidden={true}>{showValue()}</span>
              {resgatar && (
                <div className="more-info">
                  <h2>Já é quase seu!</h2>
                  <p>
                    Você se dedicou e conseguiu pontuação suficiente para
                    resgatar esse item.
                  </p>
                  <span>
                    <b>Saldo atual: </b>
                    <div className="currency">
                      <Currency />
                      <span>{user?.point_categories?.coin || 0}</span>
                    </div>
                  </span>
                </div>
              )}
            </div>
            {props.data.amout > props.data.owned || props.data.amout !== -1 ? (
              <div className="resgatar">
                {resgatar ? (
                  <div className="confirm-resgate">
                    <button
                      disabled={loading}
                      onClick={confirmResgate}
                      aria-label="Confirmar resgate"
                    >
                      {loading ? "resgatando..." : "Confirmar resgate"}
                    </button>
                    <button
                      disabled={loading}
                      onClick={() => setResgatar(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <>
                    <p
                      tabIndex={0}
                      aria-label={`Quantidade disponível de: ${
                        props.data.amount - props.data.owned
                      }`}
                    >
                      <strong>Quantidade disponível:</strong>{" "}
                      {props.data.amount - props.data.owned}
                    </p>
                    {props.global.activeStore ? (
                      <>
                        {props.disabled ? (
                          <button
                            onClick={() => {
                              toast.error(
                                `O item estará disponível para resgate em ${props.disabled}`
                              );
                            }}
                          >
                            Disponível em {props.disabled}
                          </button>
                        ) : (
                          <button onClick={() => setResgatar(true)}>
                            Resgatar
                          </button>
                        )}
                      </>
                    ) : (
                      <button
                        onClick={() => {
                          toast.error(
                            `O item estará disponível para resgate em breve`
                          );
                        }}
                      >
                        Disponível em breve
                      </button>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="esgotado">Esgotado</div>
            )}
          </div>
        )}

        {(user?.point_categories?.coin < showValue() ||
          !props.global.activeStore ||
          props.disabled) && (
          <div className="tooltip">
            {user?.point_categories?.coin < showValue() && (
              <div className="content-tooltip">
                <p>
                  Você não possui pontuação o suficiente para resgatar esse
                  item.
                </p>
                <div className="currency">
                  <b>Saldo atual: </b>
                  <Currency />
                  <span>{user?.point_categories?.coin || 0} moedas</span>
                </div>
              </div>
            )}

            {(!props.global.activeStore || props.disabled) && (
              <div className="content-tooltip">
                <p>
                  O item estará disponível para resgate em{" "}
                  {props.disabled && props.global.activeStore
                    ? props.disabled
                    : "breve"}
                  .
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={`${resgatar ? "mask-show" : "mask-hide"}`}></div>
    </>
  );
};

export default BoxStore;
