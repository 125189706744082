import { api } from './api';

export async function GetItems() {
  const { data } = await api.get('virtualgoods/item?q=active:true');
  return data;
}

export async function purchaseItem(item: string, player: string) {
  try {
    const { data } = await api.post('virtualgoods/purchase_by_player/'+player, {
      item,
    });
    return data;
  } catch (error) {
    return error;
  }
}