import { useAuth } from "context/AuthContext";
import { useEffect, useRef, useState } from "react";
import BB_ROUTES from "routes/const";
import moment from "moment";
import ResponsivePagination from "react-responsive-pagination";

import "./styles.scss";
import { getExtratoSimples } from "services/extrato.service";
import { ReactComponent as Star } from "../../assets/img/star.svg";
import { ReactComponent as Mount } from "../../assets/img/currency.svg";
import ReactPaginate from "react-paginate";

export function Extrato() {
  const paginationRef = useRef(null);
  const [extrato, setExtrato] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { setBreadCrumbItens, user, setPageTitle, contrast } = useAuth();
  const [filteredData, setFilteredData] = useState([]);

  const [allFilterOptions, setAllFilterOptions] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {}, []);

  useEffect(() => {
    // Extrair nomes únicos de itens para opções de filtro
    const uniqueItemNames = Array.from(
      new Set(filteredData.map((item: any) => item.item.name))
    );
    setAllFilterOptions(uniqueItemNames);
  }, [filteredData]);

  useEffect(() => {
    setBreadCrumbItens([
      {
        isFirst: true,
        name: "Inicio/",
        active: false,
        path: BB_ROUTES.HOME(),
      },
      {
        isFirst: false,
        name: `Extrato`,
        active: true,
        path: "/",
      },
    ]);
    setPageTitle("Extrato");
  }, [setBreadCrumbItens]);

  useEffect(() => {
    setLoading(true);
    if (user?._id) {
      loadData();
    }
  }, [user, startDate, endDate, selectedFilter]);

  async function loadData() {
    const id = user?._id || "";

    const start = moment(`${startDate} 00:00:00`).format("YYYY-MM-DDTHH:mm:ss");
    const end = moment(`${endDate} 23:59:59`).format("YYYY-MM-DDTHH:mm:ss");

    const data = await getExtratoSimples(
      id,
      startDate ? `${start}.000Z` : "2023-01-02T00:00:00.000Z",
      endDate ? `${end}.000Z` : "NOW"
    );

    const itens = data;
    const filteredByType = itens.filter(
      (item: any) => item.type === 0 && !!item.origin.name
    );

    // Apply filter if a filter is selected
    const filteredData = selectedFilter
      ? filteredByType.filter((item: any) => item.item.name === selectedFilter)
      : filteredByType;

    setExtrato(filteredData);
    setFilteredData(filteredData);
    setLoading(false);
  }

  function Items({ currentItems }: any) {
    return (
      <>
        {currentItems && currentItems.length ? (
          <div className={`w-full contents ${contrast ? "has-contrast" : ""}`}>
            {currentItems?.map((item: any) => (
              <>
                <tr
                  tabIndex={0}
                  aria-label={`Por: ${item.origin.name} em ${moment(
                    item.time
                  ).format("DD/MM/YYYY HH:mm")}, você ganhou ${item.total} ${
                    item.item._id == "coin" ? "moeda" : "estrela"
                  } ${item.total > 1 ? "s" : ""}`}
                >
                  <td aria-hidden={true}>
                    {moment(item.time).format("DD/MM/YYYY HH:mm")}
                  </td>
                  <td aria-hidden={true}>{item.origin.name}</td>
                  <td aria-hidden={true}>
                    <div className="actions flex flex-col lg:flex-row">
                      {item.item._id === "coin" && (
                        <span className="flex gap-1">
                          <Mount />
                          {item.total}
                          <span>moeda{item.total > 1 ? "s" : ""}</span>
                        </span>
                      )}
                      {item.item._id === "star" && (
                        <span className="flex gap-1">
                          <Star />
                          {""}
                          {item.total}
                          {""}
                          <span>estrela{item.total > 1 ? "s" : ""}</span>
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </div>
        ) : (
          <tr>
            <td colSpan={100}>Nenhum extrato para exibir!</td>
          </tr>
        )}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }: any) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = filteredData.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredData.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event: any) => {
      const newOffset = (event * itemsPerPage) % filteredData.length;
      console.log(
        `User requested page number ${event}, which is offset ${newOffset}`
      );
      setCurrentPage(event);
      setItemOffset(newOffset);
    };

    const ariaLabelBuilder = (pageNumber: number, isCurrent: number) => {
      if (isCurrent) {
        return `Página ${pageNumber}, atual`;
      } else {
        return `ir para a página ${pageNumber}`;
      }
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ResponsivePagination
          ariaNextLabel="Próxima"
          ariaPreviousLabel="Anterior"
          nextLabel="Próxima >"
          total={pageCount}
          onPageChange={handlePageClick}
          previousLabel="< Anterior"
          className={`pagination mt-6 ${
            currentItems.length <= 1 ? "hidden" : ""
          }`}
          current={currentPage}
        />
      </>
    );
  }

  return (
    <>
      <div className="w-full text-white text-xl font-lighter main-extrato">
        {loading ? (
          "Carregando"
        ) : (
          <div className={`content-extrato ${contrast ? "has-contrast" : ""}`}>
            <div className="action-date flex  flex-col lg:flex-row justify-center lg:justify-start items-center lg:items-start gap-2">
              <label>
                <span>Filtrar por:</span>
                <select
                  value={selectedFilter || ""}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                  className="h-[38px] text-[#777777] text-[15px]"
                >
                  <option value="">Todos</option>
                  {allFilterOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                <span>De:</span>
                <input
                  type="date"
                  aria-label={`Data inicial ${startDate ? startDate : ""}`}
                  placeholder="00/00/000"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </label>
              <label>
                <span>Até:</span>
                <input
                  type="date"
                  aria-label={`Data final ${endDate ? endDate : ""}`}
                  placeholder="00/00/000"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </label>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Detalhamento</th>
                  <th>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {filteredData && filteredData.length > 0 && (
                  <PaginatedItems itemsPerPage={20} />
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
