import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/AuthContext';

import { Link, useNavigate } from 'react-router-dom';

import { AzureProfile } from 'sso/azure/ui/AzureProfile';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'sso/azure/authConfig';
import { getAzureToken } from 'sso/azure/azure-api';
import Button from 'components/ui/button';
import { BiDesktop, BiMobileAlt } from 'react-icons/bi';
import BB_ROUTES from 'routes/const';
import './styles.scss';
import { useWebsocketNotification } from 'context/WebsocketNotificationContext';
import { api } from 'services/api';
import { setStorageItem } from 'utils/storage';
import Logo from 'components/Logo';

const Login = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const { loginWithToken, login, isAuthenticated, DateDifferenceCheck } =
    useContext(AuthContext);
  const [useSSO, setUseSSO] = useState(false);

  useEffect(() => {
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  function loginSSO() {
    setLoading(true);
    setUseSSO(true);
    setInvalidUser(false);
  }

  function redirectUser() {
    setTimeout(() => {
      DateDifferenceCheck();
    }, 300);
  }

  async function handleLoginSSO(response: any) {
    try {
      const password = await getAzureToken();
      const username = response.userPrincipalName;

      if (username && password) {
        await login({ username, password });
        redirectUser();
      }
    } catch (e) {
      setLoading(false);
      setInvalidUser(true);
      setUseSSO(false);
    }
  }

  useEffect(() => {
    async function doLogin(username: string, password: string) {
      try {
        await login({ username, password });
        redirectUser();
      } catch (e: any) {
        setLoading(false);
        setInvalidUser(true);

        if (
          e?.statusCode === 401 &&
          e?.data?.output?.length > 0 &&
          e.data.output[0] ===
            'Your login is disabled, contact the administrator'
        ) {
          navigate(`/acesso-negado`);
        }
      }
    }

    async function doLoginWithToken(token: string) {
      try {
        await loginWithToken(token);
        redirectUser();
      } catch (e) {
        setLoading(false);
        setInvalidUser(true);
      }
    }

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const agent = params.get('agent');
    const secret = params.get('secret');
    const tokenItem = params.get('tokenItem');

    if (token) {
      setLoading(true);
      doLoginWithToken(token);
    } else if (agent && secret) {
      setLoading(true);
      doLogin(agent, secret);
    } else if (tokenItem) {
      setLoading(true);
      const decodedToken = atob(tokenItem);
      const tokenParts = decodedToken.split(':');
      const id = tokenParts[0];
      const password = tokenParts[1];

      setStorageItem('readOnly', 'true');
      doLogin(id, password);
    } else {
      setInvalidUser(true);
    }
  }, [loginWithToken, login]);

  return (
    <div className="flex flex-col flex-center items-center justify-center relative bg-blue h-screen gap-16 lg:gap-0">
      <div className="w-[120px] lg:w-[260px] relative -mb-10 lg:mb-12">
        <Logo />
      </div>

      <div className=" mt-4 mb-4 py-4 px-6">
        <p
          className="text-white font-normal text-base text border-t border-white py-4"
          tabIndex={0}
        >
          Olá! o Game Tô On no BB está disponível somente aos novos funcionários
          <br />
          BB empossados a partir de Setembro/2023. Incentive a participação dos
          <br />
          seus novos colegas! Conhecimento dá jogo!
        </p>
      </div>
      {loading && !useSSO && !invalidUser && (
        <>
          <div className="cta">
            <div className=" hover:ring hover:ring-white rounded-md shadow-xl font-bold px-4 w-full text-xl p-2 bg-yellow-100 text-gray-300">
              <span>CARREGANDO...</span>
            </div>
          </div>
        </>
      )}

      {!loading && invalidUser && (
        <>
          <div className="cta">
            <Button
              color="clear"
              className="w-full text-xl p-2 bg-yellow-100 text-gray-300"
              aria-label={'Fazer login'}
              onClick={() => {
                window.location.replace(`${process.env.REACT_APP_SSO_URL}`); // FIXME get this value from properties
              }}
            >
              <div className="flex flex-row items-center justify-center space-x-2">
                <BiDesktop />
                <span>Clique para entrar</span>
              </div>
            </Button>
          </div>
        </>
      )}

      {useSSO ? <AzureProfile onAuthenticate={handleLoginSSO} /> : null}
    </div>
  );
};

export default Login;
