import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";

import Skeleton from "components/Skeleton";
import { useModal } from "context/ModalContext";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import BB_ROUTES from "routes/const";
import {
	checkIsLike,
	getMyIdeaOrContributte,
	getThemes,
	ideaList,
	likeIdea,
	paginatedIdeaItems,
	ranking,
} from "services/ideia.service";
import { getNameSetor } from "utils/constants";
import HeaderIdeiasPage from "./header";
import "./style.scss";

import Paginated from "classes/Paginated";
import AvaliationsIdea from "components/AvaliationsIdea";
import Lottie from "lottie-react";
import React, { MouseEvent } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { SortPagination } from "utils/paginations";
import { ReactComponent as Like } from "../../assets/img/like.svg";
import { ReactComponent as Unlike } from "../../assets/img/unlike.svg";

import IdeiaDestaque from "assets/lottie/destaque-ideia.json";
import moment from "moment";

const IdeiaPage = () => {
	const PAGE_SIZE = 10;

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [showDetail, setShowDetail] = useState(false);
	const [dataIdeias, setDataIdeias] = useState<Paginated<any>>();

	const [ideas, setIdeas] = useState<Array<any>>();

	const [listThemes, setThemes] = useState<any>([]);

	const {
		setBreadCrumbItens,
		setPageTitle,
		globalConfig,
		allowAddIdea,
		myIdeaOrContributte,
		setMyIdeaOrContributte,
		myIdea,
		contrast,
	} = useContext(AuthContext);

	const { setShowModalAddIdeia, showModalAddIdeia, refreshListIdeias } =
		useModal();
	const [loadinglike, setLoadinglike] = useState<any>(false);
	const params = useParams();
	const [showNewIdea, setShowNewIdea] = useState(false);
	const { user } = useContext(AuthContext);

	const [formFilter, setformFilter] = useState({
		search: "",
	});

	const [currentPage, setCurrentPage] = useState(0);

	const [offsetPage, setOffsetPage] = useState(0);

	// const [myIdea, setMyIdea] = useState('');

	useEffect(() => {
		setOffsetPage(0 * PAGE_SIZE);
		getLisThemes();
		getData();
	}, []);

	useEffect(() => {
		if (refreshListIdeias) {
			getData();
		}
	}, [refreshListIdeias]);

	// useEffect(() => {
	//   if (!!myIdeaId) {
	//     console.log(myIdeaId);
	//   }
	// }, [myIdeaId]);

	useEffect(() => {
		if (!myIdeaOrContributte && allowAddIdea) {
			setShowNewIdea(true);
		}
		console.log(allowAddIdea);
	}, [myIdeaOrContributte, allowAddIdea]);

	// useEffect(() => {
	//   if (user) {
	//     setTimeout(() => {
	//       getData();
	//     }, 300);
	//   }
	// }, [offsetPage]);

	useEffect(() => {
		setBreadCrumbItens([
			{
				isFirst: true,
				name: "Jornadas / ",
				active: false,
				path: BB_ROUTES.HOME(),
			},
			{
				isFirst: false,
				name: " Central de ideias",
				active: true,
				path: "/ideias",
			},
		]);
		setPageTitle("Central de ideias");
	}, [setBreadCrumbItens]);

	function getLisThemes() {
		getThemes()
			.then((resp) => {
				if (resp.length > 0) {
					setThemes(resp);
				}
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 300);
			});
	}

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => {
		scrollToTop();
	}, [params]);

	const paginatedData = (array: any, n: number) => {
		const pageSize = Math.ceil(array.length / n);

		return Array.from({ length: pageSize }, (_, index) => {
			const start = index * n;
			return array.slice(start, start + n);
		});
	};

	function getData() {
		setLoading(true);
		const { search } = formFilter;
		let arr = [];
		ideaList(search)
			.then((resp) => {
				arr = resp.sort(
					(a: any, b: any) =>
						new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
				);
				setDataIdeias(arr);
				// setIdeas(resp);

				if (arr) {
					setIdeas(paginatedData(arr, 10));
					// console.log();
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}

	async function like(e: any) {
		const verifyIsLike = e.likes.find((e: any) => e.userId === user?._id);

		if (verifyIsLike) {
			toast.warning("Você já curtiu essa ideia! 😉");
			return false;
		}

		setLoadinglike(true);
		likeIdea({
			actionId: "like_idea",
			userId: "me",
			attributes: {
				id_idea: e._id,
				from_player: user && user._id,
			},
		})
			.then((resp) => {
				if (!resp.errorCode) {
					toast.success("Você curtiu essa ideia. 👍🏻");
					getData();
				} else {
					toast.error(
						"Não foi possível curtir essa ideia, tente novamente mais tarde!",
					);
				}
			})
			.finally(() => {
				setLoadinglike(false);
			});
	}

	function nameThemes(id: string) {
		const theme = listThemes.find((e: any) => e._id === id);
		return theme ? theme.title : "--";
	}

	function verificaIsIdeia() {
		if (user) {
			getMyIdeaOrContributte(user?._id)
				.then((resp) => {
					if (resp.length > 0) {
						toast.error("Uma ideia já foi adicionada.");
					} else {
						setShowModalAddIdeia(!showModalAddIdeia);
					}
				})
				.finally(() => {
					setTimeout(() => {
						setLoading(false);
					}, 300);
				});
		}
	}

	function handleDetailIdea(idea: any, position: number) {
		setShowDetail(true);
		return `/ideias/${idea._id}?p=${currentPage}`;
	}

	const handlePageClick = (event: any) => {
		if (!loading) {
			scrollToTop();
			setShowDetail(false);
			setCurrentPage(event.selected);
			// const offset = event.selected * PAGE_SIZE;
			// setOffsetPage(offset);
		}
	};

	const handleInputChange = (event: any) => {
		event.preventDefault();
		setOffsetPage(0 * PAGE_SIZE);
		getData();
	};

	const isMyIdea = (item: any) => {
		if (
			item.player[0]._id === user?._id ||
			item.participants.some((item: any) => item.value === user?._id)
		) {
			return true;
		}
	};

	const handleDownloadClick = async (event: MouseEvent) => {
		event.preventDefault();

		const imageUrl =
			"https://s3.amazonaws.com/funifier/games/64c2b2d95ef07b2b9790b972/images/65aad8ff2abf3222a8dd1bc9_original_IdeiaDestaque.png";

		try {
			const response = await fetch(imageUrl);
			const blob = await response.blob();

			const imgUrl = URL.createObjectURL(blob);

			const downloadLink = document.createElement("a");
			downloadLink.href = imgUrl;
			downloadLink.download = "ideia_destaque.png";
			document.body.appendChild(downloadLink);

			downloadLink.click();

			document.body.removeChild(downloadLink);
		} catch (error) {
			toast.error("Erro ao fazer o download da imagem: " + error);
		}
	};

	const ariaLabelBuilder = (pageNumber: number, isCurrent: number) => {
		if (isCurrent) {
			return `Página ${pageNumber}, atual`;
		} else {
			return `ir para a página ${pageNumber}`;
		}
	};

	const Item = ({ data }: any) => {
		return (
			<>
				{!!data && data.length ? (
					<>
						{data?.map((e: any, index: number) => (
							<article
								className="trail flex mb-[18px] rounded-[10px] relative transition"
								key={index}
							>
								<Link
									to={handleDetailIdea(e, index)}
									tabIndex={-1}
									aria-hidden={true}
								>
									<>
										{e.image ? (
											<img
												src={e.image}
												alt="Imagem Ideia"
												width={80}
												tabIndex={-1}
												aria-hidden={true}
											/>
										) : (
											<img
												tabIndex={-1}
												aria-hidden={true}
												src={require("../../assets/img/user.png")}
												width={80}
												alt="Imagem Ideia"
												title="Sem imagem"
											/>
										)}
									</>
								</Link>
								<div className="ml-3 lg:ml-7 mb-7 text-gray-300 w-full">
									<div className="text border-b border-[#69696E] pb-3 mb-3">
										<Link to={handleDetailIdea(e, index)}>
											<p
												className={`title ${
													contrast ? "text-yellow" : "text-purple"
												}  text-sm`}
											>
												{nameThemes(e.theme)}
											</p>
											<p
												className={`description flex items-center ${
													contrast ? "text-yellow" : ""
												}`}
											>
												{e.title}
												{e.isDestaque && (
													<>
														<Lottie
															animationData={IdeiaDestaque}
															loop={true}
															className="w-[50px]"
															title="Ideia em destaque"
														/>
														{isMyIdea(e) && (
															<span className="text-gray-300 text-xs">
																Se não conseguir visualizar o seu card{" "}
																<a
																	href="#"
																	onClick={handleDownloadClick}
																	target="_blank"
																	className="font-bold underline"
																	rel="noreferrer"
																>
																	clique aqui
																</a>
															</span>
														)}
													</>
												)}
											</p>
											<p className={`user ${contrast ? "text-yellow" : ""}`}>
												Autor: {e.player[0]?.name} (
												{getNameSetor(e.player[0]?.teams || [])})
											</p>

											{!!e.participants && e.participants.length > 0 && (
												<div
													className={`comment pt-7 ${
														contrast ? "text-yellow" : ""
													}`}
												>
													<p>
														<strong>Participante(s):</strong>
													</p>
													<ul className="flex flex-wrap w-[80%]">
														{e.participants.map((item: any, index: number) => (
															<li
																key={index}
																className="p-2 mr-2 flex-[1_1_48%]"
															>
																<span className="flex items-center">
																	<img
																		src={require("../../assets/img/user.png")}
																		width={30}
																		alt="participante"
																		title={item.label}
																	/>
																	{item.label}
																</span>
															</li>
														))}
													</ul>
												</div>
											)}
											<i
												className={`${
													contrast ? "text-yellow" : ""
												} mt-2 block`}
											>
												Criada em:{" "}
												{moment(e.created_at).format("DD/MM/YYYY HH:mm")}
											</i>
										</Link>
									</div>
									<div className="avaliation flex">
										<div className="like mb-[10px] lg:mb-0">
											<button
												className="flex flex-row"
												disabled={loadinglike}
												onClick={() => like(e)}
											>
												<figure>
													{e.likes.find((e: any) => e.userId === user?._id) ? (
														<Like />
													) : (
														<Unlike />
													)}
												</figure>
												<span
													className={`mx-2 underline ${
														contrast ? "text-yellow" : ""
													}`}
												>
													{e.likesCount} curtida{e.likesCount > 1 && "s"}
												</span>
											</button>
										</div>
										<div className="avalie flex items-center ml-2">
											<AvaliationsIdea
												qtd={e?.last_evaluate?.attributes?.grade || 0}
											/>
										</div>
									</div>
								</div>
							</article>
						))}
					</>
				) : (
					<>
						<p className="text-4xl text-center"></p>
					</>
				)}
			</>
		);
	};

	return (
		<div className="main-ideia ">
			{showNewIdea && (
				<section className="actions relative  mb-[40px] flex 	justify-end mt-5">
					<button
						className={`btn-open-add ${contrast ? "has-contrast" : ""}`}
						onClick={() => verificaIsIdeia()}
					>
						Adicionar ideia
					</button>
				</section>
			)}

			{!!params && params.id && showDetail && (
				<HeaderIdeiasPage
					id={params.id}
					themes={listThemes}
					list={dataIdeias}
				/>
			)}

			<section
				className={`${
					contrast ? "bg-black" : "bg-[#FFEC8D]"
				} shadow-md my-7	 py-10 lg:px-7 px-3 mb-[18px] rounded-[10px] relative transition`}
			>
				<p className={`text-base ${contrast ? `text-yellow` : "text-purple"}`}>
					<strong>Confira outras ideias</strong>
				</p>
				<article className="my-10">
					<form className="form mb-7" onSubmit={handleInputChange}>
						<div className="form-group flex justify-between items-center">
							<label className="flex-1">
								<input
									type="text"
									placeholder="Buscar..."
									aria-label="Buscar idéia"
									onChange={(e) =>
										setformFilter({
											...formFilter,
											...{ search: e.target.value },
										})
									}
									name="search"
									className={`w-full ${
										contrast
											? "bg-black text-yellow border-yellow"
											: "text-gray-600 bg-white"
									} p-2 border`}
									id="buscarIdeia"
								/>
							</label>

							<button
								type="submit"
								className={`${
									contrast
										? "bg-black text-yellow border border-yellow"
										: "bg-purple text-white"
								} text-base px-3 py-2`}
							>
								Buscar
							</button>
						</div>
					</form>
					{!loading && ideas && (
						<>
							<Item data={ideas[currentPage]} />
						</>
					)}
					{loading && <Skeleton size={10} type="IDEA" />}
					{!!ideas && (
						<ReactPaginate
							breakClassName={`${
								contrast ? "text-yellow" : "text-gray-300"
							} text-base`}
							previousClassName={`${
								contrast ? "text-yellow" : "text-gray-300"
							} text-base`}
							nextClassName={`${
								contrast ? "text-yellow" : "text-gray-300"
							} text-base`}
							breakLabel="..."
							nextLabel="Próxima >"
							onPageChange={handlePageClick}
							pageRangeDisplayed={4}
							pageCount={ideas?.length || 0}
							ariaLabelBuilder={ariaLabelBuilder}
							previousLabel="< Anterior"
							renderOnZeroPageCount={null}
							pageLinkClassName={`${
								contrast ? "text-yellow" : "text-gray-300"
							} text-base underline px-3`}
							activeLinkClassName={`${
								contrast ? "text-white" : "text-purple"
							} no-underline`}
							className={`pagination flex justify-items-end justify-end mt-6 ${
								!ideas ? "hidden" : ""
							}`}
						/>
					)}
				</article>
			</section>
		</div>
	);
};

export default IdeiaPage;
