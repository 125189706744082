import ChallengeInfo from "classes/challenge";
import Skeleton from "components/Skeleton";
import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import BB_ROUTES from "routes/const";
import { getChallenges } from "services/challenges.service";
import Box from "../Box";

const Challenge = () => {
	const { user, team, contrast } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [itens, setItens] = useState<ChallengeInfo[]>([]);

	useEffect(() => {
		loadChallenge();
	}, []);

	function loadChallenge() {
		setLoading(true);
		getChallenges()
			.then((resp) => {
				const data = resp.filter((item) => {
					return item.active == true;
				});
				setItens(data);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<Box
			title={<>Desafios</>}
			ctaText="Ver todos"
			path={BB_ROUTES.CHALLENGE()}
			layoutClass="border border-white box-challenge"
			customClass="challenge"
			children={
				<>
					{loading && <Skeleton type={"CHALLENGE-SIDEBAR"} size={3} />}
					{!loading && (
						<>
							{itens.slice(0, 3).map((item: ChallengeInfo, index: number) => (
								<article
									className="flex items-center mb-7"
									key={item._id}
									tabIndex={0}
									role="contentinfo"
									aria-label={item.challenge}
								>
									<div className="thumb mr-3">
										<img
											aria-hidden={true}
											tabIndex={-1}
											src={item.badgeUrl}
											alt={item.challenge}
											width={50}
											className="rounded-full"
										/>
									</div>
									<div>
										<h5
											className={`text-xs ${
												contrast ? "text-yellow" : `text-white`
											}  font-medium `}
											aria-hidden={true}
											tabIndex={-1}
										>
											{item.challenge}
										</h5>
										<p
											className={`text-[10px] ${
												contrast ? "text-yellow" : `text-white`
											} font-normal`}
											aria-hidden={true}
											tabIndex={-1}
										>
											{item.description}
										</p>
										{!!item.points &&
											!!item.points[0] &&
											item.points[0].category && (
												<div className="flex">
													{item.points[0].category == "star" && (
														<button
															className="flex font-light mr-4 text-[10px] items-center  cursor-default"
															role="contentinfo"
														>
															<AiFillStar
																color="#FCFC30"
																size={18}
																aria-hidden={true}
																tabIndex={-1}
															/>{" "}
															<span
																className={
																	contrast ? "text-yellow" : `text-white`
																}
															>
																{item.points[0].total} estrela
																{item.points[0].total > 1 ? "s" : ""}
															</span>
														</button>
													)}
													{item.points[0].category == "coin" && (
														<button
															className="flex font-light text-[10px] items-center cursor-default"
															role="contentinfo"
														>
															<AiFillStar
																color="#FCFC30"
																size={18}
																aria-hidden={true}
																tabIndex={-1}
															/>{" "}
															<span
																className={
																	contrast ? "text-yellow" : `text-white`
																}
															>
																{item.points[0].total} estrela moeda
																{item.points[0].total > 1 ? "s" : ""}
															</span>
														</button>
													)}
												</div>
											)}
									</div>
								</article>
							))}
						</>
					)}
				</>
			}
		></Box>
	);
};

export default Challenge;
