import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import "./style.scss";
import ModalIdeia from "components/ModalAddIdeia";
import ModalBoard from "components/ModalBoard";
import ModalTutorial from "components/ModalTutorial";
import TabFocusTrap from "components/TabFocusTrap";
import { useModal } from "context/ModalContext";
import ReactModal from "react-modal";
import { getNameSetor, isGestor } from "utils/constants";
import LayoutGestor from "./LayoutGestor";
import Layout from "./LayoutUser";

ReactModal.setAppElement("#root");

export interface ElementPros {
	children?: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
	[key: string]: any;
}
const ControlLayout = ({ children }: ElementPros) => {
	const { showModalAddIdeia, showModalTutorial, setShowModalAddIdeia } =
		useModal();
	const [team, setTeam] = useState("");
	const { isAuthenticated, user } = useContext(AuthContext);

	useEffect(() => {
		if (isAuthenticated) {
			if (getNameSetor(user?.teams || []) === "Agente Comercial") {
				setTeam("agente-negocial");
			}
			if (getNameSetor(user?.teams || []) === "Agente de Tecnologia") {
				setTeam("agente-ti");
			}
		} else {
			setTeam("");
		}
	}, [user]);

	return (
		<>
			{isGestor(user?.teams || []) ? (
				<LayoutGestor children={children} team={team} />
			) : (
				<Layout children={children} team={team} />
			)}
			<ReactModal
				aria={{
					modal: true,
				}}
				role={"dialog"}
				shouldFocusAfterRender={true}
				shouldCloseOnOverlayClick={false}
				isOpen={showModalAddIdeia}
				onRequestClose={() => {
					setShowModalAddIdeia(false);
				}}
				contentLabel="Central de idéias"
				portalClassName="relative z-[900]"
				overlayClassName={
					"fixed z-100 top-0 left-0 bg-black bg-opacity-60 flex flex-col items-center w-screen p-2 2xl:p-6 h-full"
				}
				contentElement={() => (
					<TabFocusTrap
						isOpen={showModalAddIdeia}
						setIsOpen={setShowModalAddIdeia}
					>
						<ModalIdeia />
					</TabFocusTrap>
				)}
			/>
			{showModalTutorial && <ModalTutorial />}
			<ModalBoard />
		</>
	);
};

export default ControlLayout;
