import ReactPlayer from "react-player/lazy";

import { FC, useContext, useEffect, useRef, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { TiTimes } from "react-icons/ti";
import { AuthContext } from "context/AuthContext";
import { DialogContent } from "utils/modal-board-data";

interface Option {
  content?: DialogContent;
  onEvent: (e?: any) => void;
  onClose: () => void;
}

const DialogVideo: FC<Option> = ({ content, onEvent, onClose }: Option) => {
  const rpRef = useRef<any>();
  const { user, contrast } = useContext(AuthContext);

  const [play, setPlay] = useState(false);
  const [trackLog, setTrackLog] = useState(false);

  useEffect(() => {
    setPlay(true);
  }, [content]);

  const handleProgress = (state: {
    playedSeconds: number;
    played: number;
    loadedSeconds: number;
    loaded: number;
  }) => {
    if (!trackLog && state.played > 0.95 && content?._id && !!user) {
      onEvent({ skip: false });
      setTrackLog(true);
    }
  };

  return (
    <>
      <div
        key={`video-${new Date().getTime()}`}
        className={`min-h-[100vmin] max-h-[95vh] md:min-h-0 overflow-y-auto flex flex-col ${
          contrast ? "bg-black border border-yellow" : "bg-white"
        } rounded-xl p-1 md:p-5 lg:rounded-3xl w-11/12 lg:w-5/6 dialog-video`}
      >
        <div
          className={
            content?.image
              ? `flex flex-col w-full overflow-y-auto content`
              : "w-full overflow-y-auto content"
          }
        >
          <div className="flex justify-end items-end mb-3">
            <button
              onClick={onClose}
              className="rounded-full"
              aria-label="Fechar modal de vídeo"
            >
              <TiTimes
                color={contrast ? "#fcfc30" : "#69696E"}
                size={25}
                aria-hidden={true}
              />
            </button>
          </div>
          <div className="stars mb-4 flex items-center justify-between">
            <h3
              className={`font-bold px-4 md:p-0 leading-tight md:text-2xl ${
                contrast ? "text-yellow" : "text-black"
              }`}
              tabIndex={0}
            >
              {content?.title}
            </h3>
            <div className="flex" aria-hidden={true}>
              {Array.from({ length: content?.star || 0 }).map((i: any) => (
                <div className="mr-1">
                  <AiFillStar color="#FCFC30" size={"25"} />
                </div>
              ))}
            </div>
          </div>

          <div className="h-4/5">
            <ReactPlayer
              ref={rpRef}
              url={content?.url}
              className="player"
              playing={play}
              stopOnUnmount={true}
              controls
              width="100%"
              height="500"
              onProgress={handleProgress}
              onReady={() => {}}
              config={{
                youtube: {
                  playerVars: { showinfo: 0, controls: 1 },
                },
              }}
            />
            {content?.content && (
              <div
                tabIndex={0}
                className={`text font-light content ${
                  contrast ? "text-yellow has-contrast" : "text-gray-300"
                }  text-base mb-6`}
                dangerouslySetInnerHTML={{ __html: content?.content }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DialogVideo;
