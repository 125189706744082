import { AxiosError } from "axios";
import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchUser, signInRequest, userMyEquipes } from "services/auth.service";
import { updateUserStatus } from "services/profile.service";
import { User } from "types/user";
import { clearStorage, getStorageItem, setStorageItem } from "utils/storage";

import { actionLog } from "services/actionlog.service";
import { GetBadgess } from "services/badges.service";
import { getGlobalConfig } from "services/global-config.service";
import { getMyIdeaOrContributte } from "services/ideia.service";
import { getTotalNotificationsUnread } from "services/notifications.service";
import { GetTutoriails } from "services/tutorial.service";
import { BreadCrumbItens } from "types/navigation";
import { api } from "../../services/api";
import { setAuthorizationHeader } from "../../services/interceptors";
import {
	createTokenCookies,
	getToken,
	removeTokenCookies,
} from "../../utils/tokenCookies";

interface SignInCredentials {
	username: string;
	password: string;
	profile?: { url: string };
}

interface AuthContextData {
	login: (credentials: SignInCredentials) => Promise<void | AxiosError>;
	loginWithToken: (access_token: string) => Promise<void>;
	signOut: (pathname?: string) => void;
	refreshUser: () => void;
	user?: User;
	equipeUsersLogged?: any;
	badges?: any;
	team?: string;
	isAuthenticated: boolean;
	loadingUserData: boolean;
	isMSTeams: boolean;
	isSidebar: boolean;

	breadcrumbItens: BreadCrumbItens[] | undefined;
	countNotify: number;
	tutorial: Array<any>;
	myIdeaOrContributte: boolean;
	myIdea?: any;
	refreshTrail: boolean;
	allowAddIdea: boolean;
	acessibility?: boolean | undefined;
	contrast?: boolean | undefined;
	setBreadCrumbItens: (val?: BreadCrumbItens[]) => void;
	setTeam: (val?: any) => void;
	blockBoard: boolean;
	setBlockBoard: (val?: any) => void;
	setSidebar: (val?: any) => void;
	pageTitle?: string;
	setPageTitle: (val?: any) => void;
	setCountNotify: (val?: any) => void;
	globalConfig: any | undefined;
	setGlobalConfig: (val?: any) => void;
	DateDifferenceCheck: () => void | boolean;
	setTutorial: (val?: any) => void;
	setMyIdeaOrContributte: (val?: any) => void;
	setMyIdea: (val?: any) => void;
	setRefreshTrail: (val?: any) => void;
	setAllowAddIdea: (val?: any) => void;
	setAcessibility: (val?: any) => void;
	setContrast: (val?: any) => void;
}

interface AuthProviderProps {
	children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
	const navigate = useNavigate();
	const token = getToken();

	const isAuthenticated = !!token;

	const [user, setUser] = useState<User | undefined>();
	const [equipeUsersLogged, setEquipeUsersLogged] = useState<any | []>([]);
	const [badges, setBadges] = useState<any | []>([]);
	const [loadingUserData, setLoadingUserData] = useState(true);

	const [isMSTeams, setIsMSTeams] = useState<boolean>(false);
	const [team, setTeam] = useState<string | undefined>();
	const [isSidebar, setSidebar] = useState(true);
	const [countNotify, setCountNotify] = useState<number>(0);
	const [breadcrumbItens, setBreadCrumbItens] = useState<
		BreadCrumbItens[] | undefined
	>();
	const [pageTitle, setPageTitle] = useState("");
	const [blockBoard, setBlockBoard] = useState<boolean>(false);
	const [myIdeaOrContributte, setMyIdeaOrContributte] =
		useState<boolean>(false);

	const [myIdea, setMyIdea] = useState<any>();
	const [globalConfig, setGlobalConfig] = useState<any>();

	const [tutorial, setTutorial] = useState<Array<any>>([]);

	const [refreshTrail, setRefreshTrail] = useState(false);

	const [allowAddIdea, setAllowAddIdea] = useState(false);

	const [acessibility, setAcessibility] = useState(undefined);
	const [contrast, setContrast] = useState(undefined);

	const signOut = useCallback(
		(pathname?: string) => {
			console.log("logoutttt");
			setUser(undefined);
			removeTokenCookies();
			clearStorage();
			setAuthorizationHeader(api.defaults, "");
			removeTokenCookies();
			setLoadingUserData(false);
			navigate(pathname || "/login");
		},
		[navigate],
	);

	function getCookie(c_name?: any) {
		let i, x, y;
		const ARRcookies = document.cookie.split(";");
		for (i = 0; i < ARRcookies.length; i++) {
			x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
			y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
			x = x.replace(/^\s+|\s+$/g, "");
			if (x === c_name) {
				return unescape(y);
			}
		}
	}

	useEffect(() => {
		if (acessibility !== undefined && acessibility !== null)
			setStorageItem("acessibility", String(acessibility));
	}, [acessibility]);

	useEffect(() => {
		if (contrast !== undefined && contrast !== null)
			setStorageItem("contrast", String(contrast));
		if (contrast) {
			document.body.classList.add("has-contrast");
		} else {
			document.body.classList.remove("has-contrast");
		}
	}, [contrast]);

	useEffect(() => {
		setAcessibility(getStorageItem("acessibility"));
		setContrast(getStorageItem("contrast"));
	}, []);
	useEffect(() => {
		const c = getCookie("blocked");
		if (c) {
			setBlockBoard(true);
		}
	}, []);

	useEffect(() => {
		if (user) {
			getBadges();
			loadGlobalConfig();
			fetchTotorial();
		}
		if (user?.teams?.length) {
			getEquipe();
		}
	}, [user]);

	function getEquipe() {
		if (user?.teams?.length) {
			userMyEquipes(user?.teams[0]).then((response) => {
				setEquipeUsersLogged(response);
			});
		}
	}
	async function getBadges() {
		const data = await GetBadgess(user?._id);
		const badges: Array<any> = [];
		if (!!data && !!user) {
			data.forEach((badge: any) => {
				let item = Object.assign({}, badge);
				item = {
					...item,
					...{ completed: user?.challenges[item._id] > 0 ? true : false },
				};
				badges.push(item);
			});
		}
		setBadges(badges);
	}

	async function loadGlobalConfig() {
		const data = await getGlobalConfig();
		setGlobalConfig(data[0]);
	}

	async function setLog() {
		setTimeout(() => {
			actionLog("me", "login", {});
		}, 300);
	}

	async function fetchTotorial() {
		try {
			const data = await GetTutoriails();
			setTutorial(data[0].steps);
		} catch (e) {
			console.log(e);
		}
	}

	async function login({ username, password }: SignInCredentials) {
		try {
			const auth = await signInRequest({ username, password });

			if (auth.message) {
				throw auth;
			}

			const { access_token, refreshToken, permissions, roles } = auth;

			createTokenCookies(access_token, refreshToken);
			setAuthorizationHeader(api.defaults, access_token);

			const data = await fetchUser();

			// const { name, image } = data;

			const userData = { _id: data._id, ...data, permissions, roles };

			setUser(userData);

			setLog();

			return userData;
		} catch (error) {
			const err = error as AxiosError;
			console.log(err);
			throw err;
		}
	}

	async function loginWithToken(access_token: string) {
		try {
			if (!access_token) {
				return;
			}

			createTokenCookies(access_token, "");
			setAuthorizationHeader(api.defaults, access_token);

			const data = await fetchUser();

			const { name, image, extra } = data;

			const userData = {
				_id: data._id,
				...data,
				extra,
				name,
				image,
				permissions: null,
				roles: null,
			};

			setUser(userData);

			setLog();
			return userData;
		} catch (error) {
			const err = error as AxiosError;
			// console.log(err);
			throw err;
		}
	}

	async function refreshUser() {
		if (!!user && !!token) {
			getTotalNotificationsUnread(user._id).then((resp) => {
				setCountNotify(resp.length);
			});
		}
		const updatedUser = await updateUserStatus();
		setUser(updatedUser);
	}

	useEffect(() => {
		const token = getToken();

		async function getUserData() {
			setLoadingUserData(true);

			try {
				const response = await fetchUser();
				if (response) {
					// console.log("fetchUser",response);

					const { _id, name, image, email, permissions, roles } = response;
					setUser({ ...response, _id, name, image, email, permissions, roles });
				}
			} catch (error) {
				signOut();
			}

			setLoadingUserData(false);
		}

		async function checkIdea() {
			if (!user && !token) return;
			const check = await getMyIdeaOrContributte(String(user?._id));
			// console.log(check.length);
			setMyIdeaOrContributte(check.length > 0 ? true : false);
			setMyIdea(check.length > 0 ? check[0] : "");
			setAllowAddIdea(check.length > 0 ? false : true);
		}

		if (!user && token) {
			setAuthorizationHeader(api.defaults, token);
			getUserData();
		}

		if (!!user && !!token) {
			getTotalNotificationsUnread(user._id).then((resp) => {
				setCountNotify(resp.length);
			});
		}
		function handleSignoutEvent() {
			signOut();
		}

		document.addEventListener("signout", handleSignoutEvent, false);

		const _MSTeams = getStorageItem("isMSTeams");

		if (!!_MSTeams && _MSTeams === "true") {
			setIsMSTeams(true);
		}

		if (isAuthenticated) {
			if (
				user?.teams?.includes("DRj0IUB") ||
				user?.teams?.includes("DRjWqyD")
			) {
				if (user?.teams?.includes("DRj0IUB")) {
					document.body.classList.remove("default");
					document.body.classList.add("agente-negocial");
					setTeam("agente-negocial");
				} else if (user?.teams?.includes("DRjWqyD")) {
					document.body.classList.remove("default");
					document.body.classList.add("agente-ti");
					setTeam("agente-ti");
				}
			} else {
				document.body.classList.add("default");
				setTeam("default");
			}
		} else {
			document.body.classList.remove("agente-negocial");
			document.body.classList.remove("agente-ti");
			setTeam("default");
		}

		checkIdea();
		return () => {
			document.removeEventListener("signout", handleSignoutEvent);
		};
	}, [user, signOut]);

	const [searchParams] = useSearchParams();

	useEffect(() => {
		const sender = searchParams.get("invite");
		const teamsContext = searchParams.get("teamsContext");

		if (sender?.match(/^F\d+$/gi)) {
			setStorageItem("jogajuntobb_invite_code", sender);
		}

		if (!!teamsContext && teamsContext === "true") {
			setStorageItem("isMSTeams", "true");
			setIsMSTeams(true);
		}
	}, [searchParams]);

	useEffect(() => {
		const current_date = new Date();
		if (globalConfig) {
			if (user?.extra) {
				const date = new Date(user?.extra.data_posse);
				date.setDate(date.getDate() + globalConfig.idea.start_period_limit);

				if (current_date.getTime() <= date.getTime()) {
					setAllowAddIdea(true);
				}
			}
		}
	}, [globalConfig, user?.extra]);

	function DateDifferenceCheck() {
		if (!globalConfig) return;
		const providedDate = new Date(user?.extra.data_cadastro);
		const startPeriodLimit = globalConfig.idea.start_period_limit;
		const currentDate = new Date();
		const timeDifference = currentDate.getTime() - providedDate.getTime();
		const daysDifference = timeDifference / (1000 * 3600 * 24);

		return daysDifference > startPeriodLimit;
	}

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				user,
				loadingUserData,

				isMSTeams,
				breadcrumbItens,
				team,
				isSidebar,
				blockBoard,
				countNotify,
				tutorial,
				myIdeaOrContributte,
				myIdea,
				refreshTrail,
				allowAddIdea,
				acessibility,
				contrast,
				setBreadCrumbItens,
				login,
				loginWithToken,
				signOut,
				refreshUser,
				setTeam,
				setBlockBoard,
				setSidebar,
				equipeUsersLogged,
				badges,
				pageTitle,
				setPageTitle,
				setCountNotify,
				globalConfig,
				setGlobalConfig,
				DateDifferenceCheck,
				setTutorial,
				setMyIdeaOrContributte,
				setRefreshTrail,
				setAllowAddIdea,
				setMyIdea,
				setAcessibility,
				setContrast,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

function useAuth() {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
}

export { AuthProvider, useAuth };
