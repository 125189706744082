interface Position{
    desktop:{
        top:number;
        left:number;
        radius?:string;
    },
    mobile:{
        top:number;
        left:number;
        radius?:string;
    }   
}

const boxWidth=155;
const boxHeight=155;
const boxMobileWidth= 75;
const boxMobileHeight=89;
const defaultSpace = 7;
const defaultSpaceMobile = 2;

const BoxPositions: Array<Position> =[
    {   
        desktop:{
            top:0,
            left:0
        },
        mobile:{
            top:0,
            left:0
        }
    },{
        desktop:{
            top:boxHeight+defaultSpace,
            left:0,
            radius:'left-bottom'
        },
        mobile:{
            top:boxMobileHeight+defaultSpaceMobile,
            left:0,
            radius:'left-bottom'
        }
    },{
        desktop:{
            top:boxHeight+7,
            left:(boxWidth*1)+defaultSpace,
        },
        mobile:{
            top:boxMobileHeight+defaultSpaceMobile,
            left:(boxMobileWidth*1)+defaultSpace,
        }
    },{
        desktop:{
            top:boxHeight+7,
            left:(boxWidth*2)+(defaultSpace *2),
        },
        mobile:{
            top:boxMobileHeight+defaultSpaceMobile,
            left:(boxMobileWidth*2)+(defaultSpace *2),
        }
    },{
        desktop:{
            top:boxHeight+7,
            left:boxWidth*3 +(defaultSpace *3),
            radius:'right-top'
        },
        mobile:{
            top:boxMobileHeight+defaultSpaceMobile,
            left:boxMobileWidth*3 +(defaultSpace *3),
            radius:'right-top'
        }
    },{
        desktop:{
            top:(boxHeight*2)+(defaultSpace*2),
            left:(boxWidth*3)+(defaultSpace *3)
        },
        mobile:{
            top:(boxMobileHeight*2)+(defaultSpaceMobile*2),
            left:(boxMobileWidth*3)+(defaultSpace *3)
        }
    },{
        desktop:{
            top:(boxHeight*3)+(defaultSpace*3),
            left:(boxWidth*3)+(defaultSpace *3),
            radius:'right-bottom'
        },
        mobile:{
            top:(boxMobileHeight*3)+(defaultSpaceMobile*3),
            left:(boxMobileWidth*3)+(defaultSpace *3),
            radius:'right-bottom'
        }
    },{
        desktop:{
            top:(boxHeight*3)+(defaultSpace*3),
            left:(boxWidth*2)+(defaultSpace *2)
        },
        mobile:{
            top:(boxMobileHeight*3)+(defaultSpaceMobile*3),
            left:(boxMobileWidth*2)+(defaultSpace *2)
        }
    },{
        desktop:{
            top:(boxHeight*3)+(defaultSpace*3),
            left:(boxWidth*1)+(defaultSpace *1),
            radius:'left-top'
        },
        mobile:{
            top:(boxMobileHeight*3)+(defaultSpaceMobile*3),
            left:(boxMobileWidth*1)+(defaultSpace *1),
            radius:'left-top'         
        }
    },{
        desktop:{
            top:(boxHeight*4)+(defaultSpace*4),
            left:(boxWidth*1)+(defaultSpace *1)
        },
        mobile:{
            top:(boxMobileHeight*4)+(defaultSpaceMobile*4),
            left:(boxMobileWidth*1)+(defaultSpace *1)            
        }
    },{
        desktop:{
            top:(boxHeight*5) +(defaultSpace*5),
            left:(boxWidth*1)+(defaultSpace *1),
            radius:'left-bottom'   
        },
        mobile:{
            top:(boxMobileHeight*5) +(defaultSpaceMobile*5),
            left:(boxMobileWidth*1)+(defaultSpace *1)            
        }
    },{
        desktop:{
            top:(boxHeight*5) +(defaultSpace*5),
            left:(boxWidth*2)+(defaultSpace *2)
        },
        mobile:{
            top:(boxMobileHeight*5) +(defaultSpaceMobile*5),
            left:(boxMobileWidth*2)+(defaultSpace *2)            
        }
    },{
        desktop:{
            top:(boxHeight*5) +(defaultSpace*5),
            left:(boxWidth*3)+(defaultSpace *3),
            radius:'right-top' 
        },
        mobile:{
            top:(boxMobileHeight*5) +(defaultSpaceMobile*5),
            left:(boxMobileWidth*3)+(defaultSpace *3),
            radius:'right-top' 
        }
    },{
        desktop:{
            top:(boxHeight*6) +(defaultSpace*6),
            left:(boxWidth*3)+(defaultSpace *3)
        },
        mobile:{
            top:(boxMobileHeight*6) +(defaultSpaceMobile*6),
            left:(boxMobileWidth*3)+(defaultSpace *3)            
        }
    },{
        desktop:{
            top:(boxHeight*7) +(defaultSpace*7),
            left:(boxWidth*3)+(defaultSpace *3)
        },
        mobile:{
            top:(boxMobileHeight*7) +(defaultSpaceMobile*7),
            left:(boxMobileWidth*3)+(defaultSpace *3)            
        }
    },{
        desktop:{
            top:(boxHeight*8) +(defaultSpace*8),
            left:(boxWidth*3)+(defaultSpace *3),
            radius:'right-bottom' 
        },
        mobile:{
            top:(boxMobileHeight*8) +(defaultSpaceMobile*8),
            left:(boxMobileWidth*3)+(defaultSpace *3),
            radius:'right-bottom'             
        }
    },{
        desktop:{
            top:(boxHeight*8)+(defaultSpace*8),
            left:(boxWidth*2)+(defaultSpace *2)
        },
        mobile:{
            top:(boxMobileHeight*8)+(defaultSpaceMobile*8),
            left:(boxMobileWidth*2)+(defaultSpace *2)
        }
    },{
        desktop:{
            top:(boxHeight*8)+(defaultSpace*8),
            left:(boxWidth*1)+(defaultSpace *1)
        },
        mobile:{
            top:(boxMobileHeight*8)+(defaultSpaceMobile*8),
            left:(boxMobileWidth*1)+(defaultSpace *1)            
        }
    },{
        desktop:{
            top:(boxHeight*8)+(defaultSpace*8),
            left:0,
            radius:'left-top' 
        },
        mobile:{
            top:(boxMobileHeight*8)+(defaultSpaceMobile*8),
            left:0,
            radius:'left-top'            
        }
    },{
        desktop:{
            top:(boxHeight*9)+(defaultSpace*9),
            left:0
        },
        mobile:{
            top:(boxMobileHeight*9)+(defaultSpaceMobile*9),
            left:0            
        }
    },{
        desktop:{
            top:(boxHeight*10)+(defaultSpace*10),
            left:0
        },
        mobile:{
            top:(boxMobileHeight*10)+(defaultSpaceMobile*10),
            left:0            
        }
    },{
        desktop:{
            top:(boxHeight*11)+(defaultSpace*11),
            left:0,
            radius:'left-bottom'
        },
        mobile:{
            top:(boxMobileHeight*11)+(defaultSpaceMobile*11),
            left:0,
            radius:'left-bottom'         
        }
    },{
        desktop:{
            top:(boxHeight*11)+(defaultSpace*11),
            left:(boxWidth*1)+(defaultSpace *1)
        },
        mobile:{
            top:(boxMobileHeight*11)+(defaultSpaceMobile*11),
            left:(boxMobileWidth*1)+(defaultSpace *1)            
        }
    },{
        desktop:{
            top:(boxHeight*11)+(defaultSpace*11),
            left:(boxWidth*2)+(defaultSpace *2),
            radius:'right-top'
        },
        mobile:{
            top:(boxMobileHeight*11)+(defaultSpaceMobile*11),
            left:(boxMobileWidth*2)+(defaultSpace *2),
            radius:'right-top'            
        }
    },{
        desktop:{
            top:(boxHeight*12)+(defaultSpace*12),
            left:(boxWidth*2)+(defaultSpace *2)
        },
        mobile:{
            top:(boxMobileHeight*12)+(defaultSpaceMobile*12),
            left:(boxMobileWidth*2)+(defaultSpace *2)            
        }
    },{
        desktop:{
            top:(boxHeight*13)+(defaultSpace*13),
            left:(boxWidth*2)+(defaultSpace *2)
        },
        mobile:{
            top:(boxMobileHeight*13)+(defaultSpaceMobile*13),
            left:(boxMobileWidth*2)+(defaultSpace *2)            
        }
    },{
        desktop:{
            top:(boxHeight*14)+(defaultSpace*14),
            left:(boxWidth*2)+(defaultSpace *2),
            radius:'right-bottom'
        },
        mobile:{
            top:(boxMobileHeight*14)+(defaultSpaceMobile*14),
            left:(boxMobileWidth*2)+(defaultSpace *2),
            radius:'right-bottom'            
        }
    },{
        desktop:{
            top:(boxHeight*14)+(defaultSpace*14),
            left:(boxWidth*1)+(defaultSpace *1)
        },
        mobile:{
            top:(boxMobileHeight*14)+(defaultSpaceMobile*14),
            left:(boxMobileWidth*1)+(defaultSpace *1)
        }
    },{
        desktop:{
            top:(boxHeight*14)+(defaultSpace*14),
            left:0,
            radius:'left-top'
        },
        mobile:{
            top:(boxMobileHeight*14)+(defaultSpaceMobile*14),
            left:0,
            radius:'left-top'       
        }
    },{
        desktop:{
            top:(boxHeight*15)+(defaultSpace*15),
            left:0
        },
        mobile:{
            top:(boxMobileHeight*15)+(defaultSpaceMobile*15),
            left:0            
        }
    },{
        desktop:{
            top:(boxHeight*16)+(defaultSpace*16),
            left:0
        },
        mobile:{
            top:(boxMobileHeight*16)+(defaultSpaceMobile*16),
            left:0            
        }
    },{
        desktop:{
            top:(boxHeight*17)+(defaultSpace*17),
            left:0,
            radius:'left-bottom'
        },
        mobile:{
            top:(boxMobileHeight*17)+(defaultSpaceMobile*17),
            left:0,
            radius:'left-bottom'            
        }
    },{
        desktop:{
            top:(boxHeight*17)+(defaultSpace*17),
            left:(boxWidth*1)+(defaultSpace *1)
        },
        mobile:{
            top:(boxMobileHeight*17)+(defaultSpaceMobile*17),
            left:(boxMobileWidth*1)+(defaultSpace *1)            
        }
    },{
        desktop:{
            top:(boxHeight*17)+(defaultSpace*17),
            left:(boxWidth*2)+(defaultSpace *2)
        },
        mobile:{
            top:(boxMobileHeight*17)+(defaultSpaceMobile*17),
            left:(boxMobileWidth*2)+(defaultSpace *2)            
        }
    },{
        desktop:{
            top:(boxHeight*17)+(defaultSpace*17),
            left:(boxWidth*3)+(defaultSpace *3),
            radius:'right-top'
        },
        mobile:{
            top:(boxMobileHeight*17)+(defaultSpaceMobile*17),
            left:(boxMobileWidth*3)+(defaultSpace *3),
            radius:'right-top'            
        }
    },{
        desktop:{
            top:(boxHeight*18)+(defaultSpace*18),
            left:(boxWidth*3)+(defaultSpace *3)
        },
        mobile:{
            top:(boxMobileHeight*18)+(defaultSpaceMobile*18),
            left:(boxMobileWidth*3)+(defaultSpace *3)            
        }
    },{
        desktop:{
            top:(boxHeight*19)+(defaultSpace*19),
            left:(boxWidth*3)+(defaultSpace *3),
            radius:'right-bottom'
        },
        mobile:{
            top:(boxMobileHeight*19)+(defaultSpaceMobile*19),
            left:(boxMobileWidth*3)+(defaultSpace *3),
            radius:'right-bottom'            
        }
    },{
        desktop:{
            top:(boxHeight*19)+(defaultSpace*19),
            left:(boxWidth*2)+(defaultSpace *2)
        },
        mobile:{
            top:(boxMobileHeight*19)+(defaultSpaceMobile*19),
            left:(boxMobileWidth*2)+(defaultSpace *2)            
        }
    },{
        desktop:{
            top:(boxHeight*19)+(defaultSpace*19),
            left:(boxWidth*1)+(defaultSpace *1),
            radius:'left-top'
        },
        mobile:{
            top:(boxMobileHeight*19)+(defaultSpaceMobile*19),
            left:(boxMobileWidth*1)+(defaultSpace *1),
            radius:'left-top'            
        }
    },{
        desktop:{
            top:(boxHeight*20)+(defaultSpace*20),
            left:(boxWidth*1)+(defaultSpace *1),
            
        },
        mobile:{
            top:(boxMobileHeight*20)+(defaultSpaceMobile*20),
            left:(boxMobileWidth*1)+(defaultSpace *1)            
        }
    }
]

export{BoxPositions, boxHeight, boxWidth} ;