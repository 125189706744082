import { useState, useEffect, useMemo } from 'react';

import { GameInterface } from 'types/games';

import './style.scss';
import { useAuth } from 'context/AuthContext';
import { getToken } from 'utils/tokenCookies';
import { getStorageItem } from 'utils/storage';

const GameFolder: any = {
  game_memory: 'memory',
  game_puzzle: 'puzzle',
  game_crossword: 'crosswords',
  game_wordsearch: 'wordsearch',
  game_codex: 'codex',
  game_enigma: 'enigma',
  game_runner: 'runner',
  game_detective: 'detective',
  game_story: 'story',
  phishing: 'story/phishing',
};

export type GameProps = {
  id: string;
  contentId?: string;
  className?: string;
  [key: string]: any;
};

export function GameContainer({ id, contentId, className }: GameProps) {
  const { user, acessibility, contrast } = useAuth();
  const [gameURL, setGameUrl] = useState<any>();
  const [height, setHeight] = useState<number>();

  const hasAspectRatio16vs9 = useMemo(() => {
    if (
      typeof id === 'string' &&
      !!id.match(/(wordfinder|game_story|phishing)/gi)
    ) {
      return true;
    }

    return false;
  }, [id]);

  useEffect(() => {
    if (id) {
      try {
        let service =
          !!(window as any).FUNIFIER_ENV?.service &&
          typeof (window as any).FUNIFIER_ENV?.service === 'string'
            ? (window as any).FUNIFIER_ENV?.service
            : process.env.REACT_APP_API_URL;

        service = service?.replace(/^(https?:\/\/)(.*)(\.funifier.*)$/gi, '$2');

        const queryString = new URLSearchParams(
          `contentId=${contentId}&authorization=${getToken()}&service=${service}`
        );

        if (acessibility) {
          queryString.set('accessibility', 'true');
        } else {
          queryString.delete('accessibility');
        }

        if (contrast) {
          queryString.set('contrast', 'true');
        } else {
          queryString.delete('contrast');
        }

        setGameUrl(
          `/game/${GameFolder[id] || id}/index.html?${queryString.toString()}`
        );
      } catch (e) {}
    }
  }, [id, contentId]);

  useEffect(() => {
    function receiveMessage(message: any) {
      if (message.data && typeof message.data === 'string') {
        if (message.data.match(/height/gi)) {
          let _height = Number(message.data.replace(/[^.\d]/gi, ''));

          if (!isNaN(_height)) {
            _height = Math.round(_height);

            if (_height > 0) {
              setHeight(_height);
            } else {
              setHeight(undefined);
            }
          }
        }
      }
    }

    window.addEventListener('message', receiveMessage);

    return () => window.removeEventListener('message', receiveMessage);
  }, []);

  if (!gameURL) return null;

  return (
    <div
      className={`w-full md:overflow-y-auto game-container flex-1 ${
        className || ''
      }`}
    >
      <iframe
        src={gameURL}
        title="game iframe"
        className="w-full m-0 aspect-[9/16] md:aspect-[16/9]"
        width={'100%'}
      />
    </div>
  );
}
