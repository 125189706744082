/**
 * Composing <Route> in React Router v6
 * https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 *
 * Upgrading from v5
 * https://reactrouter.com/docs/en/v6/upgrading/v5
 */
import {
  Routes,
  Route,
  redirect,
  Navigate,
  useNavigate,
} from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import CustomNavigationClient from 'sso/azure/CustomNavigationClient';
import { MsalProvider } from '@azure/msal-react';
import BB_ROUTES from './const';
import {
  Login,
  NotFound,
  Trail,
  Board,
  Module,
  IdeiaPage,
  Challenge,
  Rule,
  Notification,
  NotAllowed,
} from 'pages';
import StorePage from 'pages/Store';
import { Extrato } from 'pages/Extrato';
import { Badges } from 'pages/Badges';
import AdministrationPage from 'pages/Administration';
import RankingConhecimentoPage from 'pages/RankingConhecimento';
import RankingComunidadePage from 'pages/RankingComunidade';

export const RouteList = ({ msalInstance }: any) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={msalInstance}>
      <Routes>
        <Route
          path={BB_ROUTES.HOME()}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path={BB_ROUTES.NOT_ALLOWED()}
          element={
            <PublicRoute>
              <NotAllowed />
            </PublicRoute>
          }
        />
        <Route
          path={BB_ROUTES.TRAIL()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.LOGIN()}>
              <Trail />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.MODULE()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.LOGIN()}>
              <Module />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.BOARD()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.LOGIN()}>
              <Board />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.IDEIAS()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.IDEIAS()}>
              <IdeiaPage />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.IDEIAS_INTERNA()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.IDEIAS_INTERNA()}>
              <IdeiaPage />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.CHALLENGE()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.CHALLENGE()}>
              <Challenge />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.RULE()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.RULE()}>
              <Rule />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.STORE()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.STORE()}>
              <StorePage />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.NOTIFICATION()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.NOTIFICATION()}>
              <Notification />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.EXTRATO()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.EXTRATO()}>
              <Extrato />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.BADGES()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.BADGES()}>
              <Badges />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.ADMINISTRATION()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.ADMINISTRATION()}>
              <AdministrationPage />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.RANKINGCONHECIMENTO()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.RANKINGCONHECIMENTO()}>
              <RankingConhecimentoPage />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.RANKINGCOMUNIDADE()}
          element={
            <PrivateRoute redirectTo={BB_ROUTES.RANKINGCOMUNIDADE()}>
              <RankingComunidadePage />
            </PrivateRoute>
          }
        />
        <Route
          path={BB_ROUTES.LOGIN()}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </MsalProvider>
  );
};
