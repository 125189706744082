import { stringToSearchPlayers } from "utils/helpers";
import { api } from "./api";
import { fetchUser } from "./auth.service";

export async function uploadPicture(payload: any) {
	try {
		const form_data = new FormData();
		form_data.append("file", new File([DataURIToBlob(payload)], "profile.jpg"));
		form_data.append(
			"extra",
			JSON.stringify({
				session: "avatar",
				thumbnails: [
					{ name: "small", width: 160, height: 160 },
					{ name: "medium", width: 260, height: 260 },
				],
			}),
		);

		const { data } = await api.post("upload/file", form_data);
		return data.status === "OK" ? data.uploads[0] : data;
	} catch (err) {
		console.log("uploadPicture", err);
	}
}

export async function updateUserProfile(payload: any) {
	const { data } = await api.put("player/me", payload);
	return data;
}

export async function updateUserByIdProfile(payload: any) {
	// let player = await fetchUser()
	// player = { ...player, ...payload }
	const { data } = await api.put(`player/${payload._id}`, payload);
	return data;
}

export async function updateUserImage(player: string, payload: any) {
	// let player = await fetchUser()
	// player = { ...player, ...payload }
	const { data } = await api.post(`player/${player}/image`, payload);
	return data;
}

export async function updateUserStatus() {
	// const { data } = await api.put('player/me/status')
	await api.put("player/me/status", {});
	const player = await fetchUser();
	return player;
}

export async function getLevel() {
	const { data } = await api.get("level");
	return data;
}

export async function deleteMyAccount(player: string) {
	const { data } = await api.delete(`/database/player?q=_id:'${player}'`);
	return data;
}

function DataURIToBlob(dataURI: string) {
	const splitDataURI = dataURI.split(",");
	const byteString =
		splitDataURI[0].indexOf("base64") >= 0
			? atob(splitDataURI[1])
			: decodeURI(splitDataURI[1]);
	const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

	return new Blob([ia], { type: mimeString });
}

export function fetchDataURL(url: string) {
	return new Promise<string>((resolve, reject) => {
		try {
			const xhr = new XMLHttpRequest();
			xhr.onload = () => {
				const reader = new FileReader();
				reader.onloadend = () => {
					resolve(`${reader.result}`);
				};
				reader.readAsDataURL(xhr.response);
			};
			xhr.open("GET", url);
			xhr.responseType = "blob";
			xhr.send();
		} catch (e) {
			reject(e);
		}
	});
}

export function resizeImage(base64Str: string) {
	return new Promise<string>((resolve, reject) => {
		try {
			const img = new Image();
			img.src = base64Str;

			img.onload = () => {
				const canvas = document.createElement("canvas");
				const MAX_WIDTH = 256;
				const MAX_HEIGHT = 256;
				let width = img.width;
				let height = img.height;

				if (width > height) {
					if (width > MAX_WIDTH) {
						height *= MAX_WIDTH / width;
						width = MAX_WIDTH;
					}
				} else {
					if (height > MAX_HEIGHT) {
						width *= MAX_HEIGHT / height;
						height = MAX_HEIGHT;
					}
				}
				canvas.width = width;
				canvas.height = height;
				const ctx = canvas.getContext("2d");
				if (ctx) {
					const x = -(width / 2);
					const y = -(height / 4);
					ctx.drawImage(img, x, y, width * 2, height * 2);
				}

				resolve(canvas.toDataURL());
			};
		} catch (e) {
			reject(e);
		}
	});
}

export async function findPlayer(search?: string, all = false) {
	let _search = search;

	if (typeof _search === "string") {
		_search = stringToSearchPlayers(_search);
	}

	const payload = {
		search: _search,
		all,
	};
	const { data } = await api.post("find/players", payload);
	return data;
}

export async function findPlayerByGestor(player: string, search?: string) {
	const payload = {
		player,
		search,
	};
	const { data } = await api.post("find/get_gestor_prefixo", payload);
	return data;
}

export async function findGestor(player: string, search?: string) {
	const payload = {
		player,
		search,
	};
	const { data } = await api.post("find/get_gestores", payload);
	return data;
}

export async function findNewPlayer(search: string, excludeids?: string[]) {
	if (!search) return [];

	const payload: { search: string; excludeids?: string } = {
		search,
	};

	if (typeof excludeids !== "undefined" && Array.isArray(excludeids)) {
		payload.excludeids = JSON.stringify(excludeids);
	}

	const { data } = await api.post("find/new_players", payload);
	if (!Array.isArray(data)) {
		return [];
	}
	return data;
}

export async function getUserProfile(_id: string) {
	const { data } = await api.get(`player/${_id}/status`);
	return data;
}

export async function getUserFriends(_id: string) {
	const resFriends = await api.get(`player/${_id}/friend`);

	const payload = [
		{
			$match: {
				_id: {
					$in: [...resFriends.data],
				},
			},
		},
		{
			$project: {
				_id: 1,
				name: 1,
				team: 1,
				friends: 1,
				extra: 1,
			},
		},
	];

	const { data } = await api.post("database/player_status/aggregate", payload);
	return data;
}

export async function addFriend(_id: string, friendId: string) {
	await api.get(`player/${_id}/friend/${friendId}`);
	await api.get(`player/${friendId}/friend/${_id}`);
	return;
}
