import { GameContainer } from "components/GameContainer";
import { useAuth } from "context/AuthContext";
import { DialogContent } from "utils/modal-board-data";
import {
  FC,
  IframeHTMLAttributes,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AiFillStar, AiOutlinePlus } from "react-icons/ai";
import { BiChevronDownSquare, BiChevronUpSquare } from "react-icons/bi";
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import { TiTimes } from "react-icons/ti";
import ReactModal from "react-modal";
import { Popover } from "react-tiny-popover";
import { getStorageItem } from "utils/storage";

interface Options {
  content?: DialogContent;
  onEvent: (val?: any) => void;
  onClose: () => void;
}
const DialogGame: FC<Options> = ({ content, onEvent, onClose }: Options) => {
  const { contrast } = useAuth();

  const contentGame = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!!content?.gameId && !!content.gameType) {
      setIsLoaded(true);

      if (!!contentGame && !!contentGame.current) {
        const modalEl = contentGame.current;

        setTimeout(() => {
          modalEl.querySelector("h3")?.focus();
        }, 300);
      }
    }
  }, [content]);

  const onNext = () => {
    onEvent({ skip: true });
    onClose();
  };

  return (
    <>
      <div
        key={`video-${new Date().getTime()}`}
        className={`min-h-[100vmin] max-h-[95vh] md:min-h-0 overflow-y-auto flex flex-col ${
          contrast ? "bg-black border-2 border-yellow" : "bg-white"
        } rounded-xl p-1 md:p-5 lg:rounded-3xl w-11/12 lg:w-5/6 dialog-game`}
        ref={contentGame}
        id="game-container"
      >
        <div className="flex justify-end items-end mb-3">
          <button
            onClick={onClose}
            className="rounded-full"
            aria-label="Fechar modal do game"
          >
            <TiTimes
              color={contrast ? "#fcfc30" : "#69696E"}
              size={25}
              aria-hidden={true}
            />
          </button>
        </div>
        <div className="stars mb-4 flex items-center justify-between">
          <div className="px-4 md:p-0 hidden lg:block">
            <h3
              className={`font-bold leading-tight md:text-2xl ${
                contrast ? "text-yellow" : "text-black"
              } mb-4`}
              tabIndex={0}
            >
              {content?.title}
            </h3>
            <p
              className={`${
                contrast ? "text-yellow" : "text-black"
              }font-normal leading-tight text-sm`}
              tabIndex={0}
            >
              {content?.description}
            </p>
          </div>
          <div
            className="px-4 md:p-0 lg:hidden block"
            onClick={() => setShowMore(!showMore)}
          >
            <h3
              className={`font-bold leading-tight md:text-2xl ${
                contrast ? "text-yellow" : "text-black"
              } mb-4`}
              aria-label={content?.title}
              tabIndex={0}
            >
              <span className="relative">
                {!showMore ? (
                  <>
                    {content?.title?.slice(0, 100)}
                    <BiChevronDownSquare className="absolute bottom-0 right-[-40px]" />{" "}
                  </>
                ) : (
                  content?.title
                )}
              </span>
            </h3>
            {showMore && (
              <p
                className={`${
                  contrast ? "text-yellow" : "text-black"
                }font-normal leading-tight text-sm relative`}
                tabIndex={0}
              >
                {content?.description}
                <BiChevronUpSquare
                  className="absolute bottom-0 right-0"
                  aria-hidden={true}
                />
              </p>
            )}
          </div>

          <div className="flex" aria-hidden={true}>
            {Array.from({ length: content?.star || 0 }).map((i: any) => (
              <div className="mr-1">
                <AiFillStar color="#FCFC30" size={"25"} />
              </div>
            ))}
          </div>
        </div>
        {isLoaded && (
          <GameContainer
            id={String(content?.gameType)}
            contentId={content?.gameId}
          />
        )}
      </div>
    </>
  );
};

export default DialogGame;
