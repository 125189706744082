import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from './context/AuthContext';
import { RouteList } from './routes';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/fonts/index.scss';
import './App.scss';

import { ModalProvider } from 'context/ModalContext';
import ToastProvider from 'context/ToastContext';
import { WebsocketProvider } from 'context/WebsocketContext';
import { WebsocketNotificationProvider } from 'context/WebsocketNotificationContext';
import { IPublicClientApplication } from '@azure/msal-browser';
import { ControlLayout } from 'components';
import 'react-datepicker/dist/react-datepicker.css';

type AppProps = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: AppProps) => (
  <Router>
    <AuthProvider>
      <WebsocketNotificationProvider>
        <WebsocketProvider>
          <ModalProvider>
            <ControlLayout>
              <RouteList msalInstance={pca} />
            </ControlLayout>
            <ToastProvider />
            {/* <Achievement /> */}
          </ModalProvider>
        </WebsocketProvider>
      </WebsocketNotificationProvider>
    </AuthProvider>
  </Router>
);

export default App;
