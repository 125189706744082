import { useContext } from 'react';
import { AuthContext, useAuth } from 'context/AuthContext';
import { useNavigate } from 'react-router';
import BB_ROUTES from 'routes/const';
import './style.scss';
import Logo from 'components/Logo';

interface OptiosnButton {
  text?: string;
  bgClass?: string;
  icon: JSX.Element;
  click: () => void;
}
const HeaderButton = ({ text, bgClass, icon, click }: OptiosnButton) => {
  const handler = () => {
    click();
  };

  return (
    <button
      className={`h-7 w-10 px-2 lg:px-5 lg:w-16 lg:h-10 py-2 btn-nav transition-all hover:bg-opacity-70 rounded-md lg:rounded-xl shadow-md flex items-center justify-center ${
        bgClass ? `custom-color ${bgClass}` : 'no-color'
      } ${!!icon && !!text ? `justify-between` : ``}`}
      onClick={handler}
    >
      {text ? text : ''}
      {icon}
    </button>
  );
};

export default function HeaderGestor() {
  const navigate = useNavigate();
  const { user, team } = useContext(AuthContext);
  const { signOut } = useAuth();

  return (
    <>
      <header className="container my-0 mx-auto mb-2 navigation-app-gestor">
        <div className="flex lg:hidden justify-between items-center py-[10px]">
          <span
            className={`text-yellow text-xs font-bold text-center team-name relative`}
          >
            <>Seja bem vindo, {user && user.name}</>
          </span>
          <button
            className="px-6 py-2 transition-all hover:bg-opacity-70 bg-transparent border  rounded-xl shadow-md flex align-middle justify-center"
            onClick={() => signOut()}
          >
            Sair
          </button>
        </div>
        <div className="flex items-center relative md:justify-between">
          <div
            className=" lg:block brand  cursor-pointer w-[120px] lg:w-[253px] relative"
            onClick={() => navigate(BB_ROUTES.HOME())}
          >
           <Logo />
          </div>

          <nav className="hidden lg:flex pt-0 lg:pt-6 justify-between items-center flex-col md:flex-row w-[79%] mb-0">
            <span
              className={`text-yellow text-xl font-bold text-center team-name relative hidden lg:block`}
            >
              <>Seja bem vindo, {user && user.name}</>
            </span>
            <ul className="flex justify-center items-center md:justify-end md:items-end mt-[18px] md:mt-0">
              <li className="hidden lg:block">
                <button
                  className="px-6 py-2 transition-all hover:bg-opacity-70 mr-3 bg-transparent border  rounded-xl shadow-md flex align-middle justify-center"
                  onClick={() => signOut()}
                >
                  Sair
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
}
