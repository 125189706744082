import { api } from './api';

export async function checkNotifications() {
  const { data } = await api.get(`notification?player=me&scope=private&published_min=-30d&max_results=1`);
  return data;  
}

export async function getNotifications() {
  const { data } = await api.get(`notification?player=me&scope=private&published_min=-30d&max_results=100`);
  return data;
}

export async function clearNotifications() {
  const { data } = await api.get('notification?player=me&scope=private&max_results=1&delete=true');
  return data;
}

export async function delNotification(id: string) {
  if (!id) return null;

  try {
    const { data } = await api.delete(`database/notification?q=_id:'${id}'`);
    return data;
  } catch (error) {
    return false;
  }
}

export async function getTotalNotificationsUnread(userId: string) {
  const payload = [
    {"$match": {"player.id": userId, "checked": {"$exists": false}}},
    {"$match": {"time": {"$gte": { "$date": "-30d" }}}},
    {"$limit": 100}
  ];
  const { data } = await api.post('database/notification/aggregate?strict=true', payload);
  return data;
}


export async function updateNotificationAsRead(obj: any) {
  const { data } = await api.put('database/notification', obj);
  return data;
}
