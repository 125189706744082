import Skeleton from "components/Skeleton";
import { useAuth } from "context/AuthContext";
import { addDays, format, isAfter } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import BB_ROUTES from "routes/const";
import { getServerTime } from "services/elarning.service";
import { getGlobalConfig } from "services/global-config.service";
import { GetItems } from "services/store.service";
import BoxStore from "./BoxStore";
import "./style.scss";

let isLoaded = false;
const StorePage = () => {
	const { setBreadCrumbItens, setPageTitle, user } = useAuth();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [dataItems, setDataItems] = useState([]);
	const [global, setGlobal] = useState<any>({});
	const [disable, setDisable] = useState<boolean | string>(false);

	useEffect(() => {
		setBreadCrumbItens([
			{
				isFirst: true,
				name: "Inicio/",
				active: false,
				path: BB_ROUTES.HOME(),
			},
			{
				isFirst: false,
				name: `Loja de recompensas`,
				active: true,
				path: "/",
			},
		]);

		if (user) {
			getData();
		}

		setPageTitle("Loja de recompensas");

		if (user) isLoaded = true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	async function getData() {
		setLoading(true);

		const currentDate = await getServerTime();
		const dataPosse = new Date(user?.extra?.data_posse);

		const dataPosseMais90 = addDays(dataPosse, 90);
		const dataFormatada = format(dataPosseMais90, "dd/MM/yyyy");
		if (isAfter(dataPosseMais90, currentDate)) setDisable(dataFormatada);

		GetItems()
			.then((resp) => {
				if (resp.length > 0) {
					setDataItems(resp);
				}
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 300);
			});

		getGlobalConfig()
			.then((resp) => {
				if (resp.length > 0) {
					setGlobal(resp[0]);
				}
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 300);
			});
	}

	return (
		<div className="w-full text-white text-xl font-lighter main-store">
			<div className="content-store grid lg:grid-cols-3 gap-5">
				{loading ? (
					<Skeleton size={10} type="TRAIL" />
				) : (
					dataItems.map((e: any, i) => (
						<BoxStore
							key={`box_${e._id}`}
							disabled={disable}
							data={e}
							global={global}
						/>
					))
				)}
			</div>
		</div>
	);
};

export default StorePage;
