import { useModal } from "context/ModalContext";
import { TiTimes } from "react-icons/ti";
import ReactModal from "react-modal";
import swiper, {
  Swiper as SwiperType,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper";

import { GrFormNext, GrFormPrevious } from "react-icons/gr";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Skeleton from "components/Skeleton";
import { useAuth } from "context/AuthContext";
import { useEffect, useRef, useState } from "react";
import { updateUserProfile } from "services/profile.service";
import "./style.scss";
import TabFocusTrap from "components/TabFocusTrap";

const ModalTutorial = () => {
  const refTutorial = useRef<HTMLDivElement>(null);
  const { tutorial, user, refreshUser } = useAuth();
  const [dataTutorial, setDataTutorial] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showModalTutorial, setShowModalTutorial } = useModal();
  const swiperRef = useRef<SwiperType>();

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode == 27) {
        onclose();
      }
      console.log(e.keyCode);
    };
    window.addEventListener("keydown", close);

    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    if (!!refTutorial && refTutorial.current) {
      console.log(`herxe`);
      setTimeout(() => {
        console.log(`here`);
        refTutorial?.current?.querySelector("h1")?.focus();
      }, 300);
    }
  }, [showModalTutorial, tutorial]);

  const onAcessbility = () => {
    if (!!refTutorial && refTutorial.current) {
      console.log(`herxe`);
      setTimeout(() => {
        console.log(`here`);
        refTutorial?.current?.querySelector("h1")?.focus();
      }, 300);
    }
  };

  const onclose = async () => {
    setShowModalTutorial(false);
    if (!user?.extra.view_tutorial) {
      await updateUserProfile({
        _id: user?._id,
        extra: {
          ...user?.extra,
          ...{
            view_tutorial: true,
          },
        },
      });

      refreshUser();
    }
  };

  useEffect(() => {
    // if (!!swiperRef && !!swiperRef.current) {
    //   // Access the Swiper instance
    //   const swiperInstance = swiperRef.current;
    //   // Add an event listener to the 'focus' event on the first slide
    //   swiperInstance.on('afterInit', (event) => {
    //     const firstSlide = event.slides[0];
    //     if (firstSlide) {
    //       console.log(firstSlide);
    //       // firstSlide.focus();
    //     }
    //   });
    // }
  }, []);

  return (
    <ReactModal
      aria={{
        modal: true,
      }}
      role={"dialog"}
      shouldFocusAfterRender={true}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      isOpen={showModalTutorial}
      onAfterOpen={onAcessbility}
      onRequestClose={onclose}
      contentLabel="Avaliação"
      portalClassName="relative z-[1000]"
      overlayClassName="fixed z-100 top-0 left-0 bg-black bg-opacity-60 flex flex-col items-center w-screen h-screen p-6 overflow-y-auto"
      contentElement={() => (
        <TabFocusTrap
          isOpen={showModalTutorial}
          setIsOpen={setShowModalTutorial}
        >
          <>
            <div
              ref={refTutorial}
              className={`bg-white shadow rounded-[10px] py-5 px-5 dialog transition-all flex flex-col justify-between w-full md:w-[450px] tutorial`}
            >
              <div className="flex justify-end items-end mb-3">
                <button
                  onClick={onclose}
                  className="rounded-full"
                  aria-label="Fechar modal de tutorial"
                >
                  <TiTimes color="#69696E" size={25} />
                </button>
              </div>
              {loading ? (
                <Skeleton
                  size={1}
                  type="LIST"
                  key={`tutorial_loading_${new Date().getTime()}`}
                />
              ) : (
                <div className="flex w-full mb-9 wrapper">
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    onBeforeInit={(swiper) => {
                      swiperRef.current = swiper;

                      // console.log(swiperRef.current.);
                    }}
                  >
                    {tutorial.map((item: any, index: number) => (
                      <SwiperSlide key={index}>
                        <div className="item">
                          <div
                            dangerouslySetInnerHTML={{ __html: item.text }}
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <div className="btns">
                    <button
                      onClick={() => swiperRef.current?.slidePrev()}
                      tabIndex={0}
                      aria-label="Ir para anterior"
                      className="btn"
                    >
                      <GrFormPrevious size={25} />
                    </button>
                    <button
                      onClick={() => swiperRef.current?.slideNext()}
                      tabIndex={0}
                      aria-label="Ir para próximo"
                      className="btn"
                    >
                      <GrFormNext size={25} />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        </TabFocusTrap>
      )}
    />
  );
};

export default ModalTutorial;
