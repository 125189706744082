import { AuthContext } from "context/AuthContext";
import React, { useEffect, useState, useContext } from "react";
import { TiTimes } from "react-icons/ti";
import Modal from "react-modal";
import ReactModal from "react-modal";
import { getNotificationsCustom } from "services/notification-custom.service";

import "./styles.scss";

import "react-accessible-accordion/dist/fancy-example.css";
import { updateUserProfile } from "services/profile.service";

const ModalNotificationCustom = () => {
	const { user, refreshUser } = useContext(AuthContext);

	const modalID = "notification-cutom";

	const [loading, setLoading] = useState(true);
	const [modalIsOpen, setIsOpen] = useState(false);

	const [message, setMessage] = useState<any>();

	ReactModal.setAppElement("#modal");

	async function handleCloseModal() {
		setIsOpen(false);
		if (user?.extra) {
			await updateUserProfile({
				_id: user?._id,
				extra: {
					...user?.extra,
					...{
						lastNotification: message.created.$date,
					},
				},
			});

			refreshUser();
		}

		loadData({
			...user,
			extra: { ...user?.extra, lastNotification: message.created.$date },
		});
	}

	useEffect(() => {
		if (!user || !loading) return;

		setLoading(false);

		loadData(user);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	async function loadData(player: any) {
		const data = await getNotificationsCustom(
			player?._id,
			player?.teams[0],
			player?.extra.lastNotification,
		);

		if (data && data?.length > 0) {
			setMessage(data[0]);
			setIsOpen(true);
		}
	}

	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => handleCloseModal()}
				contentLabel="Example Modal"
				shouldCloseOnOverlayClick={false}
				shouldCloseOnEsc={false}
				portalClassName="relative z-[600]"
				overlayClassName="fixed z-100 top-0 left-0 bg-black bg-opacity-60 flex flex-col items-center w-screen h-screen p-6 overflow-y-auto custom-notification-modal"
				contentElement={() => (
					<div className="modalDetailBox bg-white shadow rounded-[10px] py-5 px-5 dialog transition-all flex flex-col justify-between w-full md:w-[450px] ">
						<div className="flex justify-end items-end mb-3">
							<button onClick={handleCloseModal} className="rounded-full ">
								<TiTimes color="#69696E" size={25} />
							</button>
						</div>
						<div
							className="modalBody  max-h-[480px] h-fit overflow-y-auto  relative mt-5 mb-2 text-center item"
							id="mix"
						>
							<h2 className="">{message.title}</h2>
							<div className="">
								<div
									dangerouslySetInnerHTML={{ __html: message.content }}
								></div>
							</div>
						</div>
					</div>
				)}
			/>
		</>
	);
};

export default ModalNotificationCustom;
