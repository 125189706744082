import { NavigationClient } from '@azure/msal-browser';

class CustomNavigationClient extends NavigationClient {
  private navigate: any;

  constructor(navigate: any) {
    super();
    this.navigate = navigate; // Passed in from useNavigate hook provided by react-router-dom;
  }

  // This function will be called anytime msal needs to navigate from one page in your application to another
  async navigateInternal(url: string, options: any): Promise<boolean> {
    // url will be absolute, you will need to parse out the relative path to provide to the history API
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath);
    }

    return false;
  }
}

export default CustomNavigationClient;
