import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";

import Skeleton from "components/Skeleton";
import { UserAvatar } from "components/user-avatar";
import { AiFillStar } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import BB_ROUTES from "routes/const";
import { ranking } from "services/ideia.service";
import { getNameSetor } from "utils/constants";
import "./style.scss";

const RankingComunidadePage = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const { setBreadCrumbItens, setPageTitle } = useContext(AuthContext);
	const params = useParams();

	const { user, contrast } = useContext(AuthContext);
	const [filtro, setFiltro] = useState("");

	const handleFiltroChange = (event: any) => {
		setFiltro(event.target.value);
	};

	useEffect(() => {
		const list = data.filter((item: any) =>
			item.title.toLowerCase().includes(filtro.toLowerCase()),
		);
		setFilteredData(list);
	}, [filtro]);

	useEffect(() => {
		setBreadCrumbItens([
			{
				isFirst: true,
				name: "Jornada / ",
				active: false,
				path: BB_ROUTES.HOME(),
			},
			{
				isFirst: false,
				name: " Ranking da comunidade",
				active: true,
				path: "/ranking-comunidade",
			},
		]);
		setPageTitle("Ranking da comunidade");
	}, [setBreadCrumbItens]);

	useEffect(() => {
		if (!data.length) {
			getData();
		}
	}, [user?.teams]);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => {
		scrollToTop();
	}, [params]);

	function getData() {
		setLoading(true);
		ranking()
			.then((resp) => {
				resp.map((item: any, index: number) => {
					item.position = index + 1;
				});
				setData(resp);
				setFilteredData(resp);
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 300);
			});
	}

	const handler = (item: any) => {
		if (item.player === user?._id) {
			navigate(BB_ROUTES.RANKINGCOMUNIDADE());
		}
		// e.player === user?._id;
	};

	const isMyPosition = (item: any) => {
		if (
			item.player === user?._id ||
			item.participants.some((item: any) => item.value === user?._id)
		) {
			return true;
		}
	};

	function Items({ currentItems }: any) {
		return (
			<>
				{currentItems && currentItems.length ? (
					<div>
						{currentItems.map((e: any, index: any) => (
							<article className="mb-3 block" key={e._id}>
								<Link
									to={`/ideias/${e._id}`}
									className="flex items-start item-position mb-7"
									aria-label={`Em ${e.position}º LUGAR o ${
										e.title || "--"
									}, de ${e.player[0]?.name}, com ${e.likesCount} curtida${
										e.likesCount > 1 ? "s" : ""
									}`}
								>
									<div className="thumb mr-3 w-[80px] h-[80px]">
										{e.image ? (
											<img
												src={e.image}
												alt="Imagem Ideia"
												width={80}
												aria-hidden="true"
											/>
										) : (
											<img
												src={require("../../assets/img/user.png")}
												width={80}
												alt="Imagem Ideia"
												aria-hidden="true"
											/>
										)}
									</div>
									<div
										className={`${
											e.player === user?._id ? "my-position" : ""
										} w-full`}
									>
										<div
											className={`${
												isMyPosition(e) ? "my-position" : ""
											} w-full`}
										>
											{isMyPosition(e) && (
												<div className="bg-yellow rounded-bl-lg rounded-br-lg py-2 px-2 text-center w-28 mb-3">
													<span className="text-gray-300 text-xs">
														Sua posição!
													</span>
												</div>
											)}
											<p className="text-yellow uppercase text-xs font-bold">
												{e.position}º LUGAR
											</p>
											<p
												className={`text-sm ${
													contrast ? "text-yellow" : "text-white"
												} font-thin underline m-0`}
											>
												{e.title || "--"}
											</p>
											<h5
												className={`text-xs ${
													contrast ? "text-yellow" : "text-white"
												}  font-light mt-2 mb-1`}
											>
												{e.player[0]?.name}
											</h5>
											<div className="flex items-center">
												<AiFillHeart
													color={contrast ? "#fcfc30" : "#F97A70"}
													size={18}
												/>
												<span
													className={`${
														contrast ? "text-yellow" : `text-white`
													} text-[10px] font-normal`}
												>
													{e.likesCount} Curtidas
												</span>
											</div>
										</div>
									</div>
								</Link>
							</article>
						))}
					</div>
				) : (
					<p className="text-4xl text-center"></p>
				)}
			</>
		);
	}

	function PaginatedItems({ itemsPerPage }: any) {
		// Here we use item offsets; we could also use page offsets
		// following the API or data you're working with.
		const [itemOffset, setItemOffset] = useState(0);

		// Simulate fetching items from another resources.
		// (This could be items from props; or items loaded in a local state
		// from an API endpoint with useEffect and useState)
		const endOffset = itemOffset + itemsPerPage;
		console.log(`Loading items from ${itemOffset} to ${endOffset}`);
		const currentItems = filteredData.slice(itemOffset, endOffset);
		const pageCount = Math.ceil(filteredData.length / itemsPerPage);

		// Invoke when user click to request another page.
		const handlePageClick = (event: any) => {
			const newOffset = (event.selected * itemsPerPage) % filteredData.length;
			console.log(
				`User requested page number ${event.selected}, which is offset ${newOffset}`,
			);
			setItemOffset(newOffset);
		};

		const ariaLabelBuilder = (pageNumber: number, isCurrent: number) => {
			if (isCurrent) {
				return `Página ${pageNumber}, atual`;
			} else {
				return `ir para a página ${pageNumber}`;
			}
		};

		return (
			<>
				<Items currentItems={currentItems} />
				<ReactPaginate
					breakClassName={`${
						contrast ? "text-yellow" : "text-gray-300"
					} text-base`}
					previousClassName={`${
						contrast ? "text-yellow" : "text-gray-300"
					} text-base`}
					nextClassName={`${
						contrast ? "text-yellow" : "text-gray-300"
					} text-base`}
					breakLabel="..."
					nextLabel="Próxima >"
					onPageChange={handlePageClick}
					ariaLabelBuilder={ariaLabelBuilder}
					pageRangeDisplayed={5}
					pageCount={pageCount}
					previousLabel="< Anterior"
					renderOnZeroPageCount={null}
					pageLinkClassName={`${
						contrast ? "text-yellow" : "text-gray-300"
					} text-base underline px-3`}
					activeLinkClassName={`${
						contrast ? "text-white" : "text-purple"
					} no-underline`}
					className={`pagination mt-6 ${
						currentItems.length <= 1 ? "hidden" : ""
					} ${contrast ? "text-yellow" : "text-gray-300"}`}
				/>
			</>
		);
	}

	return (
		<div className="main-ideia " id="pageRankingConhecimento">
			<section
				className={`my-7 px-[14px] py-7 lg:px-[70px] lg:mb-[18px] relative transition rounded-[10px] border ${
					contrast ? "border-yellow" : "border-white"
				}`}
			>
				<p className={`text-base ${contrast ? "text-yellow" : "text-white"}`}>
					<strong>Ranking da comunidade</strong>
				</p>
				<section className="actions relative mb-[30px] flex justify-center mt-5">
					<input
						type="text"
						placeholder="Buscar..."
						className={`w-full rounded-[5px] bg-transparent border p-2 ${
							contrast
								? "bg-black border-yellow text-yellow"
								: "border-white  text-white"
						} `}
						onChange={handleFiltroChange}
					/>
				</section>
				<article className="my-10">
					{filteredData && filteredData.length > 0 && (
						<PaginatedItems itemsPerPage={10} />
					)}
					{loading && <Skeleton size={10} type="LIST" />}
				</article>
			</section>
		</div>
	);
};

export default RankingComunidadePage;
