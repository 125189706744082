import { FC, useContext, useEffect, useState } from 'react';

import './style.scss';
import { UserAvatar } from 'components/user-avatar';
import { AuthContext } from 'context/AuthContext';
import tippy from 'tippy.js';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/dist/tippy.css';

interface Options {
  ariaLabel?: string;
  startDate?: number;
  barColor?: string;
  currentProgress: number;
  expectedProgress: number;
  expectedDays: number;
  showAvatar?: boolean;
  className?: string;
  endDate?: number;
}

const UserProgress: FC<Options> = ({
  ariaLabel,
  startDate,
  barColor,
  currentProgress,
  expectedProgress,
  expectedDays,
  showAvatar,
  className,
  endDate,
}: Options) => {
  const { user, team, contrast } = useContext(AuthContext);
  const [currentBarSize, setCurrentBarSize] = useState(0);
  const [strPercent, setStrPercent] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCurrentBarSize(currentProgress);
      const userProgress = Number(precisionNumber(currentProgress));

      setStrPercent(userProgress || 0);
    }, 500);
  }, [currentProgress]);

  const precisionNumber = (val: any) => {
    return Math.round(val).toFixed();
  };

  tippy('#user-progress', {
    content: 'Progresso no game',
    arrow: false,
    theme: team,
  });

  return (
    <>
      <div
        className="user-progress-bar"
        tabIndex={-1}
        aria-hidden={true}
        aria-label={`Progresso de ${strPercent}% em ${new Intl.DateTimeFormat(
          'pt-BR'
        ).format(new Date())}`}
      >
        <div className={`user-progress-bar ${className}`}>
          {startDate ? (
            <span
              className={`${
                contrast ? 'text-yellow' : 'text-white'
              } uppercase text-[10px] md:text-xs`}
              aria-label={`Inicia em ${new Intl.DateTimeFormat('pt-BR').format(
                startDate
              )}`}
            >
              {new Intl.DateTimeFormat('pt-BR').format(startDate)}
            </span>
          ) : (
            <span
              className={`${
                contrast ? 'text-yellow' : 'text-white'
              } uppercase text-[10px] md:text-xs`}
              aria-hidden={true}
            >
              Início
            </span>
          )}

          <div
            className="wrapper-bar relative"
            tabIndex={-1}
            aria-hidden={true}
          >
            <div
              className="me flex mark absolute top-[-69px]"
              id="user-progress"
              style={{
                left: `${
                  currentProgress >= 50 ? currentProgress - 20 : currentProgress
                }%`,
              }}
            >
              {showAvatar !== false && (
                <>
                  {/* <p>currentProgress >= 95 ? 'w-[60px]' : 'w-[45px]'</p> */}
                  <div className={`thumb  bg-yellow w-[45px]`}>
                    <UserAvatar
                      data={{ ...user, ...user?.extra }}
                      responsive={true}
                    />
                  </div>
                  <div className="ml-2" aria-hidden={true}>
                    <h5
                      className="text-yellow text-xs font-medium"
                      aria-hidden={true}
                      tabIndex={-1}
                    >
                      Progresso de {strPercent}%
                    </h5>
                    <span
                      className={`${
                        contrast ? `text-yellow` : `text-white`
                      } text-xs`}
                      aria-hidden={true}
                      tabIndex={-1}
                    >
                      em {new Intl.DateTimeFormat('pt-BR').format(new Date())}
                    </span>
                  </div>
                </>
              )}
            </div>
            {expectedProgress > currentProgress && (
              <div
                className="expected flex mark absolute bottom-[-60px]"
                aria-hidden={true}
                style={{
                  left: `${expectedProgress}%`,
                }}
              >
                <div className="thumb bg-red">
                  <div className="text-gray-300 text-xs flex items-center justify-center">{`${expectedProgress}%`}</div>
                </div>
                <div className="ml-2">
                  Você deveria estar aqui a {expectedDays} dias
                </div>
              </div>
            )}
            <div
              className={`bar ${
                contrast ? `bg-black border-2 border-yellow` : `bg-white`
              } w-full`}
              aria-hidden={true}
              tabIndex={-1}
            >
              <div
                className="h-full transition"
                style={{
                  background: contrast
                    ? '#fcfc30'
                    : barColor
                    ? barColor
                    : `#FCFC30`,
                  width: `${currentBarSize}%`,
                }}
              ></div>
            </div>
          </div>

          {endDate ? (
            <span
              className={`${
                contrast ? 'text-yellow' : 'text-white'
              } uppercase text-[10px] md:text-xs`}
              aria-label={`Termina em ${new Intl.DateTimeFormat('pt-BR').format(
                endDate
              )}`}
            >
              {new Intl.DateTimeFormat('pt-BR').format(endDate)}
            </span>
          ) : (
            <span
              className={`${
                contrast ? 'text-yellow' : 'text-white'
              } uppercase text-[10px] md:text-xs`}
              aria-hidden={true}
            >
              Fim
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default UserProgress;
