import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';

interface Options {
  color: string;
  percent: number;
}

const ProgressBar = ({ color, percent }: Options) => {
  const { contrast } = useAuth();
  const [currentBarSize, setCurrentBarSize] = useState(0);
  const [strNumber, setStrNumber] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setCurrentBarSize(Math.round(percent));
      setStrNumber(precisionNumber(percent));
    }, 300);
  }, [percent]);

  const precisionNumber = (val: any) => {
    return Math.round(val).toFixed();
  };

  return (
    <>
      <div className="progress-bar">
        <div
          className="bar mb-1 w-full border rounded-full"
          aria-label={
            currentBarSize === 0
              ? 'Você ainda não iniciou esse item'
              : `Você concluiu ${currentBarSize}% desse item`
          }
          tabIndex={0}
          style={{
            borderColor: color,
          }}
        >
          <div
            className="h-1 rounded-full transition"
            style={{
              background: color,
              width: `${currentBarSize}%`,
            }}
          ></div>
        </div>
        <span
          className={`${contrast ? 'text-yellow' : `text-gray-300`} font-bold`}
        >{`${strNumber}%`}</span>
      </div>
    </>
  );
};

export default ProgressBar;
