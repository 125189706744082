import { GestorIdeas } from "types/gestor-ideas";
import { findLast } from "utils/helpers";
import { SortPagination } from "utils/paginations";
import { api } from "./api";

export async function getIdeias(
	payload: any,
	initialRange: number,
	range: number,
) {
	const getInfos = [
		{
			$lookup: {
				from: "player",
				localField: "player",
				foreignField: "_id",
				as: "p",
			},
		},
		{
			$unwind: "$p",
		},
		{
			$lookup: {
				from: "action_log",
				let: {
					idIdea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$or: [
									{
										$eq: ["$attributes.id_idea", "$$idIdea"],
									},
								],
							},
						},
					},
				],
				as: "likes",
			},
		},
	];

	const updatedPayload = payload.concat(getInfos);
	const headers = {
		Range: `items=${initialRange}-${range}`,
	};

	const { data } = await api.post(
		"database/idea__c/aggregate",
		updatedPayload,
		{ headers },
	);
	return data;
}

export async function getIdea(_id: string) {
	const payload = [
		{
			$lookup: {
				from: "action_log",
				let: {
					id_idea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "like_idea"] },
								],
							},
						},
					},
				],
				as: "likes",
			},
		},
		{
			$lookup: {
				from: "action_log",
				let: {
					id_idea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "evaluate_idea"] },
								],
							},
						},
					},
				],
				as: "evaluates",
			},
		},
		{
			$lookup: {
				from: "player",
				localField: "player",
				foreignField: "_id",
				as: "player",
			},
		},
		{
			$project: {
				_id: 1,
				image: 1,
				file: 1,
				theme: 1,
				video: 1,
				isDestaque: 1,
				title: 1,
				content: 1,
				participants: 1,
				likesCount: { $size: "$likes" },
				likes: 1,
				evaluateCount: { $size: "$evaluates" },
				evaluates: 1,
				player: {
					_id: 1,
					name: 1,
					image: { small: { url: 1 } },
					teams: 1,
					extra: 1,
				},
			},
		},
		{ $sort: { likesCount: -1 } },
		{
			$match: {
				_id: _id,
			},
		},
	];
	const { data } = await api.post("database/idea__c/aggregate", payload);

	if (!Array.isArray(data)) {
		return {};
	} else {
		let result = Object.assign({}, data[0]);
		if (result.evaluateCount > 0) {
			const last_evaluate = findLast(
				result.evaluates,
				"actionId",
				"evaluate_idea",
			);
			result = { ...result, ...{ last_evaluate: last_evaluate } };
		}

		return result;
	}
}

export async function saveOrUpdateIdea(payload: any) {
	if (payload.participants) {
		payload = {
			...payload,
			...{
				participants: payload.participants.map((v: any) => {
					return {
						label: v.label,
						value: v.value,
					};
				}),
			},
		};
	}
	if (payload._id) {
		const { data } = await api.put("database/idea__c", payload);
		return data;
	} else {
		const { data } = await api.post("database/idea__c", payload);
		return data;
	}
}

export async function getThemes() {
	const { data } = await api.get("database/idea_theme__c");
	return data.sort((a: any, b: any) => (a.title > b.title ? 1 : -1));
}

export async function checkIsLike(ideaId: any, player: any) {
	const { data } = await api.post("database/action_log/aggregate", [
		{
			$match: {
				actionId: "like_idea",
				"attributes.id_idea": ideaId,
				"attributes.from_player": player,
			},
		},
	]);
	console.log(data);
	return data;
}

export async function likeIdea(payload: any) {
	const { data } = await api.post("action/log", payload);
	return data;
}

export async function paginatedIdeaItems(
	filter: string,
	offset: number,
	pageSize: number,
) {
	let filterMatch = { $match: {} };

	if (filter) {
		filterMatch = {
			$match: {
				$or: [
					{ title: { $regex: filter, $options: "i" } },
					{ content: { $regex: filter, $options: "i" } },
					{ "player.name": { $regex: filter, $options: "i" } },
					{ "participants.label": { $regex: filter, $options: "i" } },
				],
			},
		};
	}

	const payload = [
		{
			$lookup: {
				from: "action_log",
				let: {
					id_idea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "like_idea"] },
								],
							},
						},
					},
				],
				as: "likes",
			},
		},
		{
			$lookup: {
				from: "action_log",
				let: {
					id_idea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "evaluate_idea"] },
								],
							},
						},
					},
				],
				as: "evaluates",
			},
		},
		{
			$lookup: {
				from: "player",
				localField: "player",
				foreignField: "_id",
				as: "player",
			},
		},
		{
			$project: {
				_id: 1,
				image: 1,
				file: 1,
				theme: 1,
				video: 1,
				isDestaque: 1,
				title: 1,
				content: 1,
				participants: 1,
				likesCount: { $size: "$likes" },
				likes: 1,
				evaluateCount: { $size: "$evaluates" },
				evaluates: 1,
				player: {
					_id: 1,
					name: 1,
					image: { small: { url: 1 } },
					teams: 1,
					extra: 1,
				},
			},
		},
		{ $sort: { likesCount: -1 } },
		filterMatch,
	];

	try {
		const resp = await api.post(
			"database/idea__c/aggregate?strict=true",
			payload,
			{
				headers: { Range: `items=${offset}-${pageSize}` },
			},
		);

		const data = SortPagination(resp);
		const result: Array<any> = [];
		data.result.forEach((item: any, index: number) => {
			let currentObj = Object.assign({}, item);
			if (currentObj.evaluateCount > 0) {
				const last_evaluate = findLast(
					currentObj.evaluates,
					"actionId",
					"evaluate_idea",
				);
				currentObj = {
					...currentObj,
					...{
						last_evaluate: last_evaluate,
					},
				};
			}
			data.result[index] = currentObj;
		});

		return data;
	} catch (e) {
		throw e;
	}
}

export async function ideaList(filter: string) {
	let filterMatch = { $match: {} };

	if (filter) {
		filterMatch = {
			$match: {
				$or: [
					{ title: { $regex: filter, $options: "i" } },
					{ content: { $regex: filter, $options: "i" } },
					{ "player.name": { $regex: filter, $options: "i" } },
					{ "participants.label": { $regex: filter, $options: "i" } },
				],
			},
		};
	}

	const payload = [
		{
			$lookup: {
				from: "action_log",
				let: {
					id_idea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "like_idea"] },
								],
							},
						},
					},
				],
				as: "likes",
			},
		},
		{
			$lookup: {
				from: "action_log",
				let: {
					id_idea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "evaluate_idea"] },
								],
							},
						},
					},
				],
				as: "evaluates",
			},
		},
		{
			$lookup: {
				from: "player",
				localField: "player",
				foreignField: "_id",
				as: "player",
			},
		},
		{
			$project: {
				_id: 1,
				image: 1,
				file: 1,
				created_at: 1,
				isDestaque: 1,
				updated_at: 1,
				theme: 1,
				video: 1,
				title: 1,
				content: 1,
				participants: 1,
				likesCount: { $size: "$likes" },
				likes: 1,
				evaluateCount: { $size: "$evaluates" },
				evaluates: 1,
				player: {
					_id: 1,
					name: 1,
					image: { small: { url: 1 } },
					teams: 1,
					extra: 1,
				},
			},
		},
		{ $sort: { likesCount: -1 } },
		filterMatch,
	];

	try {
		const resp = await api.post(
			"database/idea__c/aggregate?strict=true",
			payload,
			{
				headers: { Range: `items=0-10000` },
			},
		);
		const data: any = resp.data;
		const result: Array<any> = [];
		// console.log(data);
		data.forEach((item: any, index: number) => {
			let currentObj = Object.assign({}, item);
			if (currentObj.evaluateCount > 0) {
				const last_evaluate = findLast(
					currentObj.evaluates,
					"actionId",
					"evaluate_idea",
				);
				currentObj = {
					...currentObj,
					...{
						last_evaluate: last_evaluate,
					},
				};
			}

			currentObj = {
				...currentObj,
				...{
					position: index + 1,
				},
			};
			data[index] = currentObj;
		});

		return data;
	} catch (e) {
		throw e;
	}
}

export async function ranking(filter?: string, resultPaginate?: boolean) {
	let filterMatch = { $match: {} };

	if (filter) {
		filterMatch = {
			$match: {
				$or: [
					{ title: { $regex: filter, $options: "i" } },
					{ content: { $regex: filter, $options: "i" } },
					{ "player.name": { $regex: filter, $options: "i" } },
					{ "participants.label": { $regex: filter, $options: "i" } },
				],
			},
		};
	}

	const payload = [
		{
			$lookup: {
				from: "action_log",
				let: {
					id_idea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "like_idea"] },
								],
							},
						},
					},
				],
				as: "likes",
			},
		},
		{
			$lookup: {
				from: "action_log",
				let: {
					id_idea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "evaluate_idea"] },
								],
							},
						},
					},
				],
				as: "evaluates",
			},
		},
		{
			$lookup: {
				from: "player",
				localField: "player",
				foreignField: "_id",
				as: "player",
			},
		},
		{
			$project: {
				_id: 1,
				image: 1,
				file: 1,
				theme: 1,
				video: 1,
				isDestaque: 1,
				title: 1,
				content: 1,
				participants: 1,
				likesCount: { $size: "$likes" },
				likes: 1,
				evaluateCount: { $size: "$evaluates" },
				evaluates: 1,
				player: {
					_id: 1,
					name: 1,
					image: { small: { url: 1 } },
					teams: 1,
					extra: 1,
				},
			},
		},
		{ $sort: { likesCount: -1 } },
		filterMatch,
	];

	try {
		const resp = await api.post(
			"database/idea__c/aggregate?strict=true",
			payload,
			{
				headers: { Range: `items=0-1000` },
			},
		);

		if (resultPaginate) {
			const data = SortPagination(resp);
			const result: Array<any> = [];
			data.result.forEach((item: any, index: number) => {
				let currentObj = Object.assign({}, item);
				if (currentObj.evaluateCount > 0) {
					const last_evaluate = findLast(
						currentObj.evaluates,
						"actionId",
						"evaluate_idea",
					);
					currentObj = {
						...currentObj,
						...{
							last_evaluate: last_evaluate,
						},
					};
				}
				data.result[index] = currentObj;
			});

			return data;
		} else {
			return resp.data;
		}
	} catch (e) {
		throw e;
	}
}

export async function RankingConhecimentoCompleto(team?: any) {
	const payload: any = [
		{
			$lookup: {
				from: "player",
				localField: "player",
				foreignField: "_id",
				as: "p",
			},
		},
		{
			$unwind: "$p",
		},
		{
			$match: {
				$or: [
					{
						"p.extra.status": {
							$exists: false,
						},
					},
					{
						"p.extra.status": {
							$exists: true,
						},
						"p.extra.status.active": true,
					},
				],
			},
		},
		{
			$project: {
				_id: 1,
				image: 1,
				total: 1,
				position: 1,
				move: 1,
				player: 1,
				name: 1,
				boardId: 1,
				"p._id": 1,
				"p.avatar_type": 1,
				"p.extra.avatar": 1,
				"p.fase": 1,
				"p.area": 1,
				"p.teams": 1,
			},
		},
		{
			$group: {
				_id: null,
				docs: {
					$push: "$$ROOT",
				},
			},
		},
		{
			$unwind: {
				path: "$docs",
				includeArrayIndex: "position",
			},
		},
		{
			$addFields: {
				position: {
					$add: ["$position", 1],
				},
			},
		},
		{
			$replaceRoot: {
				newRoot: {
					$mergeObjects: [
						"$docs",
						{
							position: "$position",
						},
					],
				},
			},
		},
	];

	const headers = {
		Range: `items=0-10000`,
	};

	let endpoint =
		"/leaderboard/DVtyrzY/leader/aggregate?period=-10y%3B-0d%2B&live=true";
	if (team) {
		const options: Record<string, string> = {
			DRj0IUB: "D3jMHBF", //COMERCIAL
			DRjWqyD: "DVtyrzY", //TECNOLOGIA
		};

		endpoint = `/leaderboard/${options[team]}/leader/aggregate?period=-10y%3B-0d%2B&live=true`;
	}

	const { data } = await api.post(endpoint, payload, { headers });

	return data;
}

export async function getMyIdeaOrContributte(userId: string) {
	const payload = [
		{
			$match: {
				$or: [{ player: userId }, { "participants.value": { $in: [userId] } }],
			},
		},
	];

	const { data } = await api.post("database/idea__c/aggregate", payload);
	return data;
}

export async function gestorIdeas({
	gestorId,
	filter,
	order,
	status,
	tema,
	rangeItem,
	itemAmount = 10,
	matchGestor = false,
}: {
	gestorId: string;
	filter: string;
	tema: string;
	order: string;
	status: string;
	rangeItem: number;
	itemAmount?: number;
	matchGestor?: boolean;
}) {
	let filterMatch = { $match: {} };
	let filterTema = { $match: {} };
	let filterOrder = { $sort: {} };
	let filterStatus = { $match: {} };

	if (filter) {
		filterMatch = {
			$match: {
				$or: [
					{ "player.name": { $regex: filter, $options: "i" } },
					{ "participants.label": { $regex: filter, $options: "i" } },
					{ title: { $regex: filter, $options: "i" } },
					{ content: { $regex: filter, $options: "i" } },
					{ "t.title": { $regex: filter, $options: "i" } },
				],
			},
		};
	}

	if (tema) {
		filterTema = {
			$match: {
				theme: { $in: [tema] },
			},
		};
	}

	if (order) {
		if (order === "tema") {
			filterOrder = { $sort: { theme: -1 } };
		}
		if (order === "recentes") {
			filterOrder = { $sort: { created_at: -1 } };
		}
		if (order === "curtidas") {
			filterOrder = { $sort: { likesCount: -1 } };
		}
	} else {
		filterOrder = { $sort: { likesCount: -1 } };
	}

	if (status) {
		if (status === "avaliada") {
			filterStatus = { $match: { evaluates: { $exists: true, $ne: [] } } };
		} else if (status === "nao_avaliada") {
			filterStatus = {
				$match: { $or: [{ evaluates: { $exists: false } }, { evaluates: [] }] },
			};
		}
	} else {
		filterStatus = { $match: {} };
	}

	const headers = {
		Range: `items=${rangeItem}-${itemAmount}`,
	};

	const query = [
		{
			$lookup: {
				from: "idea_theme__c",
				let: { theme: "$theme" },
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [{ $eq: ["$_id", "$$theme"] }],
							},
						},
					},
					{
						$unwind: "$managers",
					},
					{
						$match: {
							"managers._id": {
								...(matchGestor ? { $eq: gestorId } : { $ne: gestorId }),
							},
						},
					},
				],
				as: "t",
			},
		},
		{
			$match: {
				...(matchGestor
					? { $expr: { $gt: [{ $size: "$t.managers" }, 0] } }
					: {}),
			},
		},
		{
			$lookup: {
				from: "action_log",
				let: {
					id_idea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "like_idea"] },
								],
							},
						},
					},
				],
				as: "likes",
			},
		},
		{
			$lookup: {
				from: "action_log",
				let: { id_idea: "$_id" },
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "evaluate_idea"] },
								],
							},
						},
					},
				],
				as: "evaluates",
			},
		},
		{
			$lookup: {
				from: "player",
				let: { playerId: "$player" },
				pipeline: [
					{ $match: { $expr: { $eq: ["$_id", "$$playerId"] } } },
					{
						$project: {
							_id: 1,
							name: 1,
							image: { small: { url: 1 } },
							teams: 1,
						},
					},
				],
				as: "player",
			},
		},
		{
			$project: {
				_id: 1,
				image: 1,
				file: 1,
				created_at: 1,
				theme: 1,
				video: 1,
				title: 1,
				content: 1,
				participants: 1,
				evaluateCount: { $size: "$evaluates" },
				evaluates: 1,
				likesCount: { $size: "$likes" },
				likes: 1,
				player: 1,
				t: 1,
				isDestaque: 1,
			},
		},
		...(filter ? [filterMatch] : []),
		...(tema ? [filterTema] : []),
		...(order ? [filterOrder] : []),
		...(status ? [filterStatus] : []),
	];

	const data = await api.post<GestorIdeas[]>(
		"database/idea__c/aggregate?strict=true",
		query,
		{
			headers,
		},
	);

	return SortPagination(data);
}

export async function getIdeiasGestor(
	filter?: string,
	tema?: string,
	order?: string,
) {
	let filterMatch = { $match: {} };
	let filterTema = { $match: {} };
	let filterOrder = { $sort: {} };

	if (filter) {
		filterMatch = {
			$match: {
				$or: [
					{ "player.name": { $regex: filter, $options: "i" } },
					{ "participants.label": { $regex: filter, $options: "i" } },
					{ title: { $regex: filter, $options: "i" } },
					{ content: { $regex: filter, $options: "i" } },
				],
			},
		};
	}

	if (tema) {
		filterTema = {
			$match: {
				theme: { $in: [tema] },
			},
		};
	}

	if (order) {
		if (order === "tema") {
			filterOrder = { $sort: { theme: -1 } };
		}
		if (order === "recentes") {
			filterOrder = { $sort: { created_at: -1 } };
		}
		if (order === "curtidas") {
			filterOrder = { $sort: { likesCount: -1 } };
		}
	} else {
		filterOrder = { $sort: { likesCount: -1 } };
	}

	const payload = [
		{
			$lookup: {
				from: "action_log",
				let: {
					id_idea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "like_idea"] },
								],
							},
						},
					},
				],
				as: "likes",
			},
		},
		{
			$lookup: {
				from: "action_log",
				let: {
					id_idea: "$_id",
				},
				pipeline: [
					{
						$match: {
							$expr: {
								$and: [
									{ $eq: ["$attributes.id_idea", "$$id_idea"] },
									{ $eq: ["$actionId", "evaluate_idea"] },
								],
							},
						},
					},
				],
				as: "evaluates",
			},
		},
		{
			$lookup: {
				from: "player",
				localField: "player",
				foreignField: "_id",
				as: "player",
			},
		},
		{
			$lookup: {
				from: "idea_theme__c",
				localField: "theme",
				foreignField: "_id",
				as: "t",
			},
		},
		{ $unwind: "$t" },
		{
			$project: {
				_id: 1,
				image: 1,
				file: 1,
				created_at: 1,
				isDestaque: 1,
				theme: 1,
				video: 1,
				title: 1,
				content: 1,
				participants: 1,
				likesCount: { $size: "$likes" },
				likes: 1,
				evaluateCount: { $size: "$evaluates" },
				evaluates: 1,
				player: {
					_id: 1,
					name: 1,
					image: {
						small: {
							url: 1,
						},
					},
					teams: 1,
					extra: 1,
				},
				managers: "$t.managers",
			},
		},
		filterMatch,
		filterTema,
		filterOrder,
	];

	const { data } = await api.post(
		"database/idea__c/aggregate?strict=true",
		payload,
	);
	return data;
}

export async function getEquipeGestor(
	userId: string,
	filter?: string,
	matricula?: string,
	equipe?: string,
) {
	let filterMatch = { $match: {} };
	let filterMatricula = { $match: {} };
	let filterEquipe = { $sort: {} };

	if (filter) {
		filterMatch = {
			$match: {
				$or: [{ name: { $regex: filter, $options: "i" } }],
			},
		};
	}

	if (matricula) {
		filterMatricula = {
			$match: {
				_id: { $regex: matricula, $options: "i" },
			},
		};
	}

	if (equipe) {
		if (equipe === "cadastro") {
			filterEquipe = { $sort: { "extra.data_posse": 1 } };
		}
		if (equipe === "nome") {
			filterEquipe = { $sort: { name: 1 } };
		}
	} else {
		filterEquipe = { $sort: { teams: -1 } };
	}

	const payload = [
		{
			$match: {
				"extra.gestor": userId,
			},
		},
		filterMatch,
		filterMatricula,
		filterEquipe,
	];

	const { data } = await api.post(
		"database/player_status/aggregate?strict=true",
		payload,
	);
	return data;
}

export async function getOriginalIdea(idIdea: string) {
	const { data } = await api.post("database/idea__c/aggregate?strict=true", [
		{ $match: { _id: idIdea } },
	]);
	return data[0];
}
