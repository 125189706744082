import TrailInfo from "classes/trail.interface";
import TrailCard from "components/Card/TrailCard";
import ModalNotificationCustom from "components/ModalNotificaionCustom";
import MysteryBox from "components/MysteryBox";
import Skeleton from "components/Skeleton";
import UserProgress from "components/UserProgress";
import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import BB_ROUTES from "routes/const";
import { getContent } from "services/elarning.service";

const Trail = () => {
  const navigate = useNavigate();
  const {
    setBreadCrumbItens,
    team,
    setSidebar,
    setPageTitle,
    user,
    DateDifferenceCheck,
    myIdeaOrContributte,
    refreshTrail,
    setRefreshTrail,
    allowAddIdea,
  } = useAuth();

  const { setShowModalAddIdeia, showModalAddIdeia, refreshListIdeias } =
    useModal();
  const [list, setList] = useState<Array<TrailInfo>>([]);
  const [bkpList, setBkpList] = useState<Array<TrailInfo>>([]);
  const [loading, setLoading] = useState(true);

  const [totalProgress, setTotalProgress] = useState(0);
  const [totalPercent, setTotalPercent] = useState(0);
  const [expectedPercent, setExpectedPercent] = useState(0);

  const [totalItens, setTotalItens] = useState<{
    total: number;
    done: number;
  }>();

  useEffect(() => {
    if (list) {
      const done = list.reduce((sum, item) => sum + Number(item.done), 0);
      const totalItens = list.reduce(
        (sum, item) => sum + Number(item.total),
        0
      );
      setTotalProgress((done / totalItens) * 100);
    }
  }, [list]);

  useEffect(() => {
    if (!!refreshTrail && refreshTrail) {
      onload();
    }
  }, [refreshTrail, team, user?.extra]);

  useEffect(() => {
    if (!!user?.extra && !!team) {
      onload();
    }
  }, [team, user?.extra]);

  useEffect(() => {
    if (bkpList.length > 0) {
      const criteria = {
        fase: user?.extra.fase,
        especializacao: user?.extra.especializacao,
        profile: team === "agente-ti" ? "tecnologia" : "negocio",
        area: user?.extra.area,
      };

      const filteredData = bkpList.filter((trail: any) => {
        const itemExtra = trail.extra;
        const matchProfile =
          !itemExtra.profile || itemExtra.profile === criteria.profile;
        const matchEspecializacao =
          !itemExtra.especializacao ||
          itemExtra.especializacao === criteria.especializacao;
        const matchArea = !itemExtra.area || itemExtra.area === criteria.area;
        const matchFase = !itemExtra.fase || itemExtra.fase === criteria.fase;

        return matchProfile && matchEspecializacao && matchArea && matchFase;
      });
      if (filteredData) {
        setList(
          filteredData.sort((a, b) => a.extra.position - b.extra.position)
        );
        setLoading(false);
      }
    }
  }, [bkpList, user?.extra]);

  useEffect(() => {
    setLoading(true);
    setSidebar(true);
    setPageTitle("Jornada");
    setBreadCrumbItens([
      {
        isFirst: true,
        name: "Jornada",
        active: true,
        path: "/login",
      },
    ]);
  }, [setPageTitle, setBreadCrumbItens, setSidebar]);

  function onload() {
    setLoading(true);
    getContent(user?._id)
      .then((resp) => {
        if (resp.length > 0) {
          setBkpList(resp);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setRefreshTrail(false);
        }, 300);
      });
  }

  function goItem(_id: string) {
    navigate(BB_ROUTES.MODULE(_id));
  }

  function goIdea() {
    if (allowAddIdea) {
      navigate(BB_ROUTES.IDEIAS());
      setTimeout(() => {
        if (!myIdeaOrContributte) setShowModalAddIdeia(!showModalAddIdeia);
      }, 300);
    } else {
      toast.warning("O período para o envio de ideias esta encerrado!");
    }
  }

  return (
    <>
      <div className="trail w-full lg:mt-28  mt-24">
        <div
          className={expectedPercent ? "mb-11 lg:mb-32" : "mb-11 lg:mb-11"}
          tabIndex={0}
          aria-labelledby="TESTE"
        >
          <UserProgress
            currentProgress={totalProgress}
            expectedProgress={0}
            expectedDays={0}
            ariaLabel={`Você atingiu ${Math.round(
              totalProgress
            )}% nas jornadas`}
          />
          <span className="opacity-0" id="TESTE">{`Você atingiu ${Math.round(
            totalProgress
          )}% nas jornadas`}</span>
        </div>

        {!!list && !loading && (
          <>
            <TrailCard
              item={{
                _id: "",
                title: "Jornada TÔ ON",
                type: "",
                extra: {
                  color: "FFEC8D",
                  content:
                    "Vamos iniciar essa Jornada com o Desafio da Central de Ideias",
                },
              }}
              key={0}
              percent={myIdeaOrContributte == true ? 100 : 0}
              onclick={goIdea}
            />
            {list?.map((item: any, index: number) => (
              <TrailCard
                key={index}
                item={item}
                percent={item.percent}
                onclick={() => goItem(item._id)}
              />
            ))}
          </>
        )}
        {!loading && list.length == 0 && (
          <h3 className="text-center font-bold text-3xl text-white">
            Não há trilhas disponíveis!
          </h3>
        )}
        {loading && <Skeleton size={10} type="TRAIL" />}
      </div>
      <ModalNotificationCustom />
      <MysteryBox />
    </>
  );
};

export default Trail;
