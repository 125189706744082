import { useAuth } from 'context/AuthContext';
import { HiLightBulb, HiOutlineLightBulb } from 'react-icons/hi';

const AvaliationsIdea = ({ qtd }: any) => {
  const { contrast } = useAuth();

  return (
    <>
      {qtd ? (
        <>
          {Array.from({ length: qtd }).map((_, index) => (
            <span key={index}>
              <HiLightBulb fontSize={25} color="#FCA120" />
            </span>
          ))}
          {Array.from({ length: 5 - qtd }).map((_, index) => (
            <span key={index}>
              <HiOutlineLightBulb
                fontSize={25}
                color={contrast ? '#fcfc30' : '#69696E'}
              />
            </span>
          ))}
        </>
      ) : (
        Array.from({ length: 5 }).map((_, index) => (
          <span key={index}>
            <HiOutlineLightBulb
              fontSize={25}
              color={contrast ? '#fcfc30' : '#69696E'}
            />
          </span>
        ))
      )}
    </>
  );
};

export default AvaliationsIdea;
