import Skeleton from "components/Skeleton";
import { AuthContext } from "context/AuthContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
	gestorIdeas,
	getOriginalIdea,
	getThemes,
	likeIdea,
	saveOrUpdateIdea,
} from "services/ideia.service";
import { getNameSetor } from "utils/constants";
import "../style.scss";

import AvaliationsIdea from "components/AvaliationsIdea";
import { useModal } from "context/ModalContext";
import useDebounce from "hooks/useDebounce";
import Lottie from "lottie-react";
import { HiLightBulb, HiOutlineLightBulb } from "react-icons/hi";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { Evaluate, GestorIdeas } from "types/gestor-ideas";
import { ReactComponent as Like } from "../../../assets/img/like.svg";
import ModalDestaque from "./components/ModalDestaque";

import IdeiaDestaque from "assets/lottie/destaque-ideia.json";
import moment from "moment";
import { FaCheck, FaPencilAlt, FaTimes } from "react-icons/fa";

const AdminCentralIdeia = () => {
	const { user } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [loadingAvaliation, setLoadingAvaliation] = useState(false);
	const [showAvaliation, setshowAvaliation] = useState<any>(null);
	const [showAvaliationOtherIdeas, setShowAvaliationOtherIdeas] =
		useState<any>(null);
	const [dataIdeias, setDataIdeias] = useState<GestorIdeas[]>([]);
	const [dataGestorIdeias, setDataGestorIdeias] = useState<GestorIdeas[]>([]);
	const [pageContGestorIdeas, setPageContGestorIdeas] = useState(0);
	const [pageContIdeas, setPageContIdeas] = useState(0);
	const [gestorIdeasLoading, setGestorIdeasLoading] = useState(false);
	const [otherIdeasLoading, setOtherIdeasLoading] = useState(false);
	const [listThemes, setThemes] = useState<any>([]);
	const [formAvaliation, setformAvaliation] = useState<any>({
		star: null,
		comment: "",
	});
	const [formFilter, setformFilter] = useState({
		tema: "",
		participante: "",
		order: "",
		status: "",
	});
	const [search, setSearch] = useState("");
	const { showModal } = useModal();

	const searchTerm = useDebounce(search, 600);

	const handleInputChange = (event: any) => {
		const { name, value } = event.target;
		setformFilter({ ...formFilter, [name]: value });
	};

	const handleSelectChange = (event: any) => {
		const { name, value } = event.target;
		setformFilter({ ...formFilter, [name]: value });
	};

	const refGestorIdeas = useRef<any>(null);
	const refOtherIdeas = useRef<any>(null);

	const [previousTheme, setPreviousTheme] = useState("");
	const [newThemeIdea, setNewThemeIdea] = useState<any>({});

	const loadGestorIdeas = useCallback(
		async (itemPage?: number, goToRef?: boolean) => {
			if (!user?._id) return;

			try {
				setGestorIdeasLoading(true);
				const { tema, order, status } = formFilter;
				const rangeItem = itemPage ? itemPage * 10 : 0;

				const [ideasMatchGestor] = await Promise.all([
					await gestorIdeas({
						filter: searchTerm,
						tema,
						order,
						status,
						gestorId: user._id,
						rangeItem,
						matchGestor: true,
					}),
					getLisThemes(),
				]);

				setDataGestorIdeias(ideasMatchGestor.result);
				setPageContGestorIdeas(ideasMatchGestor.range.pages);
			} catch (error) {
				console.log(error);
			} finally {
				setGestorIdeasLoading(false);
				setLoading(false);
				if (goToRef)
					refGestorIdeas.current.scrollIntoView({ behavior: "smooth" });
			}
		},
		[formFilter, searchTerm, user?._id],
	);

	const loadOtherIdeas = useCallback(
		async (itemPage?: number, goToRef?: boolean) => {
			if (!user?._id) return;

			try {
				setOtherIdeasLoading(true);
				const { tema, order, status } = formFilter;
				const rangeItem = itemPage ? itemPage * 10 : 0;

				const [ideasNotMatchGestor] = await Promise.all([
					await gestorIdeas({
						filter: searchTerm,
						tema,
						order,
						status,
						gestorId: user._id,
						rangeItem,
						matchGestor: false,
					}),
					getLisThemes(),
				]);

				setDataIdeias(ideasNotMatchGestor.result);
				setPageContIdeas(ideasNotMatchGestor.range.pages);
			} catch (error) {
				console.log(error);
			} finally {
				setOtherIdeasLoading(false);

				if (goToRef) {
					refOtherIdeas.current.scrollIntoView({
						behavior: "smooth",
						block: "start",
					});
				}
			}
		},
		[formFilter, searchTerm, user?._id],
	);

	useEffect(() => {
		setLoading(true);
		Promise.all([loadGestorIdeas(), loadOtherIdeas()])
			.then()
			.catch((error) => console.log(error))
			.finally(() => setLoading(false));
	}, [formFilter, loadGestorIdeas, loadOtherIdeas]);

	function getLisThemes() {
		getThemes()
			.then((resp) => {
				if (resp.length > 0) {
					setThemes(resp);
				}
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 300);
			});
	}

	function nameThemes(id: string) {
		const theme = listThemes.find((e: any) => e._id === id);
		return theme ? theme.title : "--";
	}

	function downloadFile(item: any) {
		fetch(item.file)
			.then((response) => response.blob())
			.then((blob) => {
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = `ideia_${item._id}`;
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(link.href);
				document.body.removeChild(link);
			})
			.catch((error) => {
				toast.error("Erro ao baixar o arquivo:");
			});
	}

	function avaliate(item: any, index: number) {
		setLoadingAvaliation(true);
		const payload = {
			actionId: "evaluate_idea",
			userId: "me",
			attributes: {
				id_idea: item._id,
				grade: formAvaliation.star,
				comment: formAvaliation.comment,
			},
		};
		likeIdea(payload)
			.then((resp) => {
				if (!resp.errorCode) {
					toast.success("Ideia avaliada!");
					item.likes.push(resp);
					const newValues: any = dataIdeias;
					newValues[index] = item;
					setDataIdeias(newValues);
					setRefresh(!refresh);
					setshowAvaliation(null);
				} else {
					toast.error(
						"Não foi possível curtir essa ideia, tente novamente mais tarde!",
					);
				}
			})
			.finally(() => {
				setLoadingAvaliation(false);
				loadGestorIdeas();
			});
	}

	function handleModalDestaque(ideia: any) {
		showModal("modal-destaque-cutom", { config: ideia });
	}

	function editTheme(tema: string) {
		setPreviousTheme(tema);
	}

	const handleNewThemeIdea = (event: any, ideia: any) => {
		const duplicatedIdea = { ...ideia };
		duplicatedIdea.previousTheme = previousTheme;
		duplicatedIdea.theme = event.target.value;
		setNewThemeIdea(duplicatedIdea);
	};

	function loadOriginalIdea(ideia: any) {
		getOriginalIdea(ideia._id).then((resp) => {
			if (resp.player) {
				resp.previousTheme = newThemeIdea.previousTheme;
				resp.theme = newThemeIdea.theme;
				resp.isChangeTheme = true;
				saveNewTheme(resp);
			} else {
				toast.error("Erro ao buscar ideia.");
			}
		});
	}

	function saveNewTheme(ideia: any) {
		saveOrUpdateIdea(ideia)
			.then((resp) => {
				if (resp) {
					toast.success("Ideia salva");
				} else {
					toast.error("Erro ao salvar a ideia.");
				}
			})
			.finally(() => {
				setPreviousTheme("");
				loadGestorIdeas();
			});
	}

	return (
		<div className="main-adm-ideias">
			<div>
				<form className="form">
					<div className="form-group">
						<label>
							<input
								type="text"
								placeholder="Buscar..."
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								name="search"
							/>
						</label>
					</div>
					<div className="form-group">
						<label>
							<select
								value={formFilter.status}
								onChange={handleSelectChange}
								name="status"
							>
								<option value="" className="text-black">
									Selecione o status
								</option>
								<option value="avaliada" className="text-black">
									Avaliada
								</option>
								<option value="nao_avaliada" className="text-black">
									Não avaliada
								</option>
							</select>
						</label>
						<label>
							<select
								value={formFilter.tema}
								onChange={handleSelectChange}
								name="tema"
							>
								<option value="" className="text-black">
									Selecione o Tema
								</option>
								{listThemes.map((e: any) => (
									<option value={e._id} className="text-black">
										{e.title}
									</option>
								))}
							</select>
						</label>
						<label>
							<select
								value={formFilter.order}
								onChange={handleSelectChange}
								name="order"
							>
								<option value="" className="text-black">
									Ordenar
								</option>
								<option value="tema" className="text-black">
									Por tema
								</option>
								<option value="recentes" className="text-black" selected>
									Mais recentes
								</option>
								<option value="curtidas" className="text-black">
									Mais curtidas
								</option>
							</select>
						</label>
					</div>
				</form>
			</div>
			<section
				ref={refGestorIdeas}
				className="bg-[#FFEC8D] shadow-md my-7	 py-10 px-7 mb-[18px] rounded-[10px] relative transition"
			>
				<h2 className="text-[#69696e] text-[1.7em]">
					{formFilter.status && formFilter.status === "avaliada"
						? "Ideias avaliadas"
						: "Ideias para avaliação"}
				</h2>
				<article className="my-10">
					{!(loading || gestorIdeasLoading) &&
						dataGestorIdeias.length === 0 && (
							<h3 className="text-[#69696e] text-[1.3em] text-center">
								{formFilter.status && formFilter.status === "avaliada"
									? "Sem ideias avaliadas"
									: "Sem ideias para avaliação"}
							</h3>
						)}
					{!(loading || gestorIdeasLoading) &&
						dataGestorIdeias.map((e: GestorIdeas, index) => (
							<>
								<article
									className=" trail-card main-ideia flex mb-[18px] rounded-[10px] relative transition"
									key={index}
								>
									<figure>
										<img
											src={
												e.image && e.image !== "" && typeof e.image === "string"
													? e.image
													: e.player[0]?.image?.small
														? e.player[0]?.image?.small?.url
														: require("../../../assets/img/user.png")
											}
											alt="Imagem Ideia"
											aria-hidden={true}
										/>
									</figure>
									<div className="ml-7 mb-7 text-gray-300">
										<div className="text">
											<p className="title">{nameThemes(e.theme)}</p>
											<p
												className="description"
												onClick={() => (
													setformAvaliation({
														star:
															e.evaluates.length > 0
																? e.evaluates[e.evaluates.length - 1].attributes
																		.grade
																: null,
														comment:
															e.evaluates.length > 0
																? e.evaluates[e.evaluates.length - 1].attributes
																		.comment
																: "",
													}),
													setshowAvaliation(e._id)
												)}
											>
												<span className="flex items-center">
													{e.title}
													{e.isDestaque && (
														<Lottie
															animationData={IdeiaDestaque}
															loop={true}
															className="w-[50px]"
															title="Ideia em destaque"
														/>
													)}
												</span>
											</p>
											<p className="user">
												{e.player[0]?.name} ({getNameSetor(e.player[0]?.teams)})
											</p>
											{e.evaluates.length > 0 && (
												<p className="comment">
													<strong>Comentário: </strong>
													{
														e.evaluates[e.evaluates.length - 1].attributes
															.comment
													}
												</p>
											)}
											<i className="mt-2 block">
												Criada em:{" "}
												{moment(e.created_at).format("DD/MM/YYYY HH:mm")}
											</i>
										</div>
										<div className="avaliation">
											<div className="like">
												<button>
													<figure>
														<Like />
													</figure>
													<span className="mx-2">
														{e.likesCount} curtida
														{e.likesCount > 1 && "s"}
													</span>
												</button>
											</div>
											<div className="avalie flex items-center">
												<div className="avaliations mx-2 flex gap-[5px]">
													<AvaliationsIdea
														qtd={
															// @ts-expect-error typescript dindt find
															e?.evaluates?.findLast(
																(e: Evaluate) => e.actionId === "evaluate_idea",
															)?.attributes?.grade || 0
														}
													/>
												</div>
												{!e.likes.filter(
													(e: any) => e.actionId === "evaluate_idea",
												)?.length && (
													<button
														onClick={() => (
															setformAvaliation({
																star:
																	e.evaluates.length > 0
																		? e.evaluates[e.evaluates.length - 1]
																				.attributes.grade
																		: null,
																comment:
																	e.evaluates.length > 0
																		? e.evaluates[e.evaluates.length - 1]
																				.attributes.comment
																		: "",
															}),
															setshowAvaliation(e._id)
														)}
													>
														{e.evaluates.length > 0
															? "Editar avaliação"
															: "Avaliar"}
													</button>
												)}
											</div>
										</div>
									</div>
								</article>

								<div
									className={`form-avaliation ${
										showAvaliation === e._id ? "open" : ""
									}`}
								>
									<button
										className="close"
										onClick={() => (
											setshowAvaliation(null),
											setformAvaliation({ star: null, comment: "" }),
											editTheme("")
										)}
									>
										X
									</button>
									<div className="content">
										<div className="figure">
											<figure>
												<img
													src={require("../../../assets/img/user.png")}
													alt=""
												/>
											</figure>
										</div>
										<div className="avaliation-data">
											{!previousTheme ? (
												<p className="title">
													{nameThemes(e.theme)}{" "}
													<button
														className="ml-3"
														title="Editar tema"
														onClick={() => {
															editTheme(e.theme);
														}}
													>
														<FaPencilAlt />
													</button>
												</p>
											) : (
												<p className="title flex items-center">
													<select
														name=""
														id=""
														className="rounded p-2 border border-[#735CC6]"
														onChange={(event) => {
															handleNewThemeIdea(event, e);
														}}
													>
														{listThemes.map((e: any) => (
															<option
																value={e._id}
																key={e._id}
																selected={e._id === previousTheme}
															>
																{e.title}
															</option>
														))}
													</select>{" "}
													<button
														className="ml-3 flex border border-[red] p-2 text-[red]"
														title="Cancelar"
														onClick={() => {
															editTheme("");
														}}
													>
														Cancelar <FaTimes className="ml-2" />
													</button>
													<button
														className="ml-3 flex  border border-[green] p-2 text-[green]"
														title="Salvar tema"
														onClick={() => {
															loadOriginalIdea(e);
														}}
													>
														Salvar <FaCheck className="ml-2" />
													</button>
												</p>
											)}

											<p>{e.title}</p>
											<span>{e.content}</span>
											<p className="user">
												{e.player[0]?.name} ({getNameSetor(e.player[0]?.teams)})
											</p>
											<div className="boxparticipantes">
												<p>Participantes:</p>
												<div className="itens">
													<div className="item">
														<figure>
															<img
																src={require("../../../assets/img/user.png")}
																alt=""
															/>
														</figure>
														<p>{e.player[0]?.name}</p>
													</div>

													{e.participants.map((participant: any) => (
														<div className="item">
															<figure>
																<img
																	src={require("../../../assets/img/user.png")}
																	alt=""
																/>
															</figure>
															<p>{participant.label}</p>
														</div>
													))}
												</div>
											</div>
											{e.video !== "" && (
												<div className="link">
													<p>Link do vídeo</p>
													<a href={e.video} target="_blank" rel="noreferrer">
														{e.video}
													</a>
												</div>
											)}
											{e.file && (
												<div className="file">
													<p>Arquivos:</p>
													<button onClick={() => downloadFile(e)}>
														Download
													</button>
												</div>
											)}
											<hr />
											<p>
												Este comentário é opcional e será apresentado para toda
												comunidade do TO ON.
											</p>
											<textarea
												value={formAvaliation.comment}
												onChange={(e) =>
													setformAvaliation({
														...formAvaliation,
														comment: e.target.value,
													})
												}
												placeholder="Fazer comentário"
											></textarea>
											<div className="actions">
												<div className="avaliation">
													<p>Avaliar</p>
													<div className="setAvaliation">
														{formAvaliation.star >= 1 ? (
															<HiLightBulb
																fontSize={25}
																color="#FCA120"
																onClick={() =>
																	setformAvaliation({
																		...formAvaliation,
																		star: null,
																	})
																}
															/>
														) : (
															<HiOutlineLightBulb
																fontSize={25}
																color="grey"
																onClick={() =>
																	setformAvaliation({
																		...formAvaliation,
																		star: 1,
																	})
																}
															/>
														)}
														{formAvaliation.star >= 2 ? (
															<HiLightBulb
																fontSize={25}
																color="#FCA120"
																onClick={() =>
																	setformAvaliation({
																		...formAvaliation,
																		star: 2,
																	})
																}
															/>
														) : (
															<HiOutlineLightBulb
																fontSize={25}
																color="grey"
																onClick={() =>
																	setformAvaliation({
																		...formAvaliation,
																		star: 2,
																	})
																}
															/>
														)}
														{formAvaliation.star >= 3 ? (
															<HiLightBulb
																fontSize={25}
																color="#FCA120"
																onClick={() =>
																	setformAvaliation({
																		...formAvaliation,
																		star: 3,
																	})
																}
															/>
														) : (
															<HiOutlineLightBulb
																fontSize={25}
																color="grey"
																onClick={() =>
																	setformAvaliation({
																		...formAvaliation,
																		star: 3,
																	})
																}
															/>
														)}
														{formAvaliation.star >= 4 ? (
															<HiLightBulb
																fontSize={25}
																color="#FCA120"
																onClick={() =>
																	setformAvaliation({
																		...formAvaliation,
																		star: 4,
																	})
																}
															/>
														) : (
															<HiOutlineLightBulb
																fontSize={25}
																color="grey"
																onClick={() =>
																	setformAvaliation({
																		...formAvaliation,
																		star: 4,
																	})
																}
															/>
														)}
														{formAvaliation.star >= 5 ? (
															<HiLightBulb
																fontSize={25}
																color="#FCA120"
																onClick={() =>
																	setformAvaliation({
																		...formAvaliation,
																		star: 5,
																	})
																}
															/>
														) : (
															<HiOutlineLightBulb
																fontSize={25}
																color="grey"
																onClick={() =>
																	setformAvaliation({
																		...formAvaliation,
																		star: 5,
																	})
																}
															/>
														)}
													</div>
												</div>
												<button
													className="save"
													onClick={() => avaliate(e, index)}
													disabled={
														formAvaliation.star === null || loadingAvaliation
													}
												>
													{loadingAvaliation ? "registrando..." : "Salvar"}
												</button>
											</div>

											{e.isDestaque ? (
												<button
													onClick={() => {
														handleModalDestaque(e);
													}}
													className="bg-[#f50226] bordered rounded px-[15px] py-[10px]"
												>
													REMOVER DESTAQUE
												</button>
											) : (
												<button
													onClick={() => {
														handleModalDestaque(e);
													}}
													className="bg-[#735cc6] bordered rounded px-[15px] py-[10px]"
												>
													DESTACAR IDEIA
												</button>
											)}
										</div>
									</div>
								</div>
							</>
						))}
					{(loading || gestorIdeasLoading) && (
						<Skeleton size={3} type="LISTFULL" />
					)}
				</article>

				<ReactPaginate
					breakLabel="..."
					nextLabel="Próxima >"
					onPageChange={(data) => loadGestorIdeas(data.selected, true)}
					pageRangeDisplayed={5}
					pageCount={pageContGestorIdeas}
					previousLabel="< Anterior"
					renderOnZeroPageCount={null}
					className={`pagination mt-6 ${
						pageContGestorIdeas <= 1 || loading || gestorIdeasLoading
							? "hidden"
							: ""
					}`}
				/>
			</section>

			<ModalDestaque />

			<section
				ref={refOtherIdeas}
				className="bg-[#FFEC8D] shadow-md my-7	py-10 px-7 mb-[18px] rounded-[10px] relative transition mt-[50px]"
			>
				<h2 className="text-[#69696e] text-[1.7em]">Outras ideias</h2>
				<article className="my-10">
					{!(loading || otherIdeasLoading) && dataIdeias?.length === 0 && (
						<h3 className="text-[#69696e] text-[1.3em] text-center">
							Sem resultados
						</h3>
					)}
					{!(loading || otherIdeasLoading) &&
						dataIdeias.map((e: GestorIdeas, index) => (
							<>
								<article
									className=" trail-card main-ideia flex mb-[18px] rounded-[10px] relative transition"
									key={index}
								>
									<figure>
										<img
											src={
												e.image && e.image !== "" && typeof e.image === "string"
													? e.image
													: e.player[0]?.image?.small
														? e.player[0]?.image?.small?.url
														: require("../../../assets/img/user.png")
											}
											alt="Imagem Ideia"
											aria-hidden={true}
										/>
									</figure>
									<div className="ml-7 mb-7 text-gray-300">
										<div className="text">
											<p className="title">{nameThemes(e.theme)}</p>
											<p
												className="description"
												onClick={() => setShowAvaliationOtherIdeas(e._id)}
											>
												<span className="flex items-center">
													{e.title}
													{e.isDestaque && (
														<Lottie
															animationData={IdeiaDestaque}
															loop={true}
															className="w-[50px]"
															title="Ideia em destaque"
														/>
													)}
												</span>
											</p>
											<p className="user">
												{e.player[0]?.name} ({getNameSetor(e.player[0]?.teams)})
											</p>
										</div>
										<div className="avaliation">
											<div className="like">
												<button className="cursor-text">
													<figure>
														<Like />
													</figure>
													<span className="mx-2">
														{e.likesCount} curtida
														{e.likesCount > 1 && "s"}
													</span>
												</button>
											</div>
											<div className="avalie flex items-center">
												<div className="avaliations mx-2 flex gap-[5px]">
													<AvaliationsIdea
														qtd={
															// @ts-expect-error typescript dindt find
															e?.evaluates?.findLast(
																(e: Evaluate) => e.actionId === "evaluate_idea",
															)?.attributes?.grade || 0
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</article>

								<div
									className={`form-avaliation ${
										showAvaliationOtherIdeas === e._id ? "open" : ""
									}`}
								>
									<button
										className="close"
										onClick={() => setShowAvaliationOtherIdeas(null)}
									>
										X
									</button>
									<div className="content">
										<div className="figure">
											<figure>
												<img
													src={require("../../../assets/img/user.png")}
													alt=""
												/>
											</figure>
										</div>
										<div className="avaliation-data">
											<p className="title">{nameThemes(e.theme)}</p>
											<p>{e.title}</p>
											<span>{e.content}</span>
											<p className="user">
												{e.player[0]?.name} ({getNameSetor(e.player[0]?.teams)})
											</p>
											<div className="boxparticipantes">
												<p>Participantes:</p>
												<div className="itens">
													<div className="item">
														<figure>
															<img
																src={require("../../../assets/img/user.png")}
																alt=""
															/>
														</figure>
														<p>{e.player[0]?.name}</p>
													</div>

													{e.participants.map((participant: any) => (
														<div className="item">
															<figure>
																<img
																	src={require("../../../assets/img/user.png")}
																	alt=""
																/>
															</figure>
															<p>{participant.label}</p>
														</div>
													))}
												</div>
											</div>
											{e.video !== "" && (
												<div className="link">
													<p>Link do vídeo</p>
													<a href={e.video} target="_blank" rel="noreferrer">
														{e.video}
													</a>
												</div>
											)}
											{e.file && (
												<div className="file">
													<p>Arquivos:</p>
													<button onClick={() => downloadFile(e)}>
														Download
													</button>
												</div>
											)}
											<hr />
											<p>
												Este comentário é opcional e será apresentado para toda
												comunidade do TO ON.
											</p>
											<textarea
												disabled
												value={formAvaliation.comment}
												placeholder="Fazer comentário"
											></textarea>
										</div>
									</div>
								</div>
							</>
						))}
					{(loading || otherIdeasLoading) && (
						<Skeleton size={3} type="LISTFULL" />
					)}

					<ReactPaginate
						breakLabel="..."
						nextLabel="Próxima >"
						onPageChange={(data) => loadOtherIdeas(data.selected, true)}
						pageRangeDisplayed={5}
						pageCount={pageContIdeas}
						previousLabel="< Anterior"
						renderOnZeroPageCount={null}
						className={`pagination mt-6 ${
							pageContIdeas <= 1 || loading || otherIdeasLoading ? "hidden" : ""
						}`}
					/>
				</article>
			</section>
			{/* {loading && <Skeleton size={3} type="LISTFULL" />} */}
		</div>
	);
};

export default AdminCentralIdeia;
