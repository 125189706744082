import { useAuth } from "context/AuthContext";
import React from "react";
import { FC, useEffect } from "react";

interface Options {
	type:
		| "MODULE"
		| "TRAIL"
		| "LIST"
		| "LISTFULL"
		| "CHALLENGE"
		| "CHALLENGE-SIDEBAR"
		| "IDEA";
	size: number;
}
const Skeleton: FC<Options> = ({ type, size }: Options) => {
	const { contrast } = useAuth();

	return (
		<>
			{Array.from({ length: size }).map((v: any, i: number) => (
				<React.Fragment key={`skeleton_${i}`}>
					{type === "MODULE" && (
						<article
							key={`skeleton_module_${i}`}
							className={`max-w-sm animate-pulse ${
								contrast ? "bg-black" : "bg-white"
							} rounded-[10px] py-[30px] px-[22px] mb-5 transition`}
						>
							<div
								className={`${
									contrast ? "bg-yellow" : "bg-gray-200"
								} mb-3 text-xl font-normal`}
							/>
							<div
								className={`${
									contrast ? "bg-yellow" : "bg-gray-200"
								} text-sm font-normal mb-12 h-4`}
							/>
							<div
								className={`${
									contrast ? "bg-yellow" : "bg-gray-200"
								} text-sm font-normal mb-12 h-2`}
							/>
						</article>
					)}
					{type === "TRAIL" && (
						<article
							key={`skeleton_trail_${i}`}
							className={`${
								contrast ? "bg-black border border-yellow" : "bg-white"
							} trail-card flex mb-[18px] rounded-[10px] relative transition animate-pulse w-full`}
						>
							<div
								className={`${
									contrast ? "bg-yellow" : "bg-slate-300"
								} bar h-full w-5 absolute top-0 left-0 rounded-tl-[10px] rounded-bl-[10px]`}
							/>
							<div className="py-7 px-10 w-full">
								<div
									className={`${
										contrast ? "bg-yellow " : "bg-gray-200"
									} h-4 text-gray-300 font-normal text-3xl mb-[14px]`}
								/>
								<div
									className={`text-gray-300 ${
										contrast ? "bg-yellow" : "bg-gray-200"
									} font-normal text-sm h-7`}
								/>
								<div
									className={`text-gray-300 ${
										contrast ? "bg-yellow" : "bg-gray-200"
									} font-normal text-sm h-7`}
								/>
							</div>
						</article>
					)}
					{type === "LIST" && (
						<div className="space-y-5" key={`skeleton_list_${i}`}>
							<article className="max-w-sm animate-pulse rounded-[10px] py-[30px] px-[22px] mb-5 transition">
								<div
									className={`${
										contrast ? "bg-yellow" : "bg-gray-200"
									} mb-3 text-xl font-normal h-8`}
								/>
								<div
									className={`${
										contrast ? "bg-yellow" : "bg-gray-200"
									} text-sm font-normal mb-3 h-4`}
								/>
								<div
									className={`${
										contrast ? "bg-yellow" : "bg-gray-200"
									} text-sm font-normal mb-3 h-2`}
								/>
							</article>
						</div>
					)}
					{type === "LISTFULL" && (
						<div className="space-y-5" key={`skeleton_list_full_${i}`}>
							<article className="w-full animate-pulse rounded-[10px] py-[30px] px-[22px] mb-3 transition">
								<div
									className={`${
										contrast ? "bg-yellow" : "bg-gray-200"
									} mb-3 text-xl font-normal`}
								/>
								<div
									className={`${
										contrast ? "bg-yellow" : "bg-gray-200"
									} text-sm font-normal mb-3 h-6`}
								/>
								<div
									className={`${
										contrast ? "bg-yellow" : "bg-gray-200"
									} text-sm font-normal mb-3 h-3`}
								/>
							</article>
						</div>
					)}
					{type === "IDEA" && (
						<div className="space-y-5 w-full" key={`skeleton_idea_${i}`}>
							<article className="w-full animate-pulse rounded-[10px] py-[30px] px-[22px] mb-5 transition">
								<div
									className={`${
										contrast ? "bg-yellow" : "bg-gray-200"
									} mb-3 text-xl font-normal h-8`}
								/>
								<div
									className={`${
										contrast ? "bg-yellow" : "bg-gray-200"
									} text-sm font-normal mb-3 h-4`}
								/>
								<div
									className={`${
										contrast ? "bg-yellow" : "bg-gray-200"
									} text-sm font-normal mb-3 h-2`}
								/>
							</article>
						</div>
					)}
					{type === "CHALLENGE-SIDEBAR" && (
						<article
							className="flex items-center mb-7"
							key={`skeleton_challenge_${i}`}
						>
							<div className="thumb">
								<svg
									className="w-10 h-10 text-gray-200 bg-gray-200 rounded-full opacity-80"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 18"
								>
									<path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
								</svg>
							</div>
							<div>
								<div
									className={`h-2.5 ${
										contrast
											? "bg-yellow dark:bg-yellow-700"
											: "bg-gray-200 dark:bg-gray-700"
									}  rounded-full  w-48 mb-4`}
								/>
								<div
									className={`h-2 ${
										contrast
											? "bg-yellow dark:bg-yellow-700"
											: "bg-gray-200 dark:bg-gray-700"
									} rounded-full  max-w-[440px] mb-2.5`}
								/>
								<div
									className={`h-2 ${
										contrast
											? "bg-yellow dark:bg-yellow-700"
											: "bg-gray-200 dark:bg-gray-700"
									} rounded-full  max-w-[460px] mb-2.5`}
								/>
								<div
									className={`h-2 ${
										contrast
											? "bg-yellow dark:bg-yellow-700"
											: "bg-gray-200 dark:bg-gray-700"
									} rounded-full  max-w-[360px]`}
								/>
							</div>
						</article>
					)}
				</React.Fragment>
			))}
		</>
	);
};

export default Skeleton;
