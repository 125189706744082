import { api } from './api';


export async function getGlobalConfig() {
    const payload = [
      {
        "$match": {"_id": "global_config"}
      }
    ];
    const { data } = await api.post("database/global_config__c/aggregate", payload);
    return data;
}