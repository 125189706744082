import { useNavigate } from 'react-router-dom';

import Logo from 'assets/img/logo-festa-junina.svg';
import { useAuth } from 'context/AuthContext';
import { useEffect } from 'react';

const NotFound = () => {
  const { signOut, setPageTitle } = useAuth();

  useEffect(() => {
    setPageTitle('');
  }, []);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col space-y-5 justify-center items-center h-screen w-full">
      <h1 className="text-2xl">BB TO ON.</h1>
      <h1 className="text-xl">Página não encontrada.</h1>

      <div className="pt-5">
        <button
          className="uppercase py-2 px-5 rounded w-auto"
          onClick={() => navigate('/')}
        >
          VOltar para Página inicial
        </button>
      </div>
    </div>
  );
};

export default NotFound;
