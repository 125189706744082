import { api } from "./api";

export async function getExtratoSimples(
  player: string,
  startDate: string,
  endDate: string
) {
  const headers = {
    range: "item=0-1000"
  }
  const payload = { player, startDate, endDate };

  const { data } = await api.post("/find/extrato_simples", payload, {headers});
  return data;
}

export async function getApuracao(challenge: string) {
  const payload = { challenge };

  const { data } = await api.post("/find/apuracao", payload);
  return data;
}
