import { MsalAuthenticationResult } from '@azure/msal-react';

export const ErrorComponent: React.FC<MsalAuthenticationResult> = ({
  error,
}) => {
  return (
    <div className="cta">
      <div className="rounded-md shadow-xl font-bold px-4 w-full text-xl p-2 bg-red text-white">
        <div className="flex flex-row items-center justify-center space-x-2">
          <span>Erro {error ? error.errorCode : 'Desconhecido'}</span>
        </div>
      </div>
    </div>
  );
};
