import React, { Component } from 'react';

import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

import Avatar, { genConfig } from 'funifier-nice-avatar';

require('./index.scss');

class AvatarManager extends Component {
  avatarId: string;
  constructor(props:any) {
    super(props);
    this.state = {
      config: genConfig({
        isGradient: Boolean(Math.round(Math.random())),
      }),
      shape: 'circle',
    };
    this.avatarId = 'myAvatar';
  }

  selectConfig(config:any) {
    this.setState({ config });
  }

  updateConfig(key:any, value:any) {
    const { config } = this.state as any;
    config[key] = value;
    this.setState({ config });
  }

  updateShape(shape:any) {
    this.setState({ shape });
  }

  async download() {
    const scale = 2;
    const node = document.getElementById(this.avatarId);
    if (node) {
      const blob = await domtoimage.toBlob(node, {
        height: node.offsetHeight * scale,
        style: {
          transform: `scale(${scale}) translate(${node.offsetWidth / 2 / scale}px, ${node.offsetHeight / 2 / scale}px)`,
          'border-radius': 0,
        },
        width: node.offsetWidth * scale,
      });

      saveAs(blob, 'avatar.png');
    }
  }

  render() {
    const { config, shape } = this.state as any;
    return (
      <div className="App flex flex-col min-h-screen">
        <main className="flex-1 flex flex-col items-center justify-center">
          <div id={this.avatarId} className="mb-10">
            <Avatar
              className="w-64 h-64 highres:w-80 highres:h-80"
              hairColorRandom
              shape={shape}
              {...config}
            />
          </div>
        </main>
      </div>
    );
  }
}

export default AvatarManager;
