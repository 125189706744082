import { api } from './api';

export async function uploadGlobalFile(file: File, session: string) {
  try {
    const form_data = new FormData();
    form_data.append('file', file);
    form_data.append(
      'extra',
      JSON.stringify({
        session: session,
        thumbnails: [
          { name: 'small', width: 160, height: 160 },
          { name: 'medium', width: 260, height: 260 },
          { name: 'big', width: 520, height: 520 },
        ],
      })
    );

    const { data } = await api.post('upload/file', form_data);
    return data.status === 'OK' ? data.uploads[0] : data;
  } catch (err) {
    console.log('uploadPicture', err);
  }
}