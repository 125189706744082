import { ReactNode, useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';
import BB_ROUTES from './const';

interface IPublicRoute {
  children: ReactNode;
}

export function PublicRoute({ children }: IPublicRoute) {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? (
    <Navigate to={BB_ROUTES.TRAIL()} />
  ) : (
    <>{children}</>
  );
}
