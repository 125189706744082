import { useAuth } from "context/AuthContext";
import { DialogContent } from "utils/modal-board-data";
import { FC } from "react";
import { AiOutlineLink } from "react-icons/ai";
import { TiTimes } from "react-icons/ti";
import { toast } from "react-toastify";

interface Options {
  content?: DialogContent;
  onEvent: (e?: any) => void;
  onClose: () => void;
}
const DialogExternal: FC<Options> = ({
  content,
  onEvent,
  onClose,
}: Options) => {
  const { contrast } = useAuth();

  const onOk = () => {
    window.open(content?.url, "_blank");
    onEvent();
    onClose();
  };

  const onCopy = () => {
    const textField = document.createElement("textarea");
    textField.innerText = String(content?.url);
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    toast.info("Url copiada com sucesso");
  };

  return (
    <>
      <div
        key={`video-${new Date().getTime()}`}
        className={`${
          contrast ? "bg-black border-2 border-yellow" : "bg-white"
        } shadow rounded-[10px] py-5 px-5 dialog transition-all flex flex-col justify-between w-96 dialog-external`}
      >
        <div className="flex justify-end items-end mb-3">
          <button
            onClick={onClose}
            className="rounded-full "
            aria-label={"Fechar modal de curso"}
          >
            <TiTimes color={contrast ? "#fcfc30" : "#69696E"} size={25} />
          </button>
        </div>
        <div className="mb-4">
          <h3
            className={`font-bold px-4 md:p-0 leading-tight md:text-2xl ${
              contrast ? "text-yellow" : "text-black"
            }`}
            tabIndex={0}
          >
            Olá, você será direcionado para um link externo.
          </h3>

          {/* <a
            target="_blank"
            href={content?.url}
            className="text-blue mb-[18px] block"
          >
            {content?.url}
          </a> */}
          <div
            className={`${
              contrast ? "text-yellow" : "text-gray-300"
            } mb-[18px]`}
          >
            <p tabIndex={0}>
              Você receberá suas estrelas no Game Tô On em até 24h após o
              registro do curso em seu Currículo.
            </p>
            <p tabIndex={0}>
              <b>Importante!</b> Você deve clicar no botão{" "}
              <b>"ir para o curso"</b> para que a casa conste como{" "}
              <b>"concluída"</b> posteriormente, no game. Se estiver AUTENTICADO
              (logado) na UNIBB, esse link vai levar direto ao curso.
            </p>
          </div>
        </div>

        <div
          className={`border ${
            contrast
              ? "border-yellow text-yellow"
              : "border-gray-100 text-gray-300"
          }  justify-between flex p-4 mb-4 cursor-pointer`}
          onClick={onCopy}
          aria-label="Cópiar link"
          role="button"
          tabIndex={0}
        >
          <button aria-hidden={true}>Copiar o link</button>

          <span aria-hidden={true}>
            <AiOutlineLink />
          </span>
        </div>

        <div className="flex items-center justify-center">
          <button
            className={`${
              contrast
                ? "bg-black border border-yellow text-yellow"
                : "bg-blue text-white"
            } p-3 text-center text-base rounded-[10px]`}
            onClick={onOk}
            tabIndex={0}
            aria-label={"Ir para o curso e Fechar modal"}
          >
            Ir para o curso
          </button>
        </div>
      </div>
    </>
  );
};

export default DialogExternal;
