export const Loading = () => {
  return (
    <div className="cta">
      <div className="rounded-md shadow-xl font-bold px-4 w-full text-xl p-2 bg-yellow text-white">
        <div className="flex flex-row items-center justify-center space-x-2">
          <span>CARREGANDO...</span>
        </div>
      </div>
    </div>
  );
};
