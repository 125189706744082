import Skeleton from "components/Skeleton";
import { AuthContext, useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getIdeias, getMyIdeaOrContributte } from "services/ideia.service";
import Box from "../Box";
import "./style.scss";

const Idea = () => {
	const navigate = useNavigate();
	const [dataIdeia, setDataIdeia] = useState([]);
	const [loading, setLoading] = useState(false);
	const { allowAddIdea, contrast } = useAuth();
	const { setShowModalAddIdeia, showModalAddIdeia, refreshListIdeias } =
		useModal();
	const {
		user,
		globalConfig,
		DateDifferenceCheck,
		myIdeaOrContributte,
		myIdea,
	} = useContext(AuthContext);

	useEffect(() => {
		getData();
	}, [refreshListIdeias]);

	async function getData() {
		setLoading(true);
		getIdeias(
			[
				{
					$sort: {
						created_at: -1,
					},
				},
			],
			0,
			3,
		)
			.then((resp) => {
				if (resp.length > 0) {
					setDataIdeia(resp);
				}
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 300);
			});
	}

	function verifyAdicionarIdeia() {
		if (user) {
			getMyIdeaOrContributte(user?._id).then((resp) => {
				if (resp.length === 0 && allowAddIdea) {
					setShowModalAddIdeia(!showModalAddIdeia);
				} else if (resp.length > 0) {
					toast.error("Você já enviou ou é participante de uma ideia");
				} else if (!allowAddIdea) {
					toast.error("O período para adicionar uma ideia já está encerrado");
				}
			});
		}
	}

	return (
		<>
			<Box
				title={<>Central de ideias</>}
				titleColor={contrast ? `#fcfc30` : "#735CC6"}
				layoutClass={
					contrast
						? `bg-black box-idea border border-yellow`
						: `bg-yellow-100 box-idea`
				}
				ctaText="Ver todas"
				path={"/ideias"}
				bgColorButton={contrast ? `#fcfc30` : "#735CC6"}
				titleColorButton={contrast ? "#fcfc30" : "#FFF"}
				customClass="idea"
				buttonText={myIdea ? "Minha ideia" : "Adicionar ideia"}
				actionButton={() => {
					myIdea ? navigate(`/ideias/${myIdea._id}`) : verifyAdicionarIdeia();
				}}
				children={
					<>
						<div className="icon-idea" aria-hidden={true} tabIndex={-1}>
							<img
								src={require("../../../assets/img/icon_ideia.png")}
								alt=""
								className="w-4/5"
							/>
						</div>
						{loading ? (
							<Skeleton size={1} type="LIST" />
						) : (
							<>
								{dataIdeia.map((e: any, index) => {
									return (
										<Link
											key={e._id}
											to={`/ideias/${e._id}`}
											aria-label={`${e.title}. Enviada por:${e.p.name}`}
										>
											<article
												aria-hidden={true}
												className="flex items-center mb-7 item-ideia"
												tabIndex={-1}
											>
												<div
													className="thumb mr-3"
													aria-hidden={true}
													tabIndex={-1}
												>
													{e.image ? (
														<img
															src={e.image}
															alt="Imagem Ideia"
															aria-hidden={true}
															tabIndex={-1}
														/>
													) : (
														<img
															aria-hidden={true}
															tabIndex={-1}
															src={
																e.image &&
																e.image !== "" &&
																typeof e.image === "string"
																	? e.image
																	: e.p?.image?.small
																	  ? e.p.image.small.url
																	  : require("../../../assets/img/user.png")
															}
															alt="Imagem Ideia"
															aria-label={`Avatar de ${e.p.name}`}
														/>
													)}
												</div>
												<div aria-hidden={true} tabIndex={-1}>
													<p
														className={`${
															contrast ? "text-yellow" : "text-gray-300"
														} text-xs font-normal`}
														aria-hidden={true}
														tabIndex={-1}
														aria-label={`Idéia ${e.title}`}
													>
														{e.title}
													</p>
													<p
														className={`${
															contrast ? "text-yellow" : "text-gray-300"
														} text-[10px] font-normal mt-0`}
														aria-hidden={true}
														tabIndex={-1}
														aria-label={`Autor ${e.p.name}`}
													>
														{e.p.name}
													</p>
												</div>
											</article>
										</Link>
									);
								})}
								{dataIdeia.length === 0 && (
									<p
										className={`${
											contrast ? "text-yellow" : "text-gray-300"
										} text-xs font-normal`}
										tabIndex={0}
									>
										Nenhuma ideia registrada!
									</p>
								)}
							</>
						)}
					</>
				}
			></Box>
		</>
	);
};

export default Idea;
