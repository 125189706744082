const Logo = () => {
  return (
    <>
      <img
        src={`/img/new-brand.png`}
        width={`100%`}
        className="relative lg:left-0 lg:top-[20px] z-20"
        tabIndex={0}
        aria-label="Logo Game tô on"
      />
    </>
  );
};

export default Logo;
