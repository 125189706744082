import { NotificationInfo } from 'classes/notification.interface';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import BB_ROUTES from 'routes/const';
import {
  getNotifications,
  updateNotificationAsRead,
} from 'services/notifications.service';
import Switch from 'react-switch';

export const uncheckedIcon = (
  <svg
    viewBox="-2 -5 14 20"
    height="100%"
    width="100%"
    style={{ position: 'absolute', top: 0 }}
  >
    <path
      d="M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 7.072 4.95 9.9 2.12"
      fill="#735CC6"
      fillRule="evenodd"
    />
  </svg>
);

export const checkedIcon = (
  <svg
    height="100%"
    width="100%"
    viewBox="-2 -5 17 21"
    style={{ position: 'absolute', top: 0 }}
  >
    <path
      d="M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0"
      fill="#735CC6"
      fillRule="evenodd"
    />
  </svg>
);

const Notification = () => {
  const {
    setBreadCrumbItens,
    setSidebar,
    setPageTitle,
    refreshUser,
    contrast,
  } = useAuth();

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const [itens, setItens] = useState<NotificationInfo[]>([]);

  const [pagination, setpagination] = useState({
    INITIAL_RANGE: 0,
    QTD_PER_PAGE: 10,
    RANGE_PAGE: 10,
  });

  useEffect(() => {
    setSidebar(false);
    setPageTitle('Notificações');
    setBreadCrumbItens([
      {
        isFirst: true,
        name: 'Jornadas /',
        active: false,
        path: BB_ROUTES.HOME(),
      },
      {
        isFirst: false,
        name: 'Notificações',
        active: true,
        path: BB_ROUTES.NOTIFICATION(),
      },
    ]);

    onload();
  }, []);

  function onload() {
    getNotifications().then((resp) => {
      const n: Array<NotificationInfo> = [];
      resp?.forEach((item: NotificationInfo) => {
        item.checked = false;
        n.push(item);
      });
      setItens(n);
    });
  }

  async function markCheck(index: number, e?: any) {
    const list = [...itens];
    list[index].checked = !list[index].checked;
    await updateNotificationAsRead(list[index]);

    setItens(list);
  }

  async function markAll() {
    const list = [...itens];
    list.forEach((item) => {
      item.checked = true;
    });

    setItens(list);

    list.forEach(async (item) => {
      await updateNotificationAsRead(item);
    });

    refreshUser();
  }

  return (
    <div className="w-full text-white text-xl font-lighter pb-14">
      {loading && 'Carregando'}
      {!loading && itens.length > 0 && (
        <>
          <div
            className={`flex flex-col justify-between lg:flex-row pb-8 mb-8 lg:mb-10 lg:pb-0 border-b lg:border-b-0 ${
              contrast ? 'border-yellow' : 'border-white'
            }`}
          >
            <div className="action-date flex  flex-col lg:flex-row justify-center lg:justify-start items-center lg:items-start gap-2">
              <label>
                <span className={contrast ? 'text-yellow' : 'text-white'}>
                  De:
                </span>
                <input
                  className={`${
                    contrast ? 'border border-yellow  text-yellow' : ''
                  } py-3 px-5 bg-transparent border rounded-xl  ml-3`}
                  type="date"
                  placeholder="00/00/000"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </label>
              <label>
                <span className={contrast ? 'text-yellow' : 'text-white'}>
                  Até:
                </span>
                <input
                  className={`${
                    contrast ? 'border border-yellow text-yellow' : ''
                  } py-3 px-5 bg-transparent border rounded-xl  ml-3`}
                  type="date"
                  placeholder="00/00/000"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </label>
            </div>
            <button
              className={`${
                contrast
                  ? 'bg-black border border-yellow text-yellow'
                  : 'bg-purple-100 text-purple'
              } py-3 px-5 rounded-xl mt-[50px] lg:mt-0 text-base`}
              onClick={markAll}
            >
              Marcar tudo como lida
            </button>
          </div>
          <div className="hidden w-full lg:block">
            <table width={'100%'} className="w-full">
              <thead>
                <tr>
                  <th
                    className={`${
                      contrast
                        ? 'bg-black text-yellow border-b border-yellow'
                        : 'bg-purple-100 text-gray-300'
                    } text-sm  font-medium px-4 py-4 text-left uppercase rounded-tl-xl rounded-bl-xl`}
                  >
                    Data
                  </th>
                  <th
                    className={`${
                      contrast
                        ? 'bg-black text-yellow border-b border-yellow'
                        : 'bg-purple-100 text-gray-300'
                    } text-sm  font-medium px-0 py-4 text-left uppercase`}
                  >
                    Detalhamento
                  </th>
                  <th
                    className={`${
                      contrast
                        ? 'g-black text-yellow border-b border-yellow'
                        : 'bg-purple-100 text-gray-300'
                    } text-sm  font-medium px-0 py-4 text-left uppercase  rounded-tr-xl rounded-br-xl`}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {itens.map((item: NotificationInfo, index: number) => (
                  <tr
                    className={`border-b ${
                      contrast ? 'border-yellow' : 'border-white'
                    }`}
                  >
                    <td
                      className={`py-5 px-4 ${
                        contrast ? 'text-yellow' : 'text-white'
                      }`}
                      tabIndex={0}
                      aria-label={format(
                        item.time,
                        "dd/MMM/yyyy', às' HH:mm'h'",
                        {
                          locale: ptBR,
                        }
                      )}
                    >
                      {format(item.time, "dd/MMM/yyyy', às' HH:mm'h'", {
                        locale: ptBR,
                      })}
                    </td>
                    <td
                      className={`py-5 ${
                        contrast ? 'text-yellow' : 'text-white'
                      }`}
                      tabIndex={0}
                      aria-label={item.definition.content}
                    >
                      {item.definition.content}
                    </td>
                    <td
                      className={`py-5 ${
                        contrast ? 'text-yellow' : 'text-white'
                      }`}
                    >
                      <Switch
                        onHandleColor={contrast ? '#FCFC30' : '#735CC6'}
                        offColor="#BDB6FF"
                        aria-label="Marcar como lida"
                        offHandleColor={'#735CC6'}
                        onColor={contrast ? '#000' : '#FCFC30'}
                        checkedIcon={checkedIcon}
                        uncheckedIcon={uncheckedIcon}
                        onChange={(e: any) => markCheck(index, e)}
                        checked={item.checked}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="block lg:hidden">
            {itens.map((item: NotificationInfo, index: number) => (
              <div
                className={`border-b ${
                  contrast ? 'border-yellow' : 'border-white'
                }  px-0 pt-5 pb-6`}
                key={`${item._id}_${index}`}
              >
                <div className="mb-4 flex justify-end">
                  <Switch
                    onHandleColor={'#735CC6'}
                    offColor="#BDB6FF"
                    offHandleColor={'#735CC6'}
                    onColor={'#FCFC30'}
                    checkedIcon={checkedIcon}
                    uncheckedIcon={uncheckedIcon}
                    onChange={(e: any) => markCheck(index, e)}
                    checked={item.checked}
                  />
                </div>
                <div className="flex items-start justify-between mb-7">
                  <span
                    className={` text-sm ${
                      contrast
                        ? 'bg-black text-yellow'
                        : 'bg-purple-100 text-gray-300'
                    } font-medium px-4 py-4 text-left uppercase rounded-xl`}
                  >
                    Data
                  </span>
                  <p className="text-sm font-normal">
                    {format(item.time, "dd/MMM/yyyy', às' HH:mm'h'", {
                      locale: ptBR,
                    })}
                  </p>
                </div>
                <div className="flex  items-start justify-between">
                  <span
                    className={` text-sm ${
                      contrast
                        ? 'bg-black text-yellow'
                        : 'bg-purple-100 text-gray-300'
                    }  font-medium px-4 py-4 text-left uppercase rounded-xl`}
                  >
                    Detalhamento
                  </span>
                  <p className="text-sm font-normal text-right">
                    {item.definition.content}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {!loading && itens.length == 0 && (
        <p className="text-white text-center text-base">
          Sem notificações no momento
        </p>
      )}
    </div>
  );
};

export default Notification;
