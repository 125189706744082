const imagesGallery: Array<string> = [
    "https://s3.amazonaws.com/funifier/games/toon_prod/images/64d23b91f06e6e41d19f3028_original_Aven.png",
    "https://s3.amazonaws.com/funifier/games/toon_prod/images/64d8f57e1ce3f7475ccb2fa5_original_A_Lab.png",
    "https://s3.amazonaws.com/funifier/games/toon_prod/images/64d8f6401ce3f7475ccb2fae_original_Fox_Hub.png",
    "https://s3.amazonaws.com/funifier/games/toon_prod/images/64d8f6591ce3f7475ccb2fb1_original_Hexa.png",
    "https://s3.amazonaws.com/funifier/games/toon_prod/images/64d8f6711ce3f7475ccb2fb4_original_Ideaa.png",
    "https://s3.amazonaws.com/funifier/games/toon_prod/images/64d8f6821ce3f7475ccb2fb7_original_Lighting.png",
    "https://s3.amazonaws.com/funifier/games/toon_prod/images/64d8f69b1ce3f7475ccb2fba_original_Nira.png",
    "https://s3.amazonaws.com/funifier/games/toon_prod/images/64d8f6ac1ce3f7475ccb2fbd_original_Solaytic.png",
    "https://s3.amazonaws.com/funifier/games/toon_prod/images/64d8f6bd1ce3f7475ccb2fc0_original_Towers.png"
]

export const imagesGalleryDescription: Array<string> = [
    "Imagem 1: Ícone estilizado, representando uma flor, com pétalas em um degradê laranja e rosa e um circulo roxo no centro.",
    "Imagem 2: Forma circular com bordas estilizadas, na cor azul e uma letra 'A' no centro.",
    "Imagem 3: Ícone estilizado no formato de uma raposa",
    "Imagem 4: Ícone formado por 3 hexágonos, conectados entre si, com referencia a uma colmeia de abelhas.",
    "Imagem 5: Ícone estilizado, representando uma lâmpada, emitindo luz, colorido com um degradê rosa e laranja em tons pastéis.",
    "Imagem 6: Ícone representando um raio, na cor laranja, sobrepondo um círculo com contorno preto.",
    "Imagem 7: Um gráfico representado por uma linha contínua que formam uma espécie de flor de 3 pétalas. Parecido com o símbolo que representa o infinito.",
    "Imagem 8: Um círculo rosa, com pequenas linhas rosa em volta e um coração branco no centro.",
    "Imagem 9: Um ícone estilizado, na cor laranja, com 3 linhas verticais em tamanhos diferentes que parecem prédios, mas também lembram um gráfico de barras."
]

export default imagesGallery;