import slugify from 'slugify';

export const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

export function FormatNumber(value: any) {
  try {
    if (isNaN(value)) return 0;
    return Intl.NumberFormat('pt-BR').format(Math.ceil(value));
  } catch (e) {
    return isNaN(value) ? 0 : value;
  }
}

export function stringToSearchPlayers(text: string) {
  if (typeof text !== 'string') return '';

  try {
    return slugify(text, ' ')
      .replace(/[.·/_\-,:;]+/g, ' ')
      .replace(/[\s]+/g, ' ')
      .trim()
      .toUpperCase();
  } catch (e) {
    return text
      .replace(/[.·/_\-,:;]+/g, ' ')
      .replace(/[\s]+/g, ' ')
      .trim()
      .toUpperCase();
  }
}


export function findLast(data:Array<any>, key:string, value:any)
{
  const itens = data;
  const filteredData = itens.filter((item) =>{
    
    if(item[key]){
      return item[key] === value;
    }
  });


  return filteredData[filteredData.length -1] ?  filteredData[filteredData.length -1] : {};
}