import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import { AiFillHeart } from "react-icons/ai";
import { ranking } from "services/ideia.service";
import Box from "../Box";
import "./style.scss";
import { UserAvatar } from "components/user-avatar";
import { Link } from "react-router-dom";

const RankingCommunity = () => {
	const [data, setData] = useState([]);
	const [myPosition, setMyPosition] = useState<any>({});
	const { user, contrast } = useAuth();
	const [showDetail, setShowDetail] = useState(false);

	useEffect(() => {
		if (!data.length) {
			getData();
		}
	}, []);

	useEffect(() => {
		if (data.length) {
			getMyPosition();
		}
	}, [data, user]);

	function getData() {
		ranking().then((resp) => {
			if (resp.length > 0) {
				setData(resp);
			}
		});
	}

	function getMyPosition() {
		if (user) {
			data.some((e: any, index) => {
				if (
					e.player[0] &&
					(e.player[0]._id === user._id ||
						e.participants.find((e: any) => e.value === user._id)) &&
					index > 2
				) {
					e.position = index + 1;
					setMyPosition(e);
					return true;
				}
				return false;
			});
		}
	}

	return (
		<Box
			title={
				<>
					<div className="flex items-center">
						<AiFillHeart
							color={contrast ? `#fcfc30` : "#F97A70"}
							className="mr-[10px]"
							size={22}
						/>
						Ranking da comunidade
					</div>
				</>
			}
			ctaText=""
			path={"/ranking"}
			layoutClass="border border-white box-ranking"
			customClass="ranking"
			children={
				<>
					<div className="list">
						{data.map(
							(e: any, index) =>
								index <= 2 && (
									<article key={index}>
										<Link
											to={`/ideias/${e._id}`}
											className="flex items-start item-position mb-7"
										>
											<div
												className="thumb mr-3 w-[30%] min-w-[80px] h-[80px]"
												aria-hidden={true}
												tabIndex={-1}
											>
												{e.image ? (
													<img
														src={e.image}
														alt="Imagem Ideia"
														width={80}
														aria-hidden={true}
														tabIndex={-1}
													/>
												) : (
													<img
														src={require("../../../assets/img/user.png")}
														aria-hidden={true}
														tabIndex={-1}
														width={80}
														alt="Imagem Ideia"
													/>
												)}
											</div>
											<div className="w-[70%] text-left">
												<p className="text-yellow uppercase text-xs font-bold">
													{index + 1}º LUGAR
												</p>
												<p
													className={`text-sm ${
														contrast ? "text-yellow" : "text-white"
													} font-thin underline m-0`}
												>
													{e.title || "--"}
												</p>
												<h5
													className={`text-xs ${
														contrast ? "text-yellow" : "text-white"
													} font-light mt-2 mb-1`}
												>
													{e.player[0]?.name}
												</h5>
												<div className="flex items-center">
													<AiFillHeart
														color={contrast ? `#fcfc30` : "#F97A70"}
														size={18}
													/>
													<span
														className={`${
															contrast ? "text-yellow" : "text-white"
														} text-[10px] font-normal`}
													>
														{e.likesCount} Curtidas
													</span>
												</div>
											</div>
										</Link>
									</article>
								),
						)}
					</div>
					<p className="text-yellow uppercase text-xs font-bold mb-1">...</p>
					{myPosition.position && (
						<div className="my-position border-t border-yellow border-b pb-5">
							<div className="bg-yellow rounded-bl-lg rounded-br-lg py-2 px-2 text-center w-28">
								<span className="text-gray-300 text-xs">Sua posição</span>
							</div>
							<article>
								<Link
									to={`/ideias/${myPosition._id}`}
									className="flex items-start item-position mb-3 mt-3"
								>
									<div className="thumb mr-3 w-[30%] min-w-[80px] h-[80px] imgMy">
										{myPosition.image ? (
											<img
												src={myPosition.image}
												alt="Imagem Ideia"
												aria-hidden={true}
												width={80}
											/>
										) : (
											<img
												src={require("../../../assets/img/user.png")}
												width={80}
												alt="Imagem Ideia"
												aria-hidden={true}
											/>
										)}
									</div>
									<div className="w-[70%] text-left">
										<p className="text-yellow uppercase text-xs font-bold">
											{myPosition.position}º LUGAR
										</p>
										<p className="text-sm text-white font-thin underline m-0">
											{myPosition.title}
										</p>
										<h5 className="text-xs text-white font-light mt-2 mb-1">
											{myPosition.player[0]?.name}
										</h5>
										<div className="flex items-center">
											<AiFillHeart color="#F97A70" size={18} />
											<span className="text-white text-[10px] font-normal">
												{myPosition.likesCount} Curtidas
											</span>
										</div>
									</div>
								</Link>
							</article>
						</div>
					)}
					<p className="text-left py-5 underline hover:no-underline text-xs font-bold text-[#fcfc30]">
						<Link to="/ranking-comunidade">Ranking completo</Link>
					</p>
				</>
			}
		></Box>
	);
};

export default RankingCommunity;
