import { is } from "date-fns/locale";
import React, { useRef, useEffect, useState, HtmlHTMLAttributes } from "react";

interface TabFocusTrap {
	isOpen: boolean;
	setIsOpen: (arg: boolean) => void;
	children: React.ReactNode;
	startElement?: string;
}

const TabFocusTrap = ({
	startElement,
	isOpen,
	setIsOpen,
	children,
}: TabFocusTrap) => {
	const modalRef = useRef<HTMLDivElement>(null);

	const mappedElements =
		'button, h3, div, p, iframe, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

	useEffect(() => {
		if (isOpen) {
			if (modalRef.current === null) return;

			const modalElement = modalRef?.current; //modalRef.current as HTMLElement;

			//add any focusable HTML element you want to include to this string
			let focusableElements = modalRef.current.querySelectorAll(mappedElements);

			let currentElement = document.activeElement as HTMLElement;
			let firstElement: HTMLElement;

			if (startElement) {
				firstElement = modalRef.current.querySelector(
					String(startElement),
				) as HTMLElement;
			} else {
				firstElement = focusableElements[0] as HTMLElement;
			}

			if (firstElement) {
				firstElement.focus();
			}

			const lastElement = focusableElements[
				focusableElements.length - 1
			] as HTMLElement;

			const handleTabKeyPress = (event: any) => {
				if (event.key === "Tab") {
					if (event.shiftKey && document.activeElement === firstElement) {
						event.preventDefault();
						lastElement.focus();
					} else if (
						!event.shiftKey &&
						document.activeElement === lastElement
					) {
						event.preventDefault();
						firstElement.focus();
					}
				}
			};

			const handleTabKeyRelease = (event: any) => {
				if (!isOpen) return;

				//add any focusable HTML element you want to include to this string
				focusableElements = modalElement.querySelectorAll(mappedElements);

				currentElement = document.activeElement as HTMLElement;

				let isCurrentElementInModal = false;

				for (let i = 0; i < focusableElements.length; i++) {
					const element = focusableElements.item(i) as HTMLElement;
					if (element === currentElement) {
						isCurrentElementInModal = true;
						break;
					}
				}

				const isModalElement = document.activeElement?.hasAttribute(
					"data-react-modal-body-trap",
				);

				if (!isCurrentElementInModal && !isModalElement) {
					// event.preventDefault();
					firstElement?.focus();

					try {
						(
							document.querySelectorAll("[role=dialog]")[0] as HTMLElement
						).focus();
					} catch (e) {
						console.log("invalid element to focus");
					}
				}
			};

			const handleEscapeKeyPress = (event: any) => {
				if (event.key === "Escape") {
					setIsOpen(false);
				}
			};

			document.addEventListener("keydown", handleTabKeyPress);
			document.addEventListener("keyup", handleTabKeyRelease);
			document.addEventListener("keydown", handleEscapeKeyPress);

			return () => {
				document.removeEventListener("keydown", handleTabKeyPress);
				document.removeEventListener("keyup", handleTabKeyRelease);
				document.removeEventListener("keydown", handleEscapeKeyPress);
			};
		}
	}, [isOpen, setIsOpen]);

	return isOpen ? (
		<div
			aria-modal={true}
			role="dialog"
			className="tab-focus-trap w-full h-full"
			ref={modalRef}
			tabIndex={0}
		>
			{children}
		</div>
	) : (
		<></>
	);
};

export default TabFocusTrap;
