import { UserAvatar } from "components/user-avatar";
import { AuthContext } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { genConfig } from "funifier-nice-avatar";
import { useContext, useEffect, useState } from "react";
import useAnimateNumber from "react-hook-animate-number";
import { AiFillStar } from "react-icons/ai";
import { FaImage, FaInfoCircle, FaShareAlt } from "react-icons/fa";
import { TfiMoney } from "react-icons/tfi";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import BB_ROUTES from "routes/const";
import tippy from "tippy.js";
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/backdrop.css";
import "tippy.js/dist/tippy.css";
import "./style.scss";

import coinAnimate from "assets/lottie/coinAnimate.json";
import Lottie from "lottie-react";
import { VerifyAcceptTerms } from "./VerifyAcceptTerms";

const defaultConfig = genConfig();

const UserInfo = () => {
  const navigate = useNavigate();
  const { user, team, badges } = useContext(AuthContext);

  const { showModal } = useModal();

  const [avatarConfig, setAvatarConfig] = useState(defaultConfig);

  const [stars, setStars] = useState(0);
  const [coin, setCoins] = useState(0);

  const coinNumber = useAnimateNumber({
    number: coin,
    durationInMs: 4000,
    debug: true,
  });

  const starNumber = useAnimateNumber({
    number: stars,
    durationInMs: 4000,
    debug: true,
  });

  useEffect(() => {
    if (user) {
      setCoins(user?.point_categories.coin);
      setStars(user?.point_categories.star);
    }
  }, [user, user?.point_categories.coin, user?.point_categories.star]);

  function handleAvatarClick() {
    showModal("avatar", { config: avatarConfig });
  }

  function handleImageSocialMediaClick() {
    showModal("image-social-media", {});
  }

  function verifyActive(badge: string) {
    if (!user || !user.challenge_progress?.includes({ _id: badge })) {
      return "incomplet";
    }
    return "";
  }

  tippy.setDefaultProps({ delay: 50 });
  tippy("#user-avatar", {
    content: "Alterar imagem de perfil",
    arrow: false,
    theme: team,
  });

  tippy(".has-tip", {
    arrow: true,
    theme: team,
  });

  return (
    <section
      className={` user-info flex items-center md:items-start border rounded-xl z-[200] relative ${
        team == "agente-negocial" ? `border-purple-100` : `border-blue-300`
      } shadow-md px-3 lg:px-6 w-full mb-4  lg:mb-0 lg:w-[56%] relative text-white ${
        user?.extra.perfil == "agente-ti" ? "bg-blue" : "bg-purple"
      }`}
    >
      <div className="flex flex-col md:flex-row justify-between w-full">
        <div className="flex lg:pt-7 px-5 py-5">
          <div>
            <div
              id="user-avatar"
              onClick={handleAvatarClick}
              tabIndex={0}
              aria-label="Mudar avatar"
              className="border border-yellow p-1 rounded-full flex items-center justify-center mr-3 pointer flex-col cursor-pointer w-16 h-16"
            >
              <UserAvatar
                data={{ ...user, ...user?.extra }}
                responsive={true}
              />
            </div>
          </div>
          <div>
            <h5 className="md:text-xl font-bold mb-1 user-name text-base lg:text-lg">
              {user?.name}
            </h5>

            <p
              className="text-sm font-light flex flex-col lg:flex-row gap-1"
              tabIndex={0}
            >
              Nível:{" "}
              <strong
                className="text-yellow"
                tabIndex={0}
                aria-label={`Seu nível é ${
                  !!user?.level && !!user?.level.level ? user?.level.level : 0
                }`}
              >
                {user?.level ? user?.level.level : 0}
              </strong>
              {user?.level.position >= 5 && (
                <>
                  {user?.level.position >= 5 && (
                    <>
                      <a
                        className="cursor-pointer hover:text-white flex gap-[0.2rem] items-center"
                        onClick={() => {
                          handleImageSocialMediaClick();
                        }}
                      >
                        <FaShareAlt size={18} className="ml-2" /> Card
                        Conquistador.
                      </a>
                    </>
                  )}
                </>
              )}
            </p>

            <p className="text-[16px] font-light mb-2 flex items-center">
              <span className="block w-[90%] h-[10px] rounded-full bg-[#CCCCCC] mr-2">
                <span
                  className={`block h-[10px] rounded-full bg-[#FCFC30] transition`}
                  style={{ width: `${user?.level_progress.percent}%` }}
                  tabIndex={0}
                  aria-label={
                    user?.level_progress.next_level
                      ? `Faltam ${user?.level_progress.next_points} estrelas para o nível - ${user?.level_progress.next_level.level}`
                      : "Você esta no nível máximo"
                  }
                  title={
                    user?.level_progress.next_level
                      ? `${user?.level_progress.percent}% - Faltam ${user?.level_progress.next_points} estrelas para o nível - ${user?.level_progress.next_level.level}`
                      : `${user?.level_progress.percent}%`
                  }
                ></span>
              </span>
              {!!user?.level_progress.next_level && (
                <span aria-hidden={true} tabIndex={-1}>
                  <FaInfoCircle
                    aria-hidden={true}
                    className="has-tip"
                    data-tippy-content={
                      user?.level_progress.next_level
                        ? `Faltam ${user?.level_progress.next_points} estrelas para o nível - ${user?.level_progress.next_level.level}`
                        : "Você esta no nível máximo"
                    }
                  />
                </span>
              )}
            </p>
            <div className="flex mt-3 md:mt-0">
              <button
                className="flex font-light mr-4 items-center"
                onClick={() => navigate(BB_ROUTES.EXTRATO())}
                aria-label={`Você tem ${starNumber.number} estrelas. Ir para o extrato`}
              >
                <AiFillStar color="#FCFC30" size={18} aria-hidden={true} />{" "}
                <span
                  className="underline text-left ml-1 text-[12px]"
                  aria-hidden={true}
                >
                  {starNumber.number} estrelas
                </span>
              </button>

              <button
                className="flex font-light  items-center"
                onClick={() => navigate(BB_ROUTES.EXTRATO())}
                aria-label={`Você tem ${coinNumber.number} moedas. Ir para o extrato`}
              >
                <div
                  aria-hidden={true}
                  className={`bg-[#FCAD30] rounded-full h-[18px] w-[18px] p-1 flex items-center justify-center mr-1`}
                >
                  <TfiMoney color="#735CC6" size={20} />{" "}
                </div>

                <span
                  className="underline text-left text-[12px]"
                  aria-hidden={true}
                >
                  {coinNumber.number} moedas
                </span>
              </button>
            </div>
          </div>
        </div>

        <div
          className="resume resume-badges pt-[30px] pb-[17px]
          "
          onClick={() => {
            navigate(BB_ROUTES.BADGES());
          }}
        >
          <div className="lg:grid lg:grid-cols-4 flex gap-3 mb-3">
            {badges.map((i: any) => (
              <div
                key={i._id}
                tabIndex={0}
                className={`mx-auto my-0 ${
                  i.completed ? "completed" : "incomplet"
                }`}
              >
                {i.badge?.medium?.url ? (
                  <img
                    src={i.badge?.medium?.url}
                    alt="badge"
                    aria-label={`Badge ${i.challenge} - ${
                      i.completed ? "Conquistado" : "Não conquistado"
                    }`}
                  />
                ) : (
                  "--"
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center">
            <Link
              to={BB_ROUTES.BADGES()}
              className="underline hover:no-underline text-center hover:text-white"
            >
              Ver todos os badges
            </Link>
          </div>
        </div>

        {/* <Tip /> */}
      </div>
      <VerifyAcceptTerms wait={1000} />
    </section>
  );
};

export default UserInfo;
