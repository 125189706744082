import { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as Like } from '../../../assets/img/like.svg';
import { ReactComponent as Unlike } from '../../../assets/img/unlike.svg';
import { getNameSetor } from 'utils/constants';

import './style.scss';
import { getIdea, likeIdea } from 'services/ideia.service';
import { toast } from 'react-toastify';
import { AuthContext, useAuth } from 'context/AuthContext';
import AvaliationsIdea from 'components/AvaliationsIdea';
import { findLast } from 'utils/helpers';
import { BsPencil } from 'react-icons/bs';
import { useModal } from 'context/ModalContext';
import moment from 'moment';

export default function HeaderIdeiasPage(props: any) {
  const {
    setShowModalAddIdeia,
    showModalAddIdeia,
    setModalData,
    modalData,
    refreshListIdeias,
  } = useModal();
  const selectedIdea = useRef<HTMLDivElement>(null);
  const [dataIdeaSelected, setDataIdeaSelected] = useState<any>({});
  const [showIdea, setShowIdea] = useState(false);
  const [loading, setLoading] = useState<any>({
    like: false,
  });
  const [allowEdit, setAllowEdit] = useState(false);
  const [isPlayer, setIsPlayer] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const { contrast } = useAuth();

  useEffect(() => {
    setAllowEdit(false);
    if (props.id) {
      const verifyExist = props.list.find((e: any) => e._id === props.id);

      if (verifyExist) {
        setIsPlayer(true);
        setDataIdeaSelected(verifyExist);

        const isMy = verifyExist.player.filter((item: any) => {
          return item._id == user?._id;
        });

        if (!!isMy && !!isMy[0] && verifyExist.evaluateCount <= 0) {
          setAllowEdit(true);
        }

        setShowIdea(true);

        if (!!selectedIdea && !!selectedIdea.current) {
          selectedIdea.current.querySelector(`h3`)?.focus();
        }
      }
    }
  }, [props]);

  useEffect(() => {
    if (!!refreshListIdeias && refreshListIdeias) {
    }
  }, [refreshListIdeias]);

  function like() {
    const verifyIsLike = dataIdeaSelected.likes.find(
      (e: any) => e.userId === user?._id
    );
    if (verifyIsLike) {
      return false;
    }

    setLoading({ ...loading, like: true });
    const payload = {
      actionId: 'like_idea',
      userId: 'me',
      attributes: {
        id_idea: dataIdeaSelected._id,
        from_player: user && user._id,
      },
    };
    likeIdea(payload)
      .then((resp) => {
        if (!resp.errorCode) {
          toast.success('Você curtiu essa ideia. 👍🏻');
          dataIdeaSelected.likes.push(resp);
        } else {
          toast.error(
            'Não foi possível curtir essa ideia, tente novamente mais tarde!'
          );
        }
      })
      .finally(() => {
        setLoading({ ...loading, like: false });
      });
  }

  const onEdit = () => {
    setModalData(dataIdeaSelected);
    setShowModalAddIdeia(true);
  };

  function downloadFile() {
    fetch(dataIdeaSelected.file)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `ideia_${dataIdeaSelected._id}`;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      })
      .catch((error) => {
        toast.error('Erro ao baixar o arquivo:');
      });
  }

  function nameThemes(id: string) {
    const theme = props.themes.find((e: any) => e._id === id);
    return theme ? theme.title : '--';
  }

  const IdeiaInteraction = () => {
    return (
      <>
        <div className="hidden lg:flex justify-between w-full items-center">
          <div className="flex ">
            <div className="curtidas flex mr-4">
              <button
                disabled={loading.like}
                onClick={like}
                tabIndex={0}
                aria-label={`Essa idéia tem ${
                  dataIdeaSelected.likes.filter(
                    (e: any) => e.actionId === 'like_idea'
                  )?.length
                } curtidas ${
                  dataIdeaSelected.likes.find(
                    (e: any) => e.userId === user?._id
                  )
                    ? 'Você já curtiu a idéia'
                    : 'Curtir idéia'
                }`}
              >
                <figure>
                  {dataIdeaSelected.likes.find(
                    (e: any) => e.userId === user?._id
                  ) ? (
                    <Like />
                  ) : (
                    <Unlike />
                  )}
                </figure>
                <span className="mx-2">
                  {
                    dataIdeaSelected.likes.filter(
                      (e: any) => e.actionId === 'like_idea'
                    )?.length
                  }{' '}
                  curtida
                  {dataIdeaSelected.likes.filter(
                    (e: any) => e.actionId === 'like_idea'
                  )?.length > 1 && 's'}
                </span>
              </button>
            </div>
            <div
              className="avalie flex items-center"
              tabIndex={0}
              aria-label={`Avaliação média da idéia é de ${
                dataIdeaSelected.last_evaluate?.attributes?.grade || 0
              } de 5`}
            >
              <AvaliationsIdea
                qtd={dataIdeaSelected.last_evaluate?.attributes?.grade || 0}
              />
            </div>
          </div>
          <div className="ranking">
            <div
              tabIndex={0}
              className="ranking-item"
              aria-label={` Idéia na posição ${dataIdeaSelected.position}º do ranking da comunidade`}
              role="contentinfo"
            >
              <p aria-hidden={true} tabIndex={-1}>
                <strong>Ranking da Comunidade</strong>{' '}
                {dataIdeaSelected.position}º lugar
              </p>
            </div>
          </div>
        </div>

        <div className="block lg:hidden">
          <div className="flex justify-between w-full mb-[22px]">
            <div className="">
              <button
                disabled={loading.like}
                onClick={like}
                className="flex cursor-pointer"
              >
                <figure>
                  {dataIdeaSelected.likes.find(
                    (e: any) => e.userId === user?._id
                  ) ? (
                    <Like />
                  ) : (
                    <Unlike />
                  )}
                </figure>
                <span className="mx-2 underline">
                  {
                    dataIdeaSelected.likes.filter(
                      (e: any) => e.actionId === 'like_idea'
                    )?.length
                  }{' '}
                  curtida
                  {dataIdeaSelected.likes.filter(
                    (e: any) => e.actionId === 'like_idea'
                  )?.length > 1 && 's'}
                </span>
              </button>
            </div>
            <div className="avalie flex items-center">
              <AvaliationsIdea
                qtd={dataIdeaSelected.last_evaluate?.attributes?.grade || 0}
              />
            </div>
          </div>
          <div className="ranking flex items-center w-full">
            <div className="ranking-item">
              <p>
                <strong>Ranking da Comunidade</strong>{' '}
                {dataIdeaSelected.position}º lugar
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isPlayer && showIdea && (
        <section className="ideia-selected" ref={selectedIdea}>
          <article
            className={` ${
              contrast ? 'bg-black border-yellow border' : 'bg-white'
            } shadow-md p-7 trail-card flex flex-col lg:flex-row mb-[18px] rounded-[10px] relative transition`}
          >
            <figure className="avatar mx-auto mt-0 mb-0">
              <img
                aria-hidden={true}
                tabIndex={-1}
                src={
                  dataIdeaSelected.image
                    ? dataIdeaSelected.image
                    : require('../../../assets/img/user.png')
                }
                alt="Imagem Ideia"
                title="Sem imagem"
              />
            </figure>
            <div
              className={`content-ideia px-7 ${
                contrast ? 'text-yellow' : 'text-gray-300'
              } relative`}
            >
              <p className="text-base flex items-center justify-between">
                <h3 className="font-bold" tabIndex={0}>
                  {dataIdeaSelected.title}
                </h3>
                {allowEdit && (
                  <button
                    className={`flex ${
                      contrast ? 'bg-black text-yellow' : `text-white bg-purple`
                    }  rounded-[20px] items-center ml-3  text-sm px-3 py-1 translate-x-3 hover:opacity-80`}
                    onClick={onEdit}
                    aria-label="Editar idéia"
                  >
                    <BsPencil
                      className="text-white"
                      aria-hidden={true}
                      tabIndex={-1}
                    />{' '}
                    <span className="ml-1">Editar</span>
                  </button>
                )}
              </p>
              <p tabIndex={0}>{dataIdeaSelected.content}</p>
              <p tabIndex={0}>
                Autor:{' '}
                <strong>
                  {dataIdeaSelected.player[0]?.name} (
                  {getNameSetor(dataIdeaSelected.player[0].teams || [])})
                </strong>
              </p>
              <p tabIndex={0}>
                Tema: <strong>{nameThemes(dataIdeaSelected.theme)}</strong>
              </p>
              {dataIdeaSelected.participants &&
                dataIdeaSelected.participants.length > 0 && (
                  <div className="comment pt-7">
                    <h4>
                      <strong>Participante(s):</strong>
                    </h4>
                    <div
                      className="flex"
                      aria-label={dataIdeaSelected.participants
                        .map((item: any) => item.label)
                        .join(', ')}
                      role="contentinfo"
                    >
                      {dataIdeaSelected.participants
                        .map((item: any) => item.label)
                        .join(', ')}
                    </div>
                  </div>
                )}
              {typeof dataIdeaSelected.file === 'string' &&
                dataIdeaSelected.file !== '' && (
                  <button
                    style={{
                      padding: '0.3rem 2rem',
                      marginTop: '1rem',
                      borderRadius: '20px',
                      backgroundColor: 'rgb(115, 92, 198)',
                      color: '#fff',
                    }}
                    onClick={downloadFile}
                  >
                    Fazer download de arquivo
                  </button>
                )}

              <i className="mt-2 block">
                Criada em:{' '}
                {moment(dataIdeaSelected.created_at).format('DD/MM/YYYY HH:mm')}
              </i>

              {dataIdeaSelected.evaluates &&
                dataIdeaSelected.evaluates.length > 0 &&
                dataIdeaSelected.evaluates[
                  dataIdeaSelected.evaluates.length - 1
                ]?.attributes?.comment && (
                  <div
                    className="comment pt-7"
                    tabIndex={0}
                    aria-label={`Comentário da área gestora: ${
                      dataIdeaSelected.evaluates[
                        dataIdeaSelected.evaluates.length - 1
                      ]?.attributes?.comment
                    }`}
                  >
                    <p tabIndex={-1} aria-hidden={true}>
                      <strong>Comentário da área gestora:</strong>
                    </p>
                    <p tabIndex={-1} aria-hidden={true}>
                      {
                        dataIdeaSelected.evaluates[
                          dataIdeaSelected.evaluates.length - 1
                        ]?.attributes?.comment
                      }
                    </p>
                  </div>
                )}
              <hr className={contrast ? 'border-yellow' : 'border-gray-300'} />
              <div className="actions-ideia pt-7 grid lg:flex justify-between	items-center	">
                <IdeiaInteraction />
              </div>
            </div>
          </article>
        </section>
      )}
    </>
  );
}
