import TabFocusTrap from "components/TabFocusTrap";
import { useModal } from "context/ModalContext";
import DialogItem from "pages/Board/DialogItem";
import { useEffect, useState } from "react";
import ModalBoardData from "utils/modal-board-data";

const ModalBoard = () => {
  const { showModalBoard, setShowModalBoard, setModalData, modalData } =
    useModal();
  const [showDialog, setShowDialog] = useState(false);

  const [dialogData, setDialogData] = useState<ModalBoardData>();

  useEffect(() => {
    if (modalData) {
      addContentId();
      setDialogData(modalData);
    }
  }, [modalData]);

  useEffect(() => {
    if (!showModalBoard) {
      removeContentId();
    }
  }, [showModalBoard]);

  function addContentId() {
    if (modalData.dialogContent || modalData.config) {
      const url = new URL(String(window.location));
      url.searchParams.set(
        "contentId",
        modalData.dialogContent
          ? modalData.dialogContent._id
          : modalData.config._id
      );
      window.history.pushState({}, "", url);
    }
  }

  function removeContentId() {
    const url = new URL(String(window.location));
    url.searchParams.set("contentId", "");
    window.history.pushState({}, "", url);
  }

  return (
    <>
      <TabFocusTrap
        startElement="h3"
        isOpen={showModalBoard}
        setIsOpen={() => setShowModalBoard(false)}
      >
        <div
          className={`fixed z-[999] top-0 left-0 bg-black bg-opacity-60 flex flex-col items-center w-full h-full p-6`}
        ></div>
        <div
          className="board-dialog fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[9999] w-4/5"
          aria-modal="true"
        >
          <DialogItem />
        </div>
      </TabFocusTrap>
    </>
  );
};

export default ModalBoard;
