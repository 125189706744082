import { useContext, useEffect, useState } from "react";
import ProgressBar from "../../ProgressBar";

import { ProgressInfo } from "classes/progress.interface";
import TrailInfo from "classes/trail.interface";
import { AuthContext } from "context/AuthContext";
import "./style.scss";

interface Options {
	item: TrailInfo;
	percent: number;
	onclick: () => void;
}

const TrailCard = ({ item, percent, onclick }: Options) => {
	const { user, isAuthenticated, contrast } = useContext(AuthContext);
	const [blockedItem, setBlockedItem] = useState(false);
	const [progress, setProgress] = useState<ProgressInfo>();
	const [expectedDate, setExpectedDate] = useState<any>();

	return (
		<div
			aria-label={`${item.title} - ${Math.round(percent)}% concluído`}
			role="button"
			onClick={onclick}
			className={`${
				contrast ? `bg-transparent border-yellow border` : "bg-white"
			} trail-card flex mb-[18px] rounded-[10px] cursor-pointer relative transition hover:opacity-80`}
		>
			<div
				className="bar h-full w-5 absolute top-0 left-0 rounded-tl-[10px] rounded-bl-[10px]"
				style={{
					background: item.extra.color?.includes("#")
						? item.extra.color
						: `#${item.extra.color}`,
				}}
			/>
			<div className="py-7 px-10 w-full" role="link">
				<h3
					className={`${
						contrast ? "text-yellow" : "text-gray-300"
					} font-normal  mb-[14px] text-xl lg:text-3xl`}
					tabIndex={0}
					aria-label={item.title}
				>
					{item.title}
				</h3>
				<div
					tabIndex={item.extra.content ? 0 : -1}
					aria-hidden={item.extra.content ? false : true}
					aria-label={item.extra.content}
					className={`${
						contrast ? "text-yellow" : "text-gray-300"
					} font-normal text-xs lg:text-sm leading-4`}
					dangerouslySetInnerHTML={{ __html: item.extra.content }}
				/>
				<div className="mt-7">
					<ProgressBar
						color={
							contrast
								? "#fcfc30"
								: item.extra.color?.includes("#")
									? item.extra.color
									: `#${item.extra.color}`
						}
						percent={percent || 0}
					/>
				</div>
			</div>
		</div>
	);
};

export default TrailCard;
