import BreadCrumb from 'components/BreadCrumb';
import { AuthContext } from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import './style.scss';
import HeaderGestor from 'components/HeaderGestor';

export interface ElementPros {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  [key: string]: any;
}
const LayoutGestor = ({ children, team }: ElementPros) => {
  const { breadcrumbItens, isAuthenticated, user, isSidebar, pageTitle } =
    useContext(AuthContext);

  return (
    <>
      <div
        className={`w-full wrapper-app px-9 md:px-0 ${
          !isAuthenticated ? 'no-logged' : 'logged'
        } ${team ? team : 'bg-[#735CC6] h-full min-h-[100vh]'}`}
      >
        {isAuthenticated ? (
          <>
            <HeaderGestor />
            <main className="flex w-full">
              <div className="container mt-0 md:mt-7 mb-0 mx-auto z-[300]">
                {!!breadcrumbItens && breadcrumbItens.length > 1 && (
                  <div className="">
                    <BreadCrumb itens={breadcrumbItens} />
                  </div>
                )}

                <div
                  className={`flex flex-col lg:flex-row justify-between w-full mt-[80px]`}
                >
                  <div className="w-full">
                    {!!pageTitle && (
                      <h2 className="text-yellow font-bold lg:text-4xl text-xl border-b border-yellow pb-1 relative mt-0 text-center lg:text-left mb-5">
                        {pageTitle}
                      </h2>
                    )}
                    {children}
                  </div>
                </div>
              </div>
            </main>
          </>
        ) : (
          <>{children}</>
        )}
      </div>
    </>
  );
};

export default LayoutGestor;
