import { useEffect, useState } from 'react';
import Box from '../Box';
import { AiFillStar } from 'react-icons/ai';
import { RankingConhecimentoCompleto } from 'services/ideia.service';
import { useAuth } from 'context/AuthContext';
import './style.scss';
import { getNameSetor } from 'utils/constants';
import { UserAvatar } from 'components/user-avatar';
import { useNavigate } from 'react-router';
import BB_ROUTES from 'routes/const';

const Ranking = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [myPosition, setMyPosition] = useState<any>({});
  const { user, contrast } = useAuth();

  useEffect(() => {
    if (user?.teams) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    if (!!data && data.length > 0) {
      getMyPosition();
    }
  }, [data, user]);

  function getData() {
    RankingConhecimentoCompleto(user?.teams).then((resp: any) => {
      setData(resp);
    });
  }

  function getMyPosition() {
    if (user && data.length > 0) {
      data.some((e: any, index) => {
        if (e.p?._id === user._id && e.position > 3) {
          setMyPosition(e);
          return true;
        }
        return false;
      });
    }
  }

  return (
    <Box
      title={<>Ranking do conhecimento</>}
      ctaText="Ranking completo"
      path={'/ranking-conhecimento'}
      layoutClass="border border-white box-ranking"
      customClass="ranking"
      children={
        <>
          <div className="list">
            {!!data &&
              data.length > 0 &&
              data?.map(
                (e: any, index) =>
                  index <= 2 && (
                    <article
                      className="flex item-position items-center mb-7"
                      key={e._id}
                      tabIndex={0}
                      role="contentinfo"
                      aria-label={`${e.position}º LUGAR ${
                        e.name
                      } ${getNameSetor(e.p?.teams)} com ${e.total} estrela${
                        e.total > 1 ? 's' : ''
                      }`}
                    >
                      <div
                        className="thumb mr-3 w-[30%] max-w-[80px] h-[80px] text-left"
                        aria-hidden={true}
                        tabIndex={-1}
                      >
                        {e.p.extra.avatar ? (
                          <UserAvatar
                            data={{ ...e.p, ...e.p.extra }}
                            responsive={true}
                          />
                        ) : (
                          <span aria-hidden={true} tabIndex={-1}>
                            {e.p.image ? (
                              <img
                                aria-hidden={true}
                                tabIndex={-1}
                                src={e.p?.image?.small?.url}
                                alt="Imagem user"
                              />
                            ) : (
                              <img
                                aria-hidden={true}
                                tabIndex={-1}
                                src={require('../../../assets/img/user.png')}
                                alt="Imagem user"
                              />
                            )}
                          </span>
                        )}
                      </div>
                      <div
                        className="w-[70%] text-left"
                        aria-hidden={true}
                        tabIndex={-1}
                      >
                        <p className="text-yellow uppercase text-xs font-bold mb-1">
                          {e.position}º LUGAR
                        </p>
                        <h5
                          className={`text-xs ${
                            contrast ? 'text-yellow' : `text-white`
                          } font-medium`}
                        >
                          {e.name}
                        </h5>
                        <p
                          className={`text-[10px]  ${
                            contrast ? 'text-yellow' : `text-white`
                          } font-normal`}
                        >
                          {getNameSetor(e.p?.teams)}
                        </p>
                        <div className="flex">
                          <button
                            className="flex font-light mr-4 text-[10px] items-center cursor-default"
                            aria-hidden={true}
                            tabIndex={-1}
                          >
                            <AiFillStar color="#FCFC30" size={18} />{' '}
                            <span
                              className={
                                contrast ? 'text-yellow' : `text-white`
                              }
                            >
                              {e.total} estrela{e.total > 1 ? 's' : ''}
                            </span>
                          </button>
                        </div>
                      </div>
                    </article>
                  )
              )}
          </div>
          {myPosition.position && (
            <>
              <p className="text-yellow uppercase text-xs font-bold mb-1">
                ...
              </p>
              <div
                className="my-position border-t border-yellow border-b pb-5"
                tabIndex={0}
                role="button"
                onClick={() => navigate(BB_ROUTES.EXTRATO())}
                aria-label={`Minha posição é ${
                  myPosition.position
                }º LUGAR com ${myPosition.total} 
                          ${myPosition.total > 1 ? 'estrelas' : 'estrela'}`}
              >
                <div className="bg-yellow rounded-bl-lg rounded-br-lg py-2 px-2 text-center w-28">
                  <span className="text-gray-300 text-xs">Sua posição</span>
                </div>
                <article className="flex item-position items-center mt-4">
                  <div
                    className="thumb mr-3 w-[30%] max-w-[80px] h-[80px] text-left"
                    tabIndex={-1}
                    aria-hidden={true}
                  >
                    {myPosition.p.extra.avatar ? (
                      <UserAvatar
                        data={{ ...myPosition.p, ...myPosition.p.extra }}
                        responsive={true}
                      />
                    ) : (
                      <span>
                        {myPosition.p.image ? (
                          <img
                            tabIndex={-1}
                            aria-hidden={true}
                            src={myPosition.p?.image?.small?.url}
                            alt="Imagem user"
                          />
                        ) : (
                          <img
                            tabIndex={-1}
                            aria-hidden={true}
                            src={require('../../../assets/img/user.png')}
                            alt="Imagem user"
                          />
                        )}
                      </span>
                    )}
                  </div>
                  <div
                    className="w-[70%] text-left"
                    aria-hidden={true}
                    tabIndex={-1}
                  >
                    <p
                      className="text-yellow uppercase text-xs font-bold mb-1"
                      tabIndex={-1}
                      aria-hidden={true}
                    >
                      {myPosition.position}º LUGAR
                    </p>

                    <h5
                      className={`text-xs ${
                        contrast ? 'text-yellow' : `text-white`
                      } font-medium`}
                      tabIndex={-1}
                      aria-hidden={true}
                    >
                      {myPosition?.name}
                    </h5>
                    <p
                      className={`text-[10px]  ${
                        contrast ? 'text-yellow' : `text-white`
                      } font-normal`}
                      tabIndex={-1}
                      aria-hidden={true}
                    >
                      {getNameSetor(myPosition.p?.teams)}
                    </p>
                    <div className="flex">
                      <button
                        className="flex font-light mr-4 text-[10px] items-center"
                        tabIndex={-1}
                        aria-hidden={true}
                      >
                        <AiFillStar color="#FCFC30" size={18} />{' '}
                        <span
                          className={`underline ${
                            contrast ? 'text-yellow' : `text-white`
                          }`}
                        >
                          {myPosition.total} estrela
                          {myPosition.total > 1 ? 's' : ''}
                        </span>
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </>
          )}
        </>
      }
    ></Box>
  );
};

export default Ranking;
