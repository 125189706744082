/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from "context/AuthContext";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { getToken } from "utils/tokenCookies";

interface WebsocketContextData {
  // connect: () => void;
  // disconnect: () => void;
  sendMessage: (message: any) => void;
  setMessage: (data: any | undefined) => void;
  message: any;
}

interface WebsocketContextProps {
  children: ReactNode;
}


export const WebsocketContext = createContext({} as WebsocketContextData);

function WebsocketNotificationProvider({ children }: WebsocketContextProps) {
  const [client, setClient] = useState<any>();
  const [message, setMessage] = useState<any>();
  const { refreshUser } = useAuth();

  function connect() {
    try {
      if (client) {
        client.close();
      }
      const token = getToken();
      if (token) {
        const socketClient = new WebSocket(
          `wss://bb-service2.funifier.com/ws/notification?authorization=${encodeURIComponent(
            token
          )}`
        );
        setClient(socketClient);
      }
    } catch (e) {
      console.log(e);
      disconnect();
    }
  }

  function disconnect() {
    if (client) {
      client.close();
    }
    setClient(undefined);
  }

  function onMessage(e: MessageEvent) {
    setMessage(JSON.parse(e.data));
    // toast(
    // 	`💎 ${
    // 		"Você acaba de receber um reconhecimento"}`,
    // 	{
    // 		position: "top-right",
    // 		autoClose: false,
    // 		hideProgressBar: true,
    // 		closeOnClick: true,
    // 		pauseOnHover: true,
    // 		draggable: true,
    // 		progress: undefined,
    // 	}
    // );

    refreshUser();
    // dispatchEvent(new CustomEvent("websocket-message", { detail: JSON.parse(e.data) }));
  }

  function sendMessage(message: any) {
    if (!client) return;
    client.send(JSON.stringify(message));
  }

  useEffect(() => {
    connect();
  }, []);

  useEffect(() => {
    if (client) {
      client.onmessage = onMessage;
    }
    return () => {
      if (client) {
        client.onmessage = null;
      }
    };
  }, [client]);

  return (
    <WebsocketContext.Provider
      value={{
        sendMessage,
        message,
        setMessage,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
}

function useWebsocketNotification() {
  const context = useContext(WebsocketContext);
  if (context === undefined) {
    throw new Error(
      "useWebsocketNotification must be used within a WebsocketProvider"
    );
  }
  return context;
}

export { WebsocketNotificationProvider, useWebsocketNotification };
