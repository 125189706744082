import { useAuth } from "context/AuthContext";
import { DialogContent } from "utils/modal-board-data";
import { FC } from "react";
import { AiFillStar } from "react-icons/ai";
import { TiTimes } from "react-icons/ti";

interface Option {
  content?: DialogContent;
  onEvent: (e?: any) => void;
  onClose: () => void;
}

const DialogDocument: FC<Option> = ({ content, onEvent, onClose }: Option) => {
  const { contrast } = useAuth();

  function openDocument() {
    onEvent({ skip: false });
    if (content) {
      window.open(content?.file?.url, "_blank");
    }
  }

  return (
    <div
      key={`video-${new Date().getTime()}`}
      className={`min-h-[100vmin] max-h-[95vh] md:min-h-0 overflow-y-auto flex flex-col ${
        contrast ? "bg-black border-yellow border-2" : "bg-white "
      } rounded-xl p-1 md:p-5 lg:rounded-3xl w-11/12 lg:w-5/6 dialog-document'
      }`}
    >
      <div className="flex justify-end items-end mb-3">
        <button
          onClick={onClose}
          className="rounded-full "
          tabIndex={0}
          aria-label="Fechar modal de documento"
        >
          <TiTimes
            color={contrast ? "#fcfc30" : "#69696E"}
            size={25}
            aria-hidden={true}
          />
        </button>
      </div>
      <div className="stars mb-4 flex items-center justify-between">
        <h3
          className={`font-bold px-4 md:p-0 leading-tight md:text-2xl ${
            contrast ? "text-yellow" : "text-black"
          }`}
          tabIndex={0}
        >
          {content?.title}
        </h3>
        <div className="flex" aria-hidden={true}>
          {Array.from({ length: content?.star || 0 }).map((i: any) => (
            <div className="mr-1">
              <AiFillStar color="#FCFC30" size={"25"} />
            </div>
          ))}
        </div>
      </div>
      <div className="flex w-full mb-9">
        {!!content?.image && (
          <div className="mr-6">
            <img src={content?.image} alt="" className="rounded-[10px]" />
          </div>
        )}
        <>
          <div
            className={`flex flex-col flex-1 items-center justify-center content ${
              content?.image ? "max-w-[45%]" : "w-full"
            }`}
            aria-label={content?.introContent ? content?.introContent : ""}
            tabIndex={content?.introContent ? 0 : -1}
            aria-hidden={content?.introContent ? false : true}
          >
            <p className="font-light text-xl text-gray-300">
              {content?.introContent}
            </p>
          </div>
        </>
      </div>

      {content?.content && (
        <div
          className={`text font-light ${
            contrast ? "text-yellow" : "text-gray-300"
          } text-base mb-6`}
          dangerouslySetInnerHTML={{ __html: content?.content }}
          tabIndex={0}
        ></div>
      )}
      <div className="flex items-center justify-center">
        <button
          className={` ${
            contrast
              ? "border-2 border-yellow bg-black text-yellow"
              : "bg-blue text-white"
          } p-3 text-center text-base rounded-[10px]`}
          onClick={() => openDocument()}
          tabIndex={0}
          aria-label={"Ver documento,marcar como concluído e Fechar modal"}
        >
          Ver documento
        </button>
      </div>
    </div>
  );
};

export default DialogDocument;
