import { AuthContext } from "context/AuthContext";
import React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { BreadCrumbItens } from "types/navigation";

const BreadCrumb: FC<any> = ({ itens }: any) => {
	const [data, setData] = useState<Array<BreadCrumbItens>>([]);
	const navigate = useNavigate();

	useEffect(() => {
		setData(itens);
	}, [itens]);

	return (
		<>
			<ul
				className={`flex items-center lg:items-start p-2 relative z-40 breadcrumb mb-[22px]`}
			>
				{data?.map((item: BreadCrumbItens, index: number) => (
					<React.Fragment key={`${item.name}_${index}`}>
						{!!item && item.name !== "undefined" && (
							<li
								className="flex items-center text-center lg:text-left lg:items-start"
								tabIndex={0}
								aria-label={
									!!item.name && item.name !== "undefined"
										? `Breadcrumb ${item.name}`
										: ""
								}
								role="link"
								onClick={(e) => {
									navigate(String(item.path));
								}}
							>
								<span
									className={`${
										item.isFirst ? "text-yellow" : "text-white"
									} text-xs lg:text-sm font-normal cursor-pointer `}
								>
									{!!item.name && item.name !== "undefined" ? item.name : ""}
								</span>
							</li>
						)}
					</React.Fragment>
				))}
			</ul>
		</>
	);
};

export default BreadCrumb;
