import ChallengeInfo from "classes/challenge";
import ModuleInfo from "classes/module.interface";
import { ProgressInfo } from "classes/progress.interface";
import { Settings } from "classes/settings.interface";
import TrailInfo from "classes/trail.interface";
import TrailCard from "components/Card/TrailCard";
import ProgressBar from "components/ProgressBar";
import Skeleton from "components/Skeleton";
import UserProgress from "components/UserProgress";
import { UserAvatar } from "components/user-avatar";
import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { AiFillHeart, AiFillStar } from "react-icons/ai";
import { FaChevronLeft } from "react-icons/fa";
import { TfiMoney } from "react-icons/tfi";
import { getChallenges } from "services/challenges.service";
import {
	getContent,
	getContentAdmin,
	getProgress,
	getSingleTrail,
} from "services/elarning.service";
import {
	RankingConhecimentoCompleto,
	getEquipeGestor,
	ranking,
} from "services/ideia.service";
import { getNameSetor } from "utils/constants";
import "../style.scss";

import "./style.scss";

import AvaliationsIdea from "components/AvaliationsIdea";
import { HiLightBulb, HiOutlineLightBulb } from "react-icons/hi";
import { toast } from "react-toastify";

const AdminMinhaEquipe = () => {
	const { user, badges } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [loadingModules, setLoadingModules] = useState(false);
	const [loadingIdeaPlayer, setLoadingIdeaPlayer] = useState(false);
	const [dataEquipe, setDataEquipe] = useState<any>([]);
	const [formFilter, setformFilter] = useState({
		search: "",
		matricula: "",
		equipe: "",
	});
	const [list, setList] = useState<Array<TrailInfo>>([]);
	const [totalProgress, setTotalProgress] = useState(0);
	const [totalPercent, setTotalPercent] = useState(0);
	const [expectedPercent, setExpectedPercent] = useState(0);

	const [globalSettings, setGlobalSettings] = useState<Settings>();
	const [detailPlayer, setDetailPlayer] = useState<any>({});
	const [module, setModule] = useState<Array<ModuleInfo>>([]);
	const [progress, setProgress] = useState<ProgressInfo>();
	const [openItem, setOpenItem] = useState(null);
	const [ideaPlayer, setIdeaPlayer] = useState<any>();
	const [challenges, setChallenges] = useState<any>([]);
	const [badgesList, setBadgesList] = useState<any>([]);
	const [myPosition, setMyPosition] = useState<any>();
	const [rankingBB, setRankingBB] = useState<any>();

	const handleItemClick = (item: any) => {
		if (openItem === item) {
			// Se o item clicado for o mesmo que já está aberto, feche-o
			setOpenItem(null);
		} else {
			// Se o item clicado for diferente do que está aberto, abra-o e feche o atual
			setOpenItem(item);
		}
	};

	function changeProgress(val?: any) {
		if (val.total === val.done) {
			const calc = 100 / list.length;

			setTotalProgress(calc);
			setTotalPercent(calc);
		}
	}

	const handleInputChange = (event: any) => {
		const { name, value } = event.target;
		setformFilter({ ...formFilter, [name]: value });
	};

	const handleSelectChange = (event: any) => {
		const { name, value } = event.target;
		setformFilter({ ...formFilter, [name]: value });
	};

	useEffect(() => {
		setLoading(true);
		if (user) {
			const { search, matricula, equipe } = formFilter;
			getEquipeGestor(user._id, search, matricula, equipe)
				.then((resp) => {
					setDataEquipe(resp);
				})
				.finally(() => {
					setTimeout(() => {
						setLoading(false);
					}, 300);
				});
		}
	}, [formFilter]);

	function getContentPlayer(player: any) {
		setLoading(true);
		getContentAdmin(returnTeamPlayer(player.teams[0]), "", player._id)
			.then((resp) => {
				if (resp.length > 0) {
					const criteria = {
						fase: player.extra.fase,
						especializacao: player.extra.especializacao,
						profile:
							returnTeamPlayer(player.teams[0]) === "agente-ti"
								? "tecnologia"
								: "negocio",
						area: player.extra.area,
					};

					const filteredData = resp.filter((trail: any) => {
						const itemExtra = trail.extra;
						const matchProfile =
							!itemExtra.profile || itemExtra.profile === criteria.profile;
						const matchEspecializacao =
							!itemExtra.especializacao ||
							itemExtra.especializacao === criteria.especializacao;
						const matchArea =
							!itemExtra.area || itemExtra.area === criteria.area;
						const matchFase =
							!itemExtra.fase || itemExtra.fase === criteria.fase;

						return (
							matchProfile && matchEspecializacao && matchArea && matchFase
						);
					});

					if (filteredData) {
						setList(
							filteredData.sort((a, b) => a.extra.position - b.extra.position),
						);

						const done = filteredData.reduce(
							(sum, item) => sum + Number(item.done),
							0,
						);
						const totalItens = filteredData.reduce(
							(sum, item) => sum + Number(item.total),
							0,
						);
						const pTotal = (done / totalItens) * 100;
						const t = Math.round(pTotal).toFixed(0);
						setTotalPercent(parseInt(t));
					}
				}
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 300);
			});
	}

	function verifyActive(player: any, badge: string) {
		if (!player || !player.challenge_progress?.includes({ _id: badge })) {
			return "incomplet";
		}
		return "";
	}

	function showMoreInfoPlayer(player: any) {
		setDetailPlayer(player);
		getContentPlayer(player);
	}

	function returnTeamPlayer(team: any) {
		let teamPlayer = "";
		if (team.includes("DRjWqyD")) {
			teamPlayer = "agente-ti";
		} else {
			teamPlayer = "agente-negocial";
		}
		return teamPlayer;
	}

	async function loadMoodules(trailId: string) {
		setLoadingModules(true);
		setModule([]);

		const data = await getContentAdmin(
			returnTeamPlayer(detailPlayer.teams[0]),
			trailId,
			detailPlayer?._id,
		);

		const progress = await getProgress(
			String(detailPlayer?._id),
			String(trailId),
		);

		try {
			const itens: Array<ModuleInfo> = [];

			data.forEach((item) => {
				const currentProgress = progress.items.filter((f) => {
					return f._id == item._id;
				});

				const newItem = {
					_id: item._id,
					type: item.type,
					title: item.title,
					extra: item.extra,
					isFinish: currentProgress[0].done ? true : false,
					percent: currentProgress[0].percent,
				} as ModuleInfo;

				itens.push(newItem);
			});

			setProgress(progress);
			setModule(itens);

			setTotalProgress(Number(progress.percent));
			const pTotal = Number(progress.percent);
			const t = Math.round(pTotal).toFixed(0);
			setTotalPercent(parseInt(t));
		} finally {
			setLoadingModules(false);
		}

		const settings = await getSingleTrail(String(trailId));
		setGlobalSettings({
			title: settings[0].title,
			color: settings[0].extra.color,
		});
	}

	function loadIdeiaPlayer() {
		if (ideaPlayer) return;
		setLoadingIdeaPlayer(true);
		setIdeaPlayer(null);
		ranking()
			.then((resp) => {
				if (resp.length > 0) {
					// Verifique se o ID existe em algum objeto no array
					resp.some((dataItem: any, index: number) => {
						// Verifique se o ID existe em player
						const isPlayerIdExist = dataItem.player.filter(
							(player: any) => player._id === detailPlayer._id,
						)[0];

						// Verifique se o ID existe em participants
						const isParticipantIdExist = dataItem.participants.filter(
							(participant: any) => participant.value === detailPlayer._id,
						)[0];

						// Verifique se o ID existe em qualquer uma das duas
						if (isPlayerIdExist || isParticipantIdExist) {
							dataItem.position = index + 1;
							setIdeaPlayer(dataItem);
						}
					});
				}
			})
			.finally(() => {
				setTimeout(() => {
					setLoadingIdeaPlayer(false);
				}, 300);
			});
	}

	function downloadFile() {
		fetch(ideaPlayer.file)
			.then((response) => response.blob())
			.then((blob) => {
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = `ideia_${detailPlayer._id}`;
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(link.href);
				document.body.removeChild(link);
			})
			.catch((error) => {
				toast.error("Erro ao baixar o arquivo:");
			});
	}

	function loadChallegesList() {
		if (challenges.length > 0) return;
		setLoading(true);
		getChallenges()
			.then((resp) => {
				const list = resp.filter((item) => {
					return (
						item.active === true &&
						item.techniques.includes("GT35") &&
						item.extra.showOnList !== false
					);
				});
				list.forEach((item) => {
					item.done = detailPlayer?.challenges[item._id] > 0 ? true : false;
					const types: any[] = [];
					item.points.forEach((pt: any) => {
						types.push(pt.category);
					});
					item.type = types;
				});
				setChallenges(list);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function loadBadgesList() {
		setBadgesList(badges);
	}

	function showPoints(type: string, item: any) {
		return item.find((e: any) => e.category === type)?.total || 0;
	}

	function backToList() {
		setOpenItem(null);
		setDetailPlayer({});
		setIdeaPlayer(null);
		setChallenges([]);
		setBadgesList([]);
		setMyPosition(null);
		setTotalProgress(0);
		setTotalPercent(0);
	}

	function loadRankings() {
		loadIdeiaPlayer();
		if (myPosition) return;
		setLoading(true);
		RankingConhecimentoCompleto(detailPlayer.teams[0])
			.then((resp: any) => {
				if (resp.length > 0) {
					resp.some((e: any) => {
						if (e.p?._id === detailPlayer._id) {
							setMyPosition(e);
							return true;
						}
						return false;
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<div className="main-adm-ideias">
			<div>
				<form className="form">
					<div className="form-group">
						<label>
							<input
								type="text"
								placeholder="Buscar..."
								value={formFilter.search}
								onChange={handleInputChange}
								name="search"
							/>
						</label>
					</div>
					<div className="form-group">
						<label>
							<input
								type="text"
								placeholder="Matrícula"
								value={formFilter.matricula}
								onChange={handleInputChange}
								name="matricula"
							/>
						</label>
						<label>
							<select
								value={formFilter.equipe}
								onChange={handleSelectChange}
								name="equipe"
								className="h-[46px]"
							>
								<option value="" className="text-black">
									Ordenar
								</option>
								<option value="cadastro" className="text-black">
									Data de posse
								</option>
								<option value="nome" className="text-black">
									Por nome
								</option>
							</select>
						</label>
					</div>
				</form>
			</div>
			<section className="my-7 px-1 mb-[100px] rounded-[10px] relative transition min-h-[200px]">
				<h2 className="text-white text-[1.7em]">Funcionários</h2>
				<article className="my-10">
					{loading && !detailPlayer._id && (
						<Skeleton size={3} type="LISTFULL" />
					)}
					{!loading &&
						!detailPlayer._id &&
						dataEquipe.length > 0 &&
						dataEquipe.map((e: any) => (
							<div
								key={e._id}
								className="border border-[#BDB6FF] shadow-md  p-2 rounded mb-4 px-5"
							>
								<div className="flex flex-col md:flex-row justify-between w-full">
									<div className="flex lg:pt-7 px-5 py-5 items-center">
										<div className="mr-2">
											<div
												id="user-avatar"
												className="border border-yellow p-1 rounded-full flex items-center justify-center mr-3 pointer flex-col cursor-pointer w-16 h-16"
											>
												<UserAvatar
													data={{ ...e, ...e?.extra }}
													responsive={true}
												/>
											</div>
										</div>
										<div className="mx-2">
											<h5 className="md:text-xl font-bold mb-1 user-name text-base lg:text-lg">
												{e.name}
											</h5>
										</div>
										<div className="ml-[100px] mx-2">
											<p className="text-sm font-light ">
												Nível:{" "}
												<strong className="text-yellow">
													{e.level_progress ? e.level_progress.level.level : 0}
												</strong>
											</p>
										</div>
										<div className="mx-2">
											<div className="flex mt-3 md:mt-0">
												<button className="flex font-light mr-4 items-center">
													<AiFillStar color="#FCFC30" size={18} />{" "}
													<span className="underline text-left ml-1 text-[10px]">
														{e.point_categories && e.point_categories.star
															? e.point_categories.star
															: 0}{" "}
														estrelas
													</span>
												</button>

												<button className="flex font-light  items-center">
													<div className="bg-[#FCAD30] rounded-full h-[18px] w-[18px] p-1 flex items-center justify-center mr-1">
														<TfiMoney color="#735CC6" size={20} />{" "}
													</div>
													<span className="underline text-left text-[10px]">
														{e.point_categories && e.point_categories.coin
															? e.point_categories.coin
															: 0}{" "}
														moedas
													</span>
												</button>
											</div>
										</div>
									</div>

									<div className="resume resume-badges pt-[19px] pb-[17px]">
										<div className="flex justify-end items-end mb-1">
											{/* <a className="underline hover:no-underline text-center hover:text-white font-light">
                        Ver todos os badges
                      </a> */}
											<p>&#160;</p>
										</div>
										<div className="flex gap-5 mb-3 items-center">
											{badges.map((i: any) => (
												<div
													className={`mx-auto my-0 ${
														e.challenges && e.challenges[i._id]
															? "completed"
															: "incomplet"
													}`}
												>
													{i.badge?.medium?.url ? (
														<img src={i.badge?.medium?.url} alt="badge" />
													) : (
														"--"
													)}
												</div>
											))}
										</div>
									</div>
								</div>
								<div className="text-right">
									<button
										className="bg-[#FCFC30] rounded text-[#696969] px-8 py-1"
										onClick={() => {
											showMoreInfoPlayer(e);
										}}
									>
										Ver mais detalhes
									</button>
								</div>
							</div>
						))}
					{!detailPlayer._id && dataEquipe.length === 0 && (
						<h2>Não tem funcionários no Game TÔ ON.</h2>
					)}
					{detailPlayer._id && (
						<>
							<button
								onClick={() => backToList()}
								className="flex items-center mb-4 text-yellow text-[1.5em]"
							>
								<FaChevronLeft />
								&#160;&#160;VOLTAR
							</button>
							<div className="border border-[#BDB6FF] p-2 rounded mb-4 shadow-md px-5">
								<div className="flex flex-col md:flex-row justify-between w-full ">
									<div className="flex lg:pt-7 px-5 py-5 items-center">
										<div className="mr-2">
											<div
												id="user-avatar"
												className="border border-yellow p-1 rounded-full flex items-center justify-center mr-3 pointer flex-col cursor-pointer w-16 h-16"
											>
												<UserAvatar
													data={{ ...detailPlayer, ...detailPlayer?.extra }}
													responsive={true}
												/>
											</div>
										</div>
										<div className="mx-2">
											<h5 className="md:text-xl font-bold mb-1 user-name text-base lg:text-lg">
												{detailPlayer.name}
											</h5>
										</div>
										<div className="ml-[100px] mx-2">
											<p className="text-sm font-light ">
												Nível:{" "}
												<strong className="text-yellow">
													{detailPlayer.level_progress
														? detailPlayer.level_progress.level.level
														: 0}
												</strong>
											</p>
										</div>
										<div className="mx-2">
											<div className="flex mt-3 md:mt-0">
												<button className="flex font-light mr-4 items-center">
													<AiFillStar color="#FCFC30" size={18} />{" "}
													<span className="underline text-left ml-1 text-[10px]">
														{detailPlayer.point_categories &&
														detailPlayer.point_categories.star
															? detailPlayer.point_categories.star
															: 0}{" "}
														estrelas
													</span>
												</button>

												<button className="flex font-light  items-center">
													<div className="bg-[#FCAD30] rounded-full h-[18px] w-[18px] p-1 flex items-center justify-center mr-1">
														<TfiMoney color="#735CC6" size={20} />{" "}
													</div>
													<span className="underline text-left text-[10px]">
														{detailPlayer.point_categories &&
														detailPlayer.point_categories.coin
															? detailPlayer.point_categories.coin
															: 0}{" "}
														moedas
													</span>
												</button>
											</div>
										</div>
									</div>

									<div className="resume resume-badges pt-[19px] pb-[17px]">
										<div className="flex justify-end items-end mb-1">
											{/* <a className="underline hover:no-underline text-center hover:text-white font-light">
                        Ver todos os badges
                      </a> */}
											<p>&#160;</p>
										</div>
										<div className="flex gap-5 mb-3 items-center">
											{badges.map((i: any) => (
												<div
													className={`mx-auto my-0 ${
														detailPlayer.challenges &&
														detailPlayer.challenges[i._id]
															? "completed"
															: "incomplet"
													}`}
												>
													{i.badge?.medium?.url ? (
														<img src={i.badge?.medium?.url} alt="badge" />
													) : (
														"--"
													)}
												</div>
											))}
										</div>
									</div>
								</div>
								<div>
									<div
										className={
											expectedPercent
												? "mb-11 lg:mb-32 mx-auto"
												: "mb-11 lg:mb-11 mx-auto"
										}
									>
										<p className="progressGeral">
											<strong>Progresso: </strong>
											{totalPercent || 0}%
										</p>
										<UserProgress
											currentProgress={totalPercent}
											expectedProgress={0}
											expectedDays={0}
											showAvatar={false}
											className="justify-center mt-5"
										/>
									</div>
									<Collapsible
										trigger={<span>Jornada TO ON - Central de Ideias</span>}
										transitionTime={200}
										open={openItem === "centralDeIdeias"}
										onTriggerOpening={() => {
											handleItemClick("centralDeIdeias");
										}}
										onOpen={() => loadIdeiaPlayer()}
										onClose={() => {}}
									>
										<p className="px-[100px]">
											{loadingIdeaPlayer && (
												<Skeleton size={1} type="LISTFULL" />
											)}
											{!loadingIdeaPlayer && ideaPlayer && (
												<section className="ideia-selected">
													<article className="bg-white shadow-md p-7 trail-card flex mb-[100px] rounded-[10px] relative transition min-h-[200px]">
														<figure className="avatar">
															{ideaPlayer.file ? (
																<img
																	src={ideaPlayer.file}
																	alt="Imagem Ideia"
																	width={80}
																	aria-hidden={true}
																/>
															) : (
																<img
																	src={
																		ideaPlayer.image &&
																		ideaPlayer.image !== "" &&
																		typeof ideaPlayer.image === "string"
																			? ideaPlayer.image
																			: ideaPlayer.p?.image?.small
																				? ideaPlayer.p?.image?.small?.url
																				: require("../../../assets/img/user.png")
																	}
																	width={80}
																	alt="Imagem Ideia"
																	aria-hidden={true}
																/>
															)}
														</figure>
														<div className="content-ideia px-7 text-gray-300">
															<p className="text-base">
																<strong>{ideaPlayer.title}</strong>
															</p>
															<p>{ideaPlayer.content}</p>
															<p>
																<strong>
																	{ideaPlayer.player[0]?.name} (
																	{getNameSetor(
																		ideaPlayer.player[0].teams || [],
																	)}
																	)
																</strong>
															</p>
															{!!ideaPlayer.participants &&
																ideaPlayer.participants.length > 0 && (
																	<div className="comment pt-7">
																		<p>
																			<strong>Participante(s):</strong>
																		</p>
																		<ul className="flex">
																			{ideaPlayer.participants.map(
																				(item: any, index: number) => (
																					<li key={index} className="p-2 mr-2">
																						{item.label}
																					</li>
																				),
																			)}
																		</ul>
																	</div>
																)}
															{typeof ideaPlayer.file === "string" && (
																<button
																	style={{
																		padding: "0.3rem 2rem",
																		marginTop: "1rem",
																		borderRadius: "20px",
																		backgroundColor: "rgb(115, 92, 198)",
																		color: "#fff",
																	}}
																	onClick={downloadFile}
																>
																	Fazer download de arquivo
																</button>
															)}
															{ideaPlayer.evaluates &&
																ideaPlayer.evaluates.length > 0 && (
																	<div className="comment pt-7">
																		<p>
																			<strong>Comentário do gestor:</strong>
																		</p>
																		<p>
																			{
																				ideaPlayer.evaluates[
																					ideaPlayer.evaluates.length - 1
																				]?.attributes?.comment
																			}
																		</p>
																		<strong>
																			{
																				ideaPlayer.evaluates[
																					ideaPlayer.evaluates.length - 1
																				]?.userId
																			}
																		</strong>
																	</div>
																)}
															<hr />
															<div className="actions-ideia pt-7 flex justify-between	items-center	">
																<div className="curtidas flex ">
																	<button>
																		<span className="mx-2 underline">
																			{
																				ideaPlayer.likes.filter(
																					(e: any) =>
																						e.actionId === "like_idea",
																				)?.length
																			}{" "}
																			curtida
																			{ideaPlayer.likes.filter(
																				(e: any) => e.actionId === "like_idea",
																			)?.length > 1 && "s"}
																		</span>
																	</button>
																</div>
																<div className="avalie flex items-center">
																	<AvaliationsIdea
																		qtd={
																			ideaPlayer.evaluates.findLast(
																				(e: any) =>
																					e.actionId === "evaluate_idea",
																			)?.attributes?.grade || 0
																		}
																	/>
																</div>
																<div className="ranking">
																	<div className="ranking-item">
																		<p>
																			<strong>Ranking da Comunidade</strong>{" "}
																			{ideaPlayer.position}º lugar
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</article>
												</section>
											)}
											{!loadingIdeaPlayer && !ideaPlayer && (
												<h2 className="text-center">
													Sem ideia cadastrada ou não esta como participante em
													uma ideia.
												</h2>
											)}
										</p>
									</Collapsible>
									{list?.map((item: any, index: number) => (
										<Collapsible
											key={item._id}
											trigger={<span>{item.title}</span>}
											transitionTime={200}
											open={openItem === item._id}
											onTriggerOpening={() => {
												handleItemClick(item._id);
											}}
											onOpen={() => {
												loadMoodules(item._id);
											}}
											onClose={() => {
												setModule([]);
											}}
										>
											<p className="px-[100px]">
												{!!module && module.length > 0 && !loadingModules && (
													<>
														<div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
															{module.map((item: ModuleInfo, index: number) => (
																<>
																	<article
																		key={index}
																		className={`rounded-[10px] py-[30px] px-[22px] mb-5 ${
																			Number(item.percent) >= 100
																				? `bg-pink-100`
																				: `bg-white`
																		} cursor-pointer relative transition hover:opacity-80`}
																	>
																		<h4 className="text-gray-300 mb-3 text-xl font-normal">
																			{item.title}
																		</h4>
																		<div
																			className="text-gray-300 text-sm font-light mb-12"
																			dangerouslySetInnerHTML={{
																				__html: item.extra.description,
																			}}
																		></div>
																		<ProgressBar
																			color={`#${globalSettings?.color}`}
																			percent={item?.percent || 0}
																		/>
																	</article>
																</>
															))}
														</div>
													</>
												)}
												{loadingModules && module.length === 0 && (
													<>
														<div className="grid gap-7 md:grid-cols-2 lg:grid-cols-3">
															<Skeleton size={3} type="MODULE" />
														</div>
													</>
												)}
											</p>
										</Collapsible>
									))}
									<Collapsible
										trigger={<span>Desafios</span>}
										transitionTime={200}
										open={openItem === "desafios"}
										onTriggerOpening={() => {
											handleItemClick("desafios");
										}}
										onOpen={() => {
											loadChallegesList();
										}}
										onClose={() => {}}
									>
										<p className="px-[40px] py-10">
											{loading && challenges.length === 0 && (
												<>
													<div className="grid gap-7 md:grid-cols-2 lg:grid-cols-3">
														<Skeleton size={3} type="MODULE" />
													</div>
												</>
											)}
											{!loading && challenges.length > 0 && (
												<div className="grid grid-cols-1 lg:grid-cols-4 gap-7 mb-20">
													{challenges.map(
														(item: ChallengeInfo, index: number) => (
															<div className="box rounded-[10px] px-[18px] py-10 pb-4 cursor-pointer relative">
																{item.done && (
																	<div className="icon-checked absolute top-[-10px] right-[-10px]">
																		<img
																			src={require("../../../assets/images/board-icon/checked.png")}
																		/>
																	</div>
																)}
																<div>
																	<div className="mb-8 flex items-center justify-center">
																		<img
																			src={item.badgeUrl}
																			alt={item.challenge}
																			width={78}
																		/>
																	</div>
																	<p className="text-lg text-gray-300 font-medium mb-3 title text-center">
																		{item.challenge}
																	</p>
																	<p className="text-gray-300 text-sm  text-center">
																		{item.description}
																	</p>
																</div>

																{item.points && item.points.length && (
																	<div className="stats flex">
																		{item.type.includes("star") && (
																			<p className="flex">
																				<button
																					className={
																						item.extra.Tip
																							? "myButton flex"
																							: "flex"
																					}
																				>
																					<AiFillStar
																						color="#FCFC30"
																						size={20}
																					/>
																					<span className="underline text-gray-300 ml-2 font-normal">
																						{!item.extra.Tip
																							? showPoints("star", item.points)
																							: ""}{" "}
																						Estrela
																						{showPoints("star", item.points) > 1
																							? "s"
																							: ""}
																					</span>
																				</button>
																			</p>
																		)}
																		{item.type.includes("coin") && (
																			<p className="flex">
																				<img src="/img/coin.svg" />
																				<span className="underline text-gray-300  ml-2 font-normal">
																					{showPoints("coin", item.points)}
																					Moeda
																					{showPoints("star", item.points) > 1
																						? "s"
																						: ""}
																				</span>
																			</p>
																		)}
																	</div>
																)}
															</div>
														),
													)}
												</div>
											)}
										</p>
									</Collapsible>
									<Collapsible
										trigger={<span>Badges</span>}
										transitionTime={200}
										open={openItem === "badges"}
										onTriggerOpening={() => {
											handleItemClick("badges");
										}}
										onOpen={() => {
											loadBadgesList();
										}}
										onClose={() => {}}
									>
										<p className="px-[40px] py-10">
											{loading && badgesList.length === 0 && (
												<>
													<div className="grid gap-7 md:grid-cols-2 lg:grid-cols-3">
														<Skeleton size={3} type="MODULE" />
													</div>
												</>
											)}
											{!loading && badgesList.length > 0 && (
												<div className="w-full text-white text-xl font-lighter main-badges">
													<div className="content-badges">
														<table>
															<thead>
																<tr>
																	<th>BADGE</th>
																	<th>NOME</th>
																	<th>DESAFIOS</th>
																</tr>
															</thead>
															<tbody>
																{badgesList?.map((item: any) => (
																	<tr>
																		<td
																			className={
																				detailPlayer.challenges &&
																				detailPlayer.challenges[item._id]
																					? "completed"
																					: "incomplet"
																			}
																		>
																			{item.badge?.medium?.url ? (
																				<img
																					src={item.badge?.medium?.url}
																					alt="badge"
																				/>
																			) : (
																				"--"
																			)}
																		</td>
																		<td>
																			<span>{item.challenge}</span>
																		</td>
																		<td>{item.description}</td>
																	</tr>
																))}
																{!badges.length && (
																	<tr>
																		<td colSpan={100}>
																			Nenhum badges para exibir!
																		</td>
																	</tr>
																)}
															</tbody>
														</table>
													</div>
												</div>
											)}
										</p>
									</Collapsible>
									<Collapsible
										trigger={<span>Rankings</span>}
										transitionTime={200}
										open={openItem === "rankings"}
										onTriggerOpening={() => {
											handleItemClick("rankings");
										}}
										onOpen={() => {
											loadRankings();
										}}
										onClose={() => {}}
									>
										{loading && (
											<>
												<p className="px-[100px] py-10">
													<div className="grid gap-7 md:grid-cols-2 lg:grid-cols-3">
														<Skeleton size={3} type="MODULE" />
													</div>
												</p>
											</>
										)}
										<p className="px-[100px] py-10 flex justify-center">
											{!loading && !myPosition && (
												<div className="md:w-[33%] border border-[#BDB6FF] shadow-md p-2 rounded mb-4 px-5 mr-4">
													<h2 className="flex items-center text-[1.5em]">
														Ranking do conhecimento
													</h2>
													<p className="text-center">
														Sem posição no ranking do conhecimento
													</p>
												</div>
											)}
											{!loading && myPosition && (
												<div className="md:w-[33%] border border-[#BDB6FF] shadow-md p-2 rounded mb-4 px-5 mr-4">
													<h2 className="flex items-center text-[1.5em]">
														Ranking do conhecimento
													</h2>
													<div className="flex">
														<div className="thumb mr-3 w-[30%] min-w-[80px] h-[80px] text-left">
															{myPosition.image ? (
																<img
																	src={myPosition.image}
																	alt="Imagem user"
																	className=" rounded-full"
																/>
															) : (
																<span>
																	{myPosition.p.extra.avatar ? (
																		<UserAvatar
																			data={{
																				...myPosition.p,
																				...myPosition.p.extra,
																			}}
																			responsive={true}
																		/>
																	) : (
																		<img
																			src={require("../../../assets/img/user.png")}
																			alt="Imagem user"
																			className=" rounded-full"
																		/>
																	)}
																</span>
															)}
														</div>
														<div className="w-[70%] text-left">
															<p className="text-yellow uppercase text-xs font-bold mb-1">
																{myPosition.position}º LUGAR
															</p>
															<h5 className="text-xs text-white font-medium">
																{myPosition.name}
															</h5>
															<p className="text-[10px] text-white font-normal">
																{getNameSetor(myPosition.p?.teams)}
															</p>
															<div className="flex">
																<button className="flex font-light mr-4 text-[10px] items-center cursor-default">
																	<AiFillStar color="#FCFC30" size={18} />{" "}
																	<span>
																		{myPosition.total} estrela
																		{myPosition.total > 1 ? "s" : ""}
																	</span>
																</button>
															</div>
														</div>
													</div>
												</div>
											)}

											{!loading && !ideaPlayer && (
												<div className="md:w-[33%] border border-[#BDB6FF] shadow-md p-2 rounded mb-4 px-5 mr-4">
													<h2 className="flex items-center text-[1.5em]">
														<AiFillHeart
															color="#F97A70"
															size={20}
															className="mr-2"
														/>{" "}
														Ranking da comunidade
													</h2>
													<p className="text-center">
														Sem posição no ranking da comunidade
													</p>
												</div>
											)}
											{!loading && ideaPlayer && (
												<div className="md:w-[33%] border border-[#BDB6FF] shadow-md p-2 rounded mb-4 px-5 mr-4">
													<h2 className="flex items-center text-[1.5em]">
														<AiFillHeart
															color="#F97A70"
															size={20}
															className="mr-2"
														/>{" "}
														Ranking da comunidade
													</h2>
													<div className="flex">
														<div className="thumb mr-3 w-[25%] min-w-[80px] h-[80px] imgMy">
															<span>
																{ideaPlayer.file ? (
																	<img
																		src={ideaPlayer.file}
																		alt="Imagem user"
																	/>
																) : (
																	<img
																		src={require("../../../assets/img/user.png")}
																		alt="Imagem user"
																	/>
																)}
															</span>
														</div>
														<div className="w-[70%] text-left">
															<p className="text-yellow uppercase text-xs font-bold">
																{ideaPlayer.position}º LUGAR
															</p>
															<p className="text-sm text-white font-thin underline m-0">
																{ideaPlayer.title}
															</p>
															<h5 className="text-xs text-white font-light mt-2 mb-1">
																{ideaPlayer.player[0]?.name}
															</h5>
															<div className="flex items-center">
																<AiFillHeart color="#F97A70" size={18} />
																<span className="text-white text-[10px] font-normal">
																	{ideaPlayer.likesCount} Curtidas
																</span>
															</div>
														</div>
													</div>
												</div>
											)}
										</p>
									</Collapsible>
								</div>
							</div>
						</>
					)}
				</article>
			</section>
		</div>
	);
};

export default AdminMinhaEquipe;
