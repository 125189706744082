export function SortPagination(data: any) {
  const str = data.headers["content-range"];
  const rangeTotal = str.split("-")[1].split("/")[1];
  const count = str.split("-")[1].split("/")[0];
  const ranges = str.split(" ")[1].split("-")[0];

  const range = {
    total: parseInt(rangeTotal),
    from: parseInt(ranges),
    pages: Math.ceil(parseInt(rangeTotal) / parseInt(count)),
  };


  return { result: data.data, range: range }
}